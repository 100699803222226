import {
	AUTHENTICATE,
	LOGOUT,
	AUTH_ERROR,
	SET_USER_NAME,
	PASSWORD_ACTION,
	SET_ERROR_MESSAGE,
	SET_VENDOR_PASSWORD_STATUS,
} from '../types/auth-type';

const initialState = {
	user: null,
	token: null,
	role: null,
	isAuthenticated: false,
	vendorPasswordStatus: false,
	errorMessage: '',
	userName: '',
	status: false,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTHENTICATE:
			return {
				user: action.user,
				token: action.token,
				role: action.role,
				isAuthenticated: action.token.length > 0,
			};
		case LOGOUT:
			return initialState;
		case SET_USER_NAME:
			return { ...state, userName: action.userName}
		case PASSWORD_ACTION:
			return { ...state, message: action.message, status: action.status };
			return { ...state, userName: action.userName };
		case SET_VENDOR_PASSWORD_STATUS:
			return {
				...state,
				vendorPasswordStatus: action.vendorPasswordStatus,
                errorMessage: action.message,
			};
		case AUTH_ERROR:
			return {
				...initialState,
				errorMessage: action.message,
			};
		default:
			return state;
	}
};
export default authReducer;
