import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import moment from "moment";
// @material-ui/core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Autocomplete } from '@material-ui/lab';
import { TextField } from "@material-ui/core";

// material ui icons
import DevicesOutlined from "@material-ui/icons/DevicesOutlined";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import CollectionsIcon from "@material-ui/icons/Collections";
import {
  AddPhotoAlternate,
  Assignment,
  Delete,
  Dvr,
  Person,
  Report,
  Search,
  ViewModule,
} from "@material-ui/icons";

// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import DetailsDialog from "../Details/DetailsDialog";
import ReportDialog from "../Report/ReportDialog";
import ThumbnailDialog from "../Thumbnail/ThumbnailDialog";
import DeleteTransaction from 'views/Transaction/DeleteTransaction';
import AddTransactionImage from 'views/Transaction/AddTransactionImage';

import { fetchTransactionBetweenDate, fetchTransactionSearchBy, fetchTransactionBetweenDateCode } from "../../redux/actions/report-action";
import { fetchDivisionAction } from "../../redux/actions/division-action";
import { fetchTypeAction } from "../../redux/actions/type-action";
import { fetchSubTypeAction } from "../../redux/actions/subtype-action";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

export default function TransactionStatus() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [partyCode, setPartyCode] = useState('');
  const [partyName, setPartyName] = useState('');
  const [fromState, setFromState] = useState("");
  const [toState, setToState] = useState("");
  const [showDetailsDialog, setShowDetailsDialog] = useState(null);
  const [showReportDialog, setShowReportDialog] = useState(null);
  const [showThumbnailsDialog, setShowThumbnailsDialog] = useState(null);
  const [deleteTransactionDialog, setDeleteTransactionDialog] = useState(null);
  const [addTransactionImage, setAddTransactionImage] = useState(null);
  
  const transactionReports = useSelector(
    (state) => state.reports.transactionReports
  );
  const transactionByName = useSelector((state) => state.reports.transactionsSearchBy);
  const allDivisions = useSelector((state) => state.divisions.divisions);
  const allTypes = useSelector((state) => state.types.types);
  const allSubTypes = useSelector((state) => state.subtypes.subtypes);

  useEffect(() => {
    batch(() => {
      dispatch(fetchDivisionAction());
      dispatch(fetchTypeAction());
      dispatch(fetchSubTypeAction());
    });
    // const currentData = new Date().toLocaleDateString();
    // console.log(`Date : ${moment(currentData).format("DD-MM-YYYY")}`)
    // const newDate = moment(currentData).format("DD-MM-YYYY");
    // alert(`Date ${newDate}`)
    // setFrom(newDate)
    // setTo(newDate)
    // debugger
  }, []);
  useEffect(() => {
    if (partyName) {
      dispatch(fetchTransactionSearchBy(partyName));
    }
  }, [partyName]);

  useEffect(() => {
    if (from && to) {
      dispatch(fetchTransactionBetweenDate(from, to));
    }
  }, [from, to]);

  useEffect(() => {
    if (from && to && partyCode) {
      dispatch(fetchTransactionBetweenDateCode(from, to, partyCode));
    } 
    if(!partyCode) {
      dispatch(fetchTransactionBetweenDate(from, to));
    }
  }, [from, to, partyCode]);

  const handleThumbnailCallback = (state) => {
    if (!state) {
      setShowThumbnailsDialog(null);
    }
  };

  const handleReportCallback = (state) => {
    if (!state) {
      setShowReportDialog(null);
    }
  };
  const handleAddImageCallback = (state) => {
    if (!state) {
      setAddTransactionImage(null);
    }
  };

  const handleDetailsCallback = (state) => {
    if (!state) {
      setShowDetailsDialog(null);
    }
  };

  const handleDeleteTransactionCallback = (state) => {
    if (!state) {
      setDeleteTransactionDialog(null);
    }
  };

  const data = transactionReports
    ? transactionReports.map((prop, key) => {
        const transactionDivision = (function () {
          if (allDivisions && allDivisions.length > 0) {
            let division = allDivisions.find(
              (item) => item._id === prop.division
            );
            if (division) {
              return division.division;
            }
          }
          return "N/A";
        })();
        const transactionDivisionUnit = (function () {
          if (allDivisions && allDivisions.length > 0) {
            let division = allDivisions.find(
              (item) => item._id === prop.division
            );
            if (division) {
              return division.unit;
            }
          }
          return "";
        })();
        const transactionType = (function () {
          if (allTypes && allTypes.length > 0) {
            let type = allTypes.find((item) => item._id === prop.type);
            if (type) {
              return type.typeName;
            }
          }
          return "N/A";
        })();
        const transactionSubType = (function () {
          if (allSubTypes && allSubTypes.length > 0) {
            let subType = allSubTypes.find((item) => item._id === prop.subType);
            if (subType) {
              return subType.subTypeName;
            }
          }
          return "N/A";
        })();
        return {
          id: prop._id,
          gatePassDate: moment(prop.gatePassDate).format("DD-MM-YYYY"),
          gatePassNumber: prop.gatePassNumber,
          vehicleNo: prop.vehicleNo,
          partyName: prop.partyName,
          orderNumber: (
            <div>
              <Button
                simple
                block
                onClick={() => {
                  setShowReportDialog(
                    <ReportDialog
                      show={true}
                      parentCallback={handleReportCallback}
                      transaction={prop}
                      unit={transactionDivisionUnit}
                    />
                  );
                }}
                color="info"
                className="remove"
              >
                {prop.orderNumber}
              </Button>
            </div>
          ),
          division: transactionDivision,
          type: transactionType,
          subType: transactionSubType,
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  setAddTransactionImage(
                    <AddTransactionImage
                      show={true}
                      parentCallback={handleAddImageCallback}
                      transaction={prop}
                    />
                  );
                }}
                color="info"
                className="like"
              >
                <AddPhotoAlternate />
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  setShowThumbnailsDialog(
                    <ThumbnailDialog
                      show={true}
                      parentCallback={handleThumbnailCallback}
                      transaction={prop}
                    />
                  );
                }}
                color="info"
                className="like"
              >
                <CollectionsIcon />
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  setShowDetailsDialog(
                    <DetailsDialog
                      show={true}
                      parentCallback={handleDetailsCallback}
                      transaction={prop}
                      division={transactionDivision}
                      type={transactionType}
                      subType={transactionSubType}
                    />
                  );
                }}
                color="warning"
                className="edit"
              >
                <Dvr />
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  setShowReportDialog(
                    <ReportDialog
                      show={true}
                      parentCallback={handleReportCallback}
                      transaction={prop}
                      unit={transactionDivisionUnit}
                    />
                  );
                }}
                color="danger"
                className="remove"
              >
                <EqualizerIcon />
              </Button>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  setDeleteTransactionDialog(
                    <DeleteTransaction
                      show={true}
                      parentCallback={handleDeleteTransactionCallback}
                      transaction={prop}
                    />
                  );
                }}
                color="danger"
                className="remove"
              >
                <Delete />
              </Button>
            </div>
          ),
        };
      })
    : [];

    const user = JSON.parse(localStorage.getItem('User'));
    let searchRow;
    if(user.role === 'Admin') {
      searchRow = (
        <GridItem xs={12} sm={4}>
          <Autocomplete 
            id='name list'
            options={transactionByName}
            renderInput={params => (
              <TextField 
                {...params} 
                label='Party Name'
                variant='outlined'
                onChange={(event) => setPartyName(event.target.value)}
              />
            )}
            getOptionLabel={option => option.partyName}
            // style={{ width: 200 }}
            onChange={(_event, newTeam) => {
              setPartyCode(newTeam ? newTeam.partyCode : '')
            }}
          >
          </Autocomplete>
        </GridItem>
      )
    }

  return (
    <div>
      <GridContainer>
        {addTransactionImage}
        {showDetailsDialog}
        {showReportDialog}
        {showThumbnailsDialog}
        {deleteTransactionDialog}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Status</h4>
              <h4 className={classes.cardCenterTitle}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={4}>
                    <TextField
                      type="date"
                      variant="outlined"
                      placeholder="From"
                      label="From"
                      value={from ? from : ""}
                      onChange={(event) => setFrom(event.target.value)}
                    ></TextField>
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <TextField
                      type="date"
                      variant="outlined"
                      placeholder="To"
                      label="To"
                      value={to}
                      onChange={(event) => setTo(event.target.value)}
                    ></TextField>
                  </GridItem>
                  {searchRow}
                </GridContainer>
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "Date",
                    accessor: "gatePassDate",
                  },
                  {
                    Header: "Party Name",
                    accessor: "partyName",
                  },
                  {
                    Header: "Gate Pass",
                    accessor: "gatePassNumber",
                  },
                  {
                    Header: "Order No",
                    accessor: "orderNumber",
                  },
                  {
                    Header: "Vechile No",
                    accessor: "vehicleNo",
                  },
                  {
                    Header: "Division",
                    accessor: "division",
                  },
                  {
                    Header: "Type",
                    accessor: "type",
                  },
                  {
                    Header: "Sub Type",
                    accessor: "subType",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={data}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
