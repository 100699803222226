import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import CollectionsIcon from "@material-ui/icons/Collections";
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import AddCircle from "@material-ui/icons/AddCircle";
import LocalShipping from "@material-ui/icons/LocalShipping";
import DateRange from "@material-ui/icons/DateRange";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import AssignmentLate from "@material-ui/icons/AssignmentLate";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import LocalOffer from "@material-ui/icons/LocalOffer";
import { AddPhotoAlternate, Delete } from "@material-ui/icons";
import Update from "@material-ui/icons/Update";
import LinearScale from "@material-ui/icons/HomeSharp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Dialog from "@material-ui/core/Dialog";

// local components
import DetailsDialog from "../Details/DetailsDialog";
import ReportDialog from "../Report/ReportDialog";
import ThumbnailDialog from "../Thumbnail/ThumbnailDialog";
import TransactionCreate from "./TransactionCreate";
import AddTransactionImage from "./AddTransactionImage";

import { windowAction } from "../../redux/actions/window-action";
import {
  fetchGrossWeightAction,
  fetchTareWeightAction,
} from "../../redux/actions/sync-action";
import {
  fetchTranactionAction,
  fetchTranactionToBeProccessed,
  fetchAllTranactionToBeProccessed,
  fetchTranactionCount,
  fetchUnreadTranactionAction,
} from "../../redux/actions/transaction-action";
import { fetchDivisionAction } from "../../redux/actions/division-action";
import { fetchTypeAction } from "../../redux/actions/type-action";
import { fetchSubTypeAction } from "../../redux/actions/subtype-action";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import DeleteTransaction from './DeleteTransaction';

const useStyles = makeStyles(styles);

export default function Transactions() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showDetailsDialog, setShowDetailsDialog] = useState(null);
  const [showReportDialog, setShowReportDialog] = useState(null);
  const [showThumbnailsDialog, setShowThumbnailsDialog] = useState(null);
  const [addTransactionImage, setAddTransactionImage] = useState(null);
  const [deleteTransactionDialog, setDeleteTransactionDialog] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const open = useSelector((state) => state.window.open);
  const totalTransactionCount = useSelector(
    (state) => state.transactions.allTransaction
  );
  const lastDayTransactionCount = useSelector(
    (state) => state.transactions.lastTransaction
  );
  const unprossedTransactions = useSelector(
    (state) => state.transactions.allToBeProccessed
  );
  const totalGrossWeight = useSelector((state) => state.sync.totalGrossWeight);
  const totalTareWeight = useSelector((state) => state.sync.totalTareWeight);
  const allTransactions = useSelector(
    (state) => state.transactions.transactions
  );
  const allDivisions = useSelector((state) => state.divisions.divisions);
  const allTypes = useSelector((state) => state.types.types);
  const allSubTypes = useSelector((state) => state.subtypes.subtypes);

  useEffect(() => {
    batch(() => {
      dispatch(fetchDivisionAction());
      dispatch(fetchTypeAction());
      dispatch(fetchSubTypeAction());
      dispatch(fetchTranactionAction({ pageLimiter: false }));
      dispatch(fetchAllTranactionToBeProccessed());
      dispatch(fetchTranactionCount());
      dispatch(fetchGrossWeightAction());
      dispatch(fetchTareWeightAction());
      dispatch(fetchUnreadTranactionAction());
    });
  }, []);

  const handleCreateTranaction = () => {
    dispatch(windowAction({ open: true }));
  };
  const handleCloseWindow = () => {
    dispatch(windowAction({ open: false }));
  };

  const handleThumbnailCallback = (state) => {
    if (!state) {
      setShowThumbnailsDialog(null);
    }
  };

  const handleReportCallback = (state) => {
    if (!state) {
      setShowReportDialog(null);
    }
  };

  const handleAddImageCallback = (state) => {
    if (!state) {
      setAddTransactionImage(null);
    }
  };
  const handleDetailsCallback = (state) => {
    if (!state) {
      setShowDetailsDialog(null);
    }
  };

  const handleDeleteTransactionCallback = (state) => {
    if (!state) {
      setDeleteTransactionDialog(null);
    }
  };

  // Prepare table rows
  const data = allTransactions.map((prop, key) => {
    const transactionDivision = (function () {
      if (allDivisions && allDivisions.length > 0) {
        let division = allDivisions.find((item) => item._id === prop.division);
        if (division) {
          return division.division;
        }
      }
      return "N/A";
    })();
    const transactionDivisionUnit = (function () {
      if (allDivisions && allDivisions.length > 0) {
        let division = allDivisions.find((item) => item._id === prop.division);
        if (division) {
          return division.unit;
        }
      }
      return "";
    })();
    const transactionType = (function () {
      if (allTypes && allTypes.length > 0) {
        let type = allTypes.find((item) => item._id === prop.type);
        if (type) {
          return type.typeName;
        }
      }
      return "N/A";
    })();
    const transactionSubType = (function () {
      if (allSubTypes && allSubTypes.length > 0) {
        let subType = allSubTypes.find((item) => item._id === prop.subType);
        if (subType) {
          return subType.subTypeName;
        }
      }
      return "N/A";
    })();
    return {
      id: prop._id,
      gatePassDate: moment(prop.gatePassDate).format("DD-MM-YYYY"),
      gatePassNumber: prop.gatePassNumber,
      vehicleNo: prop.vehicleNo,
      partyName: prop.partyName,
      orderNumber: (
        <div className="actions-center">
          <Button
            justIcon
            simple
            block
            onClick={() => {
              setShowReportDialog(
                <ReportDialog
                  show={true}
                  parentCallback={handleReportCallback}
                  transaction={prop}
                  unit={transactionDivisionUnit}
                />
              );
            }}
            color="danger"
            className="remove"
          >
            {prop.orderNumber}
          </Button>
        </div>
      ),
      division: transactionDivision,
      type: transactionType,
      subType: transactionSubType,
      actions: (
        <div className="actions-right">
          <Button
            justIcon
            round
            simple
            onClick={() => {
              setAddTransactionImage(
                <AddTransactionImage
                  show={true}
                  parentCallback={handleAddImageCallback}
                  transaction={prop}
                />
              );
            }}
            color="info"
            className="like"
          >
            <AddPhotoAlternate />
          </Button>
          <Button
            justIcon
            round
            simple
            onClick={() => {
              setShowThumbnailsDialog(
                <ThumbnailDialog
                  show={true}
                  parentCallback={handleThumbnailCallback}
                  transaction={prop}
                />
              );
            }}
            color="info"
            className="like"
          >
            <CollectionsIcon />
          </Button>
          <Button
            justIcon
            round
            simple
            onClick={() => {
              setShowDetailsDialog(
                <DetailsDialog
                  show={true}
                  parentCallback={handleDetailsCallback}
                  transaction={prop}
                  division={transactionDivision}
                  type={transactionType}
                  subType={transactionSubType}
                />
              );
            }}
            color="warning"
            className="edit"
          >
            <Dvr />
          </Button>
          <Button
            justIcon
            round
            simple
            onClick={() => {
              setShowReportDialog(
                <ReportDialog
                  show={true}
                  parentCallback={handleReportCallback}
                  transaction={prop}
                  unit={transactionDivisionUnit}
                />
              );
            }}
            color="danger"
            className="remove"
          >
            <EqualizerIcon />
          </Button>
          <Button
            justIcon
            round
            simple
            onClick={() => {
              setDeleteTransactionDialog(
                <DeleteTransaction
                  show={true}
                  parentCallback={handleDeleteTransactionCallback}
                  transaction={prop}
                />
              );
            }}
            color="danger"
            className="remove"
          >
            <Delete />
          </Button>
        </div>
      ),
    };
  });

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <LocalShipping />
              </CardIcon>
              <p className={classes.cardCategory}>Transaction{"'"}s</p>
              <h3 className={classes.cardTitle}>
                {lastDayTransactionCount}/{totalTransactionCount}
                {/* <small> TXN</small> */}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <DateRange />
                </Danger>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Last 24 Hours
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AssignmentLate />
              </CardIcon>
              <p className={classes.cardCategory}>Un-TXN</p>
              <h3 className={classes.cardTitle}>
                {unprossedTransactions ? unprossedTransactions : 0}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <AssignmentLate />
              </CardIcon>
              <p className={classes.cardCategory}>Tare Weight</p>
              <h3 className={classes.cardTitle}>
                <small>{totalTareWeight} KG</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Total Tare of Current Session
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <LinearScale />
              </CardIcon>
              <p className={classes.cardCategory}>Gross Weight</p>
              <h3 className={classes.cardTitle}>
                <small>{totalGrossWeight} KG</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Total Gross of Current Session
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        {showDetailsDialog}
        {showReportDialog}
        {showThumbnailsDialog}
        {addTransactionImage}
        {deleteTransactionDialog}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Transaction</h4>

              <h4 className={classes.cardButtonTitle} color="primary">
                <Button
                  link
                  simple
                  onClick={() => {
                    handleCreateTranaction();
                  }}
                  color="primary"
                >
                  <AddCircle />
                  Transaction
                </Button>
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "Date",
                    accessor: "gatePassDate",
                  },
                  {
                    Header: "Party Name",
                    accessor: "partyName",
                  },
                  {
                    Header: "Gate Pass",
                    accessor: "gatePassNumber",
                  },
                  {
                    Header: "Order No",
                    accessor: "orderNumber",
                  },
                  {
                    Header: "Vehicle No",
                    accessor: "vehicleNo",
                  },
                  {
                    Header: "Division",
                    accessor: "division",
                  },
                  {
                    Header: "Type",
                    accessor: "type",
                  },
                  {
                    Header: "Sub Type",
                    accessor: "subType",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={data}
              />
              <Dialog open={open} onClose={handleCloseWindow}>
                <TransactionCreate />
              </Dialog>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
