import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { Lightbox } from "react-modal-image";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import { fetchThumbnailAction } from "../../redux/actions/thumbnail-action";
import { fetchTransactionImageAction } from "../../redux/actions/image-action";
import { fetchTranactionDocumentAction } from 'redux/actions/transaction-action';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ThumbnailDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showLightBox, setShowLightBox] = useState(false);
  const [imageName, setImageName] = useState(false);
  const [lightBoxImage, setLightBoxImage] = useState("");
  const allTransactionThumbnails = useSelector(
    (state) => state.thumbnails.transactionThumbnails
  );
  const document = useSelector((state) => state.transactions.document)
  const transactionImage = useSelector(
    (state) => state.images.transactionImages
  );
  useEffect(() => {
    batch(() => {
      dispatch(fetchThumbnailAction(props.transaction._id));
      dispatch(fetchTranactionDocumentAction(props.transaction._id));
    })
  }, []);

  useEffect(() => {

    if(transactionImage.length > 0) {
     
      //setLightBoxImage(transactionImage)
    }
  }, [transactionImage]);

  const handleDialogClose = (state) => {
    props.parentCallback(state);
  };

  const showImageLightBox = (imageUrl, imageName) => {
    // dispatch(fetchTransactionImageAction(imageId));
    setLightBoxImage(`${imageUrl}`);
    setShowLightBox(true);
    setImageName(imageName);
  };

  const closeImageLightbox = () => {
    setShowLightBox(false);
  };
  
  const licenceData = document ? document.map((item, index) => (
    <ImageListItem key={index}>
      <img
        src={item.licence}
        alt='Licence'
      />
      <ImageListItemBar
        title='Licence'
        actionIcon={
          <IconButton
            aria-label={`info about Licence`}
            className={classes.icon}
            onClick={() =>
              showImageLightBox(item.licence, item.licence)
            }
          >
            <AspectRatioIcon />
          </IconButton>
        }
      />
    </ImageListItem>
  )) : null;

  const photoData = document ? document.map((item, index) => (
    <ImageListItem key={index+1}>
    <img
      src={item.photo}
      alt='Photo'
    />
    <ImageListItemBar
      title='Photo'
      actionIcon={
        <IconButton
          aria-label={`info about Photo`}
          className={classes.icon}
          onClick={() =>
            showImageLightBox(item.photo, item.photo)
          }
        >
          <AspectRatioIcon />
        </IconButton>
      }
    />
    </ImageListItem>
  )) : null;

  const rcData = document ? document.map((item, index) => (
    <ImageListItem key={index}>
    <img
      src={item.rc}
      alt='RC'
    />
    <ImageListItemBar
     title='RC'
    actionIcon={
      <IconButton
        aria-label={`info about RC`}
        className={classes.icon}
        onClick={() =>
          showImageLightBox(item.rc, item.rc)
        }
      >
        <AspectRatioIcon />
      </IconButton>
      }
    />
    </ImageListItem>
  )) : null;

  const challanData = document ? document.map((item, index) => (
    <ImageListItem key={index}>
    <img
      src={item.challan}
      alt='Challan'
    />
    <ImageListItemBar
      title='Challan'
      actionIcon={
        <IconButton
          aria-label={`info about Licence`}
          className={classes.icon}
          onClick={() =>
            showImageLightBox(item.challan, item.challan)
          }
        >
        <AspectRatioIcon />
      </IconButton>
      }
    />
    </ImageListItem>
  )) : null;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {showLightBox && (
          <Lightbox
            large={lightBoxImage}
            alt={imageName}
            onClose={closeImageLightbox}
          />
        )}
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          open={props.show}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleDialogClose(false)}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>Transaction Images</h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <ImageList rowHeight={180} className={classes.imageList}>
              {licenceData}
              {photoData}
              {rcData}
              {challanData}
              {allTransactionThumbnails.map((item) => (
                <ImageListItem key={item.imageId}>
                  <img
                    src={item.thumbnailUrl}
                    alt={item.thumbnailName}
                  />
                  <ImageListItemBar
                    title={item.thumbnailName}
                    subtitle={<span>by: {item.captureTime}</span>}
                    actionIcon={
                      <IconButton
                        aria-label={`info about ${item.thumbnailName}`}
                        className={classes.icon}
                        onClick={() =>
                          showImageLightBox(item.imageUrl, item.thumbnailName)
                        }
                      >
                        <AspectRatioIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => handleDialogClose(false)} color="warning">
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
