import { SubType } from "../../models/subtype-model";
import {
    CREATE_SUBTYPE,
    UPDATE_SUBTYPE,
    DELETE_SUBTYPE,
    SET_SUBTYPES,
    SET_SUBTYPES_BY_TYPE,
    SET_SUBTYPES_ACTION_MESSAGE,
} from "../types/subtype-type";
const initialState = {
    subtypes: [],
    subTypesByType: [],
    actionMessage: "",
    message: "",
    status: false,
};

const subtypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBTYPES:
            return {
                ...state,
                subtypes: action.subtypes,
            };
        case SET_SUBTYPES_BY_TYPE:
            return {
                ...state,
                subTypesByType: action.subtypes,
            };
        case CREATE_SUBTYPE:
            // const newsubType = new SubType(
            // 	action.subtypeData._id,
            // 	action.subtypeData.name,
            // 	action.subtypeData.typeId,
            // 	action.subtypeData.divisionId,
            // 	action.subtypeData.active,
            // 	action.subtypeData.createdAt
            // );
            return {
                ...state,
                subtypes: state.subtypes.concat(action.subtypeData),
            };
        case UPDATE_SUBTYPE:
            const subtypeIndex = state.subtypes.findIndex(
                (type) => type.id === action.id
            );
            const updatedType = new SubType(
                action.subtypeData._id,
                action.subtypeData.subTypeName,
                action.subtypeData.type,
                action.subtypeData.division,
                action.subtypeData.active,
                action.subtypeData.createdAt,
                action.subtypeData.userId
            );

            const updatedSubTypes = [...state.subtypes];
            updatedSubTypes[subtypeIndex] = updatedType;
            return {
                ...state,
                subtypes: updatedSubTypes,
                message: action.message,
                status: true,
            };
        case SET_SUBTYPES_ACTION_MESSAGE:
            return {
                ...state,
                message: action.message,
                status: false,
            };
        case DELETE_SUBTYPE:
            return {
                ...state,
                subtypes: state.subtypes.filter(
                    (subtype) => subtype.id !== action.id
                ),
            };
    }
    return state;
};

export default subtypeReducer;
