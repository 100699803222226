import axios from "axios";
import { ApiUrl } from "../../constants/apiurls";
import { StorageKey } from "../../constants/text";
import {
    CREATE_DIVISION,
    UPDATE_DIVISION,
    DELETE_DIVISION,
    SET_DIVISIONS,
    SET_DIVISION_ACTION_RESULT,
} from "../types/division-type";

export const fetchDivisionAction = () => {
    const url = ApiUrl.getAllDivisions;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .get(url, config)
            .then((response) => {
                dispatch({
                    type: SET_DIVISIONS,
                    divisions: response.data.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SET_DIVISION_ACTION_RESULT,
                    actionMessage: error.message,
                });
            });
    };
};

export const createDivisionAction = (data) => {
    const url = ApiUrl.createDivision;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .post(url, data, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CREATE_DIVISION,
                        divisionData: response.data.data,
                    });
                } else {
                    dispatch({
                        type: SET_DIVISION_ACTION_RESULT,
                        actionMessage: response.data.message,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_DIVISION_ACTION_RESULT,
                    actionMessage: `Failed to fetch divisions. ${JSON.stringify(
                        error.message
                    )}`,
                });
            });
    };
};

export const updateDivisionAction = (data) => {
    const url = `${ApiUrl.updateDivision}/${data.id}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .put(url, data, config)
            .then((response) => {
                dispatch({
                    type: UPDATE_DIVISION,
                    _id: response.data.data._id,
                    divisionData: response.data.data,
                    message: response.data.message,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SET_DIVISION_ACTION_RESULT,
                    actionMessage: `Failed to update divisions. ${JSON.stringify(
                        error.message
                    )}`,
                });
            });
    };
};

export const deleteDivisionAction = (divisionId) => {
    const url = `${ApiUrl.deleteDivision}/${divisionId}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        axios
            .delete(url, config)
            .then((response) => {
                dispatch({ type: DELETE_DIVISION, id: divisionId });
                dispatch({
                    type: SET_DIVISION_ACTION_RESULT,
                    actionMessage: "Successfully deleted divisions.",
                });
            })
            .catch((error) => {
                dispatch({
                    type: SET_DIVISION_ACTION_RESULT,
                    actionMessage: `Failed to fetch divisions. ${JSON.stringify(
                        error.message
                    )}`,
                });
            });
    };
};
