import _ from "underscore";
import {
    SET_TRANSACTION_STATUS_REPORT,
    SET_DATA_SYNC_STATUS_REPORT,
    SET_TRANSACTION_REPORT,
    SET_TRANSACTION_STATUS,
    SET_TRANSACTION_SEARCHBY,
    SET_ACTION_MESSAGE,
} from "../types/report-type";
const initialState = {
    filteredRecord: [],
    transactionReports: [],
    transactionsSearchBy: [],
    ewaybill: [],
    gatedetails: null,
    debitnote: [],
    pcr: [],
    grosstare: null,
    purchaseorder: [],
    scrapquality: [],
    message: "",
    status: false,
};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSACTION_STATUS_REPORT:
            return {
                ...state,
                filteredRecord: action.filteredRecord,
            };

        case SET_DATA_SYNC_STATUS_REPORT:
            return {
                ...state,
            };
        case SET_TRANSACTION_REPORT:
            return {
                ...state,
                transactionReports: action.transactionReport,
                message: action.message,
                status: true,
            };
        case SET_TRANSACTION_SEARCHBY:
            return {
                ...state,
                transactionsSearchBy: action.transactionsSearchBy,
                message: action.message,
                status: true,
            };
        case SET_TRANSACTION_STATUS:
            return {
                ...state,
                ewaybill: action.ewaybill,
                gatedetails: action.gatedetails,
                debitnote: action.debitnote,
                pcr: action.pcr,
                grosstare: action.grosstare,
                purchaseorder: action.purchaseorder,
                scrapquality: action.scrapquality,
                message: action.message,
                status: true,
            };
        case SET_ACTION_MESSAGE:
            return {
                ...state,
                message: action.message,
                status: false,
            };
        default:
            return state;
    }
};

export default reportReducer;
