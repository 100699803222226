import { Device } from "../../models/device-model";
import {
    ADD_DEVICE,
    UPDATE_DEVICE,
    DELETE_DEVICE,
    SET_ALL_DEVICE,
    SET_ACTION_DEVICE_MESSAGE,
    CLEAR_ACTION_DEVICE_MESSAGE,
} from "../types/device";

const initialState = {
    allDevices: [],
    message: "",
};

const deviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_DEVICE:
            return {
                allDevices: action.devices,
            };
        case ADD_DEVICE:
            const newDevice = new Device(
                action.deviceData._id,
                action.deviceData.deviceId,
                action.deviceData.osType,
                action.deviceData.validity,
                action.deviceData.userId,
                action.deviceData.active,
                action.deviceData.allowedAt
            );
            return {
                ...state,
                allDevices: state.allDevices.concat(newDevice),
                message: action.message,
            };
        case SET_ACTION_DEVICE_MESSAGE:
            return {
                ...state,
                message: action.message,
            };
    }
    return state;
};
export default deviceReducer;
