import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AdminDashboard from "./AdminDashboard";
import ManagerDashboard from "./ManagerDashboard";
import OperatorDashboard from "./OperatorDashboard";
import SuperAdminDashboard from "./SuperAdminDashboard";
import VendorDashboard from "./VendorDashboard";

export default function Dashboard() {
  const role = useSelector((state) => state.auth.role);
  switch (role.toLowerCase()) {
    case "admin":
      return <AdminDashboard />;
    case "manager":
      return <ManagerDashboard />;
    case "operator":
      return <OperatorDashboard />;
    case "superadmin":
      return <SuperAdminDashboard />;
    case "vendor":
      return <VendorDashboard />;
    default:
      return <AdminDashboard />;
  }
}
