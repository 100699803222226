import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";

import UpdatePassword from 'views/UpdatePassword/UpdatePassword';

import { readUnreadTranactionAction } from "../../redux/actions/transaction-action";
import { windowAction } from "../../redux/actions/window-action";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Snackbar from "components/Snackbar/Snackbar.js";
// import Button from '../CustomButtons/Button';

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const [openNotification, setOpenNotification] = React.useState(null);
  const [tl, setTc] = React.useState(false);
  const openP = useSelector((state) => state.window.openP);
  const unreadCount = useSelector((state) => state.transactions.unreadCount);
  const unreadTransaction = useSelector(
    (state) => state.transactions.unreadTransaction
  );

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(null);

  const dispatch = useDispatch();
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleUpdatePassword = () => {
    dispatch(windowAction({ openP: true }));
  };
  const handleCloseWindow = () => {
    dispatch(windowAction({ openP: false }));
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const showNotification = (prop) => {
    setTc(true);
    dispatch(readUnreadTranactionAction(prop));
    setTimeout(() => {
      setTc(false);
    }, 1000);
  };

  const handleUserLogout = () => {
    dispatch({ type: "LOGOUT" });
    dispatch({ type: "RESET_APP" });
  };

  const menuItemData = unreadTransaction
    ? unreadTransaction.map((data) => (
      <div key={data._id}>
        <Button
          fullWidth
          color="primary"
          key={data._id}
          onClick={() => showNotification(data._id)}
        >
          {data.partyName + " (" + data.partyCode + ")"}
        </Button>
        <Snackbar
          place="tr"
          color="info"
          message={`Party Name ${data.partyName}`}
          open={tl}
          closeNotification={() => setTc(false)}
          close
        />
      </div>
    ))
    : [];
  const classes = useStyles();
  const { rtlActive } = props;
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive,
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });
  return (
    <div className={wrapper}>
      <CustomInput
        rtlActive={rtlActive}
        formControlProps={{
          className: classes.top + " " + classes.search,
        }}
        inputProps={{
          placeholder: rtlActive ? "بحث" : "Search",
          inputProps: {
            "aria-label": rtlActive ? "بحث" : "Search",
            className: classes.searchInput,
          },
        }}
      />
      <Button
        color="white"
        aria-label="edit"
        justIcon
        round
        className={searchButton}
      >
        <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
      </Button>
      <Button
        color="transparent"
        simple
        aria-label="Dashboard"
        justIcon
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : "",
        }}
      >
        <Dashboard
          className={
            classes.headerLinksSvg +
            " " +
            (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
          }
        />
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>
            {rtlActive ? "لوحة القيادة" : "Dashboard"}
          </span>
        </Hidden>
      </Button>
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Notifications
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <span className={classes.notifications}>{unreadCount}</span>
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              {rtlActive ? "إعلام" : "Notification"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">{menuItemData}</MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {/* <Popper
                    open={Boolean(openNotification)}
                    anchorEl={openNotification}
                    transition
                    disablePortal
                    placement="bottom"
                    className={classNames({
                        [classes.popperClose]: !openNotification,
                        [classes.popperResponsive]: true,
                        [classes.popperNav]: true,
                    })}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            id="notification-menu-list"
                            style={{ transformOrigin: "0 0 0" }}
                        >
                            <Paper className={classes.dropdown}>
                                <ClickAwayListener
                                    onClickAway={handleCloseNotification}
                                >
                                    <MenuList role="menu">
                                        <MenuItem
                                            onClick={handleCloseNotification}
                                            className={dropdownItem}
                                        >
                                            {rtlActive
                                                ? "إجلاء أوزار الأسيوي حين بل, كما"
                                                : "Mike John responded to your email"}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleCloseNotification}
                                            className={dropdownItem}
                                        >
                                            {rtlActive
                                                ? "شعار إعلان الأرضية قد ذلك"
                                                : "You have 5 new tasks"}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleCloseNotification}
                                            className={dropdownItem}
                                        >
                                            {rtlActive
                                                ? "ثمّة الخاصّة و على. مع جيما"
                                                : "You're now friend with Andrew"}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleCloseNotification}
                                            className={dropdownItem}
                                        >
                                            {rtlActive
                                                ? "قد علاقة"
                                                : "Another Notification"}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleCloseNotification}
                                            className={dropdownItem}
                                        >
                                            {rtlActive
                                                ? "قد فاتّبع"
                                                : "Another One"}
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper> */}
      </div>

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : "Profile"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الملف الشخصي" : "Profile"}
                    </MenuItem>
                    <MenuItem onClick={handleUpdatePassword} className={dropdownItem}>
                      {rtlActive ? "الإعدادات" : "Password Update"}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleUserLogout}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الخروج" : "Log out"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Dialog open={openP} onClose={handleCloseWindow}>
          <UpdatePassword />
        </Dialog>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
