/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Login_Footer(props) {
  const classes = useStyles();
  const history = useHistory();
  const { fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  return (
    <footer className={classes.footer} style={{ width: "auto" }}>
      <div className={container}>
        <div className={classes.left}>
        </div>
        <p className={classes.right}>
          <a
            href="javascript:"
            onClick={() => history.push("/privacy-policy")}
            style={{
              alignItems: "self-end",
              textAlign: "right",
              color: "white",
            }}
          >
            {" "}
            Privacy policy
          </a>
        </p>
      </div>
    </footer>
  );
}