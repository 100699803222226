import axios from "axios";
import { ApiUrl } from "../../constants/apiurls";
import { StorageKey } from "../../constants/text";
import {
    ADD_USER,
    UPDATE_USER,
    DELETE_USER,
    SET_USER,
    SET_ALL_USER,
    SET_ALL_VENDOR,
    UPDATE_PASSWORD,
    SET_USER_ERROR_MESSAGE,
    CHANGE_VENDOR_PASSWORD,
    SET_ACTION_USER_MESSAGE,
    CLEAR_ACTION_USER_MESSAGE,
} from "../types/user";

export const fetchUserAction = () => {
    const url = `${ApiUrl.getAllUser}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem("UserData");
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .get(url, config)
            .then((response) => {
                dispatch({ type: SET_ALL_USER, users: response.data.data });
            })
            .catch((error) => {
                dispatch({
                    type: SET_ACTION_USER_MESSAGE,
                    message: error.message,
                });
            });
    };
};
export const updatePasswordAction = (props) => {
    console.log(props.id)
    const url = `${ApiUrl.changePassword}/${props.id}`;
    const data = {
        newPassword: props.newPassword,
        confirmPassword: props.confirmPassword,
    }
    return async (dispatch) => {
        const userData = await localStorage.getItem("UserData");
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .put(url, data, config)
            .then((response) => {
                if(response.data.success) {
                    dispatch({ 
                        type: UPDATE_PASSWORD, 
                        message: response.data.message,
                        status: response.data.success,
                    });
                } else {
                    dispatch({ 
                        type: SET_USER_ERROR_MESSAGE, 
                        message: response.data.message,
                        status: response.data.success,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_USER_ERROR_MESSAGE,
                    message: error.response.data.message,
                    status: error.response.data.success,
                });
            });
    };
};

export const fetchVendorAction = () => {
    const url = `${ApiUrl.getAllVendor}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem("UserData");
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .get(url, config)
            .then((response) => {
                dispatch({ type: SET_ALL_VENDOR, vendors: response.data.data });
            })
            .catch((error) => {
                dispatch({
                    type: SET_ACTION_USER_MESSAGE,
                    message: error.message,
                });
            });
    };
};

export const createUserAction = (data) => {
    const url = ApiUrl.register;
    return async (dispatch) => {
        const userData = await localStorage.getItem("UserData");
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .post(url, data, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ADD_USER,
                        userData: response.data.data,
                    });
                } else {
                    dispatch({
                        type: SET_ACTION_USER_MESSAGE,
                        message: response.data.message,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_ACTION_USER_MESSAGE,
                    message: error.response.data.message,
                });
            });
    };
};

export const updateUserAction = (data) => {
    const url = `${ApiUrl.updateUser}/${data.userId}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .put(url, data, config)
            .then((response) => {
                dispatch({
                    type: UPDATE_USER,
                    userData: response.data.data,
                    message: response.data.message,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SET_USER_ERROR_MESSAGE,
                    message: error.message,
                });
            });
    };
};

export const changeVendorPasswordAction = (userName, data) => {
    const url = `${ApiUrl.changeVendorPassword}/${userName}`;
    return async (dispatch) => {
        // const userData = await localStorage.getItem(StorageKey.UserData);
        // const userDataJson = JSON.parse(userData);
        // const config = {
        // 	headers: { Authorization: `Bearer ${userDataJson.token}` },
        // };

        await axios
            .put(url, data)
            .then((response) => {
                if (response.data.changePasswordStatus) {
                    dispatch({
                        type: CHANGE_VENDOR_PASSWORD,
                        message: response.data.message,
                        status: response.data.success,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_ACTION_USER_MESSAGE,
                    message: error.response.data.message,
                    status: error.response.data.success,
                });
            });
    };
};

export const changePasswordAction = (id, data) => {
    const url = `${ApiUrl.changePassword}/${id}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .put(url, data, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: SET_ACTION_USER_MESSAGE,
                        message: response.data.message,
                        status: response.data.success,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_USER_ERROR_MESSAGE,
                    message: error.response.data.message,
                    status: error.response.data.success,
                });
            });
    };
};

export const deleteUserAction = (userId) => {
    const url = `${ApiUrl.deleteUser}/${userId}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .delete(url, config)
            .then((response) => {
                dispatch({
                    type: DELETE_USER,
                    userId: userId,
                    message: response.data.message,
                });
            })
            .catch((error) => {
                dispatch({ type: SET_USER_ERROR_MESSAGE, message: error.message });
            });
    };
};
