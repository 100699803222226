import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import moment from "moment";
// @material-ui/core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// material ui icons
import DevicesOutlined from "@material-ui/icons/DevicesOutlined";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import CollectionsIcon from "@material-ui/icons/Collections";
import {
    Assignment,
    Book,
    DeveloperBoard,
    Domain,
    DonutSmall,
    Dvr,
    Gradient,
    HighQuality,
    Person,
    Report,
    Search,
} from "@material-ui/icons";
import { widgetStories, bugs, website, server } from "variables/general.js";

// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import DetailsDialog from "../Details/DetailsDialog";
import ReportDialog from "../Report/ReportDialog";
import ThumbnailDialog from "../Thumbnail/ThumbnailDialog";

import { fetchTransactionReportAction } from "../../redux/actions/report-action";
import { fetchDivisionAction } from "../../redux/actions/division-action";
import { fetchTypeAction } from "../../redux/actions/type-action";
import { fetchSubTypeAction } from "../../redux/actions/subtype-action";
import {
    createDivisionAction,
    updateDivisionAction,
} from "../../redux/actions/division-action";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import CustomTabs from "components/CustomTabs/CustomTabs";
import BugReport from "@material-ui/icons/BugReport";
import Tasks from "components/Tasks/Tasks";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import { isGetAccessor } from "typescript";
import { TextField } from "@material-ui/core";
import Table from "components/ReactTable/ReactTable";
const useStyles = makeStyles(styles);

export default function TransactionReport() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("");
    const [division, setDivision] = useState("");
    const [searchTextState, setSearchTextState] = useState("");
    const [divisionState, setDivisionState] = useState("");

    const ewaybill = useSelector((state) => state.reports.ewaybill);
    debugger
    const gatedetails = useSelector((state) => state.reports.gatedetails);
    const debitnote = useSelector((state) => state.reports.debitnote);
    const pcr = useSelector((state) => state.reports.pcr);
    const grosstare = useSelector((state) => state.reports.grosstare);
    const purchaseorder = useSelector((state) => state.reports.purchaseorder);
    const scrapquality = useSelector((state) => state.reports.scrapquality);
    const divisionData = useSelector((state) => state.divisions.divisions);

    useEffect(() => {
        dispatch(fetchDivisionAction());
    }, []);

    useEffect(() => {
        if (searchText.length >= 6 && division) {
            dispatch(fetchTransactionReportAction(searchText, division));
        }
    }, [searchText, division]);

    const divisionList = divisionData.map((data) => (
        <MenuItem value={data.unit} key={data._id}>
            {data.division + "(" + data.unit + ")"}
        </MenuItem>
    ));

    const verifySearchText = (value) => {
        return value !== 0;
    };
    const verifyDivision = (value) => {
        return value !== 0;
    };

    const ewayBillData = ewaybill
        ? ewaybill.map((data) => {
            return {
                ewayBillNo: data.eway_bill_no,
                ewayBillDate: data.eway_bill_date,
                billTyNo: data.bill_ty_no,
                supPlace: data.sup_place,
                distance: data.distance,
            }
        })
        : [];
    const gatedetailsData = gatedetails
        ? gatedetails.map((data) => {
            return {
                date: data.createdAt,
                gatePassNo: data.gate_pass_no,
                truckNo: data.truck_no,
                partyName: data.party_name,
                billNo: data.bill_no,
                itnm: data.itnm,
                gatePassQty: data.gate_pass_qty,
            }
        })
        : [];
         
    const debitnoteData = debitnote
        ? debitnote.map((data) => {
              return {
                  adv_no: data.adv_no,
                  categoty_name: data.category_name,
                  qty: data.qty,
                  rate: data.rate,
                  value: data.val,
              };
          })
        : "";
    const pcrData = pcr
        ? pcr.map((data) => {
              return {
                  pcrNo: data.purchase_recipt_no,
                  poNo: data.purchase_order_no,
                  qty: data.qty,
                  rate: data.rate,
                  val: data.val,
              };
          })
        : "";

    const grosstareData = grosstare
        ? [
              {
                  gatePassNo: grosstare.gate_pass_no,
                  grossWeight: grosstare.gross_wt,
                  tareWeight: grosstare.tare_wt,
              },
          ]
        : [];
    const purchaseorderData = purchaseorder
        ? purchaseorder.map((data, index) => {
            return {
                orderNo: data.order_no,
                categoryName: data.category_name,
                qty: data.qty,
                rate: data.rate,
                recQty: data.rec_qty,
            }
          })
        : "";
    const scrapqualityData = scrapquality
        ? scrapquality.map((data) => {
              return {
                  scrapId: data.divisionId,
                  weight: data.weight,
                  percent: data.per,
                  categoryName: data.category_name,
              };
          })
        : "";

    return (
        <div>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Status</h4>
                            <h4 className={classes.cardCenterTitle}>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={5}>
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                            className={
                                                classes.selectFormControl
                                            }
                                        >
                                            <InputLabel
                                                htmlFor="simple-select"
                                                className={classes.selectLabel}
                                            >
                                                Select Division
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className:
                                                        classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                onChange={(event) => {
                                                    if (
                                                        verifyDivision(
                                                            event.target.value
                                                        )
                                                    ) {
                                                        setDivisionState(
                                                            "success"
                                                        );
                                                    } else {
                                                        setDivisionState(
                                                            "error"
                                                        );
                                                    }
                                                    setDivision(
                                                        event.target.value
                                                    );
                                                }}
                                                inputProps={{
                                                    name: "division",
                                                    id: "division",
                                                    value: division,
                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root:
                                                            classes.selectMenuItem,
                                                    }}
                                                    value="division"
                                                >
                                                    Division
                                                </MenuItem>
                                                {divisionList}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={5}>
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            placeholder="Search Text"
                                            label="Search Text"
                                            value={searchText}
                                            onChange={(event) =>
                                                setSearchText(
                                                    event.target.value
                                                )
                                            }
                                        ></TextField>
                                    </GridItem>
                                </GridContainer>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomTabs
                                    title="Status:"
                                    headerColor="rose"
                                    tabs={[
                                        {
                                            tabName: "Eway Bill",
                                            tabIcon: Book,
                                            tabContent: (
                                                <>
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header: "Eway Bill No",
                                                                accessor:
                                                                    "ewayBillNo",
                                                            },
                                                            {
                                                                Header:
                                                                    "Eway Bill Date",
                                                                accessor:
                                                                    "ewayBillDate",
                                                            },
                                                            {
                                                                Header:
                                                                    "BillTy No",
                                                                accessor:
                                                                    "billTyNo",
                                                            },
                                                            {
                                                                Header:
                                                                    "Sup Place",
                                                                accessor:
                                                                    "supPlace",
                                                            },
                                                            {
                                                                Header:
                                                                    "Distance",
                                                                accessor:
                                                                    "distance",
                                                            },
                                                        ]}
                                                        data={ewayBillData}
                                                    />
                                                </>
                                            ),
                                        },
                                        {
                                            tabName: "Gate Details",
                                            tabIcon: Book,
                                            tabContent: (
                                                <>
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header: "Date",
                                                                accessor:
                                                                    "date",
                                                            },
                                                            {
                                                                Header:
                                                                    "Gate Pass No",
                                                                accessor:
                                                                    "gatePassNo",
                                                            },
                                                            {
                                                                Header:
                                                                    "Vechile No",
                                                                accessor:
                                                                    "truckNo",
                                                            },
                                                            {
                                                                Header:
                                                                    "Party Name",
                                                                accessor:
                                                                    "partyName",
                                                            },
                                                            {
                                                                Header:
                                                                    "Bill No",
                                                                accessor:
                                                                    "billNo",
                                                            },
                                                            {
                                                                Header:
                                                                    "Item Name",
                                                                accessor:
                                                                    "itnm",
                                                            },
                                                            {
                                                                Header:
                                                                    "Gate Pass Qty",
                                                                accessor:
                                                                    "gatePassQty",
                                                            },
                                                        ]}
                                                        data={gatedetailsData}
                                                    />
                                                </>
                                            ),
                                        },
                                        {
                                            tabName: "Debit Note",
                                            tabIcon: Domain,
                                            tabContent: (
                                                <>
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header:
                                                                    "Dedit Note No",
                                                                accessor:
                                                                    "adv_no",
                                                            },
                                                            {
                                                                Header:
                                                                    "Category Name",
                                                                accessor:
                                                                    "categoty_name",
                                                            },
                                                            {
                                                                Header:
                                                                    "Quantity",
                                                                accessor: "qty",
                                                            },
                                                            {
                                                                Header: "Rate",
                                                                accessor:
                                                                    "rate",
                                                            },
                                                            {
                                                                Header: "Value",
                                                                accessor:
                                                                    "value",
                                                            },
                                                        ]}
                                                        data={debitnoteData}
                                                    />
                                                </>
                                            ),
                                        },
                                        {
                                            tabName: "Pcr",
                                            tabIcon: DonutSmall,
                                            tabContent: (
                                                <>
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header:
                                                                    "PCR No",
                                                                accessor:
                                                                    "pcrNo",
                                                            },
                                                            {
                                                                Header: "PO No",
                                                                accessor:
                                                                    "poNo",
                                                            },
                                                            {
                                                                Header:
                                                                    "Quantity",
                                                                accessor: "qty",
                                                            },
                                                            {
                                                                Header: "Rate",
                                                                accessor:
                                                                    "rate",
                                                            },
                                                            {
                                                                Header: "Value",
                                                                accessor: "val",
                                                            },
                                                        ]}
                                                        data={pcrData}
                                                    />
                                                </>
                                            ),
                                        },
                                        {
                                            tabName: "Gross Tare",
                                            tabIcon: DeveloperBoard,
                                            tabContent: (
                                                <>
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header:
                                                                    "Gate Pass No",
                                                                accessor:
                                                                    "gatePassNo",
                                                            },
                                                            {
                                                                Header:
                                                                    "Gross Weight",
                                                                accessor:
                                                                    "grossWeight",
                                                            },
                                                            {
                                                                Header:
                                                                    "Tare Weight",
                                                                accessor:
                                                                    "tareWeight",
                                                            },
                                                        ]}
                                                        data={grosstareData}
                                                    />
                                                </>
                                            ),
                                        },
                                        {
                                            tabName: "Purchase Order",
                                            tabIcon: Gradient,
                                            tabContent: (
                                                <>
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header:
                                                                    "Order No",
                                                                accessor:
                                                                    "orderNo",
                                                            },
                                                            {
                                                                Header:
                                                                    "Category Name",
                                                                accessor:
                                                                    "categoryName",
                                                            },
                                                            {
                                                                Header:
                                                                    "Quantity",
                                                                accessor:
                                                                    "qty",
                                                            },
                                                            {
                                                                Header:
                                                                    "Rate",
                                                                accessor:
                                                                    "rate",
                                                            },
                                                            {
                                                                Header:
                                                                    "Receive Quantity",
                                                                accessor:
                                                                    "recQty",
                                                            },
                                                        ]}
                                                        data={purchaseorderData}
                                                    />
                                                </>
                                            ),
                                        },
                                        {
                                            tabName: "Scrap Quality",
                                            tabIcon: HighQuality,
                                            tabContent: (
                                                <>
                                                    <ReactTable
                                                        columns={[
                                                              {
                                                                Header:
                                                                    "Scrap Id",
                                                                accessor:
                                                                    "scrapId",
                                                            },
                                                            {
                                                                Header:
                                                                    "Weight",
                                                                accessor:
                                                                    "weight",
                                                            },
                                                            {
                                                                Header:
                                                                    "Percent",
                                                                accessor:
                                                                    "percent",
                                                            },
                                                            {
                                                                Header:
                                                                    "Category Name",
                                                                accessor:
                                                                    "categoryName",
                                                            },
                                                        ]}
                                                        data={scrapqualityData}
                                                    />
                                                </>
                                            ),
                                        },
                                    ]}
                                />
                            </GridItem>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
