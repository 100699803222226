export class SubType {
  constructor(id, name, typeId, divisionId, active, createdAt) {
    this.id = id;
    this.name = name;
    this.typeId = typeId;
    this.divisionId = divisionId;
    this.active = active;
    this.createdAt = createdAt;
  }
}
  