import React from "react";
import { Switch, useHistory, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// App imports
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import PrivateRoute from "layouts/PrivateRoute";
import PublicRoute from "layouts/PublicRoute";
import Policy from "components/Policy/Policy";

function App() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const history = useHistory();

    return <Pages isAuthenticated={isAuthenticated} />;
}
const checkForAuthentication = (isAuthenticated) => {
    if (!isAuthenticated) {
        return <Redirect from="/" to="/auth/login-page" />;
    }
    return <Redirect from="/" to="/admin/dashboard" />;
};

function Pages(isAuthenticated) {
    return (
        <Switch>
            <PublicRoute exact path="/privacy-policy" component={Policy} />
            <PublicRoute path="/auth" component={AuthLayout} />
            <PrivateRoute
                path="/admin"
                component={AdminLayout}
                render={() => checkForAuthentication(isAuthenticated)}
            />
            <Redirect from="/" to="/auth/login-page" />
        </Switch>
    );
}

export default App;
