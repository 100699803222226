import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import {
    fetchUserAction,
    updateUserAction,
} from "../../redux/actions/user-action";
import { windowAction } from "../../redux/actions/window-action";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: 400,
        height: 450,
    },
    icon: {
        color: "rgba(255, 255, 255, 0.54)",
    },
}));

const User = React.forwardRef(function User(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function UpdateUser(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [role, setRole] = useState(props.user.role);
    const [name, setName] = useState(props.user.name);
    const [userName, setUserName] = useState(props.user.userName);
    const [email, setEmail] = useState(props.user.email);
    const [mobileNo, setMobileNo] = useState(props.user.mobileNo);
    const [roleState, setRoleState] = useState("success");
    const [nameState, setNameState] = useState("success");
    const [userNameState, setUserNameState] = useState("success");
    const [emailState, setEmailState] = useState("success");
    const [mobileNoState, setMobileNoState] = useState("success");

    const message = useSelector((state) => state.user.message);
    const status = useSelector((state) => state.user.status);

    const verifyEmail = (value) => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };
    const verifyLength = (value, length) => {
        return value.length >= length;
    };
    const verifyRole = (value) => {
        return value.length !== 0;
    };
    const verifyName = (value) => {
        return value.length !== 0;
    };
    const verifyUserName = (value) => {
        return value.length !== 0;
    };
    const verifyMobileNo = (value) => {
        return value.length !== 0;
    };

    if (status) {
        setTimeout(() => {
            handleDialogClose(false);
            dispatch({
                type: "SET_ACTION_USER_MESSAGE",
                message: "",
                status: false,
            });
        }, 3000);
    }

    const handleSubmit = () => {
        if (roleState === "") {
            setRoleState("error");
            return;
        }
        if (nameState === "") {
            setNameState("error");
            return;
        }
        if (userNameState === "") {
            setUserNameState("error");
            return;
        }
        if (emailState === "") {
            setEmailState("error");
            return;
        }
        const data = {
            userId: props.user._id,
            name,
            userName,
            mobileNo,
            email,
            role,
        };
        handleReset();
        dispatch(updateUserAction(data));
    };

    const handleReset = (event) => {
        setRole("");
        setName("");
        setUserName("");
        setMobileNo("");
        setEmail("");
    };
    const handleDialogClose = (state) => {
        props.parentCallback(state);
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Dialog
                    classes={{
                        root: classes.center + " " + classes.modalRoot,
                        paper: classes.modal,
                    }}
                    open={props.show}
                    UserComponent={User}
                    keepMounted
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="notice-modal-slide-title"
                    aria-describedby="notice-modal-slide-description"
                >
                    <DialogTitle
                        id="notice-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle}>Update User</h4>
                    </DialogTitle>
                    <DialogContent
                        id="notice-modal-slide-description"
                        className={classes.modalBody}
                    >
                        {/* Custom input */}

                        <form>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={10}>
                                    <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                    >
                                        <InputLabel
                                            htmlFor="simple-select"
                                            className={classes.selectLabel}
                                        >
                                            Select Role
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            onChange={(event) => {
                                                if (
                                                    verifyRole(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setRoleState("success");
                                                } else {
                                                    setRoleState("error");
                                                }
                                                setRole(event.target.value);
                                            }}
                                            inputProps={{
                                                name: "role",
                                                id: "role",
                                                value: role,
                                            }}
                                        >
                                            <MenuItem
                                                disabled
                                                classes={{
                                                    root:
                                                        classes.selectMenuItem,
                                                }}
                                                value=""
                                            >
                                                Role
                                            </MenuItem>
                                            <MenuItem
                                                classes={{
                                                    root:
                                                        classes.selectMenuItem,
                                                    selected:
                                                        classes.selectMenuItemSelected,
                                                }}
                                                value="SuperAdmin"
                                            >
                                                Super Admin
                                            </MenuItem>
                                            <MenuItem
                                                classes={{
                                                    root:
                                                        classes.selectMenuItem,
                                                    selected:
                                                        classes.selectMenuItemSelected,
                                                }}
                                                value="Admin"
                                            >
                                                Admin
                                            </MenuItem>
                                            <MenuItem
                                                classes={{
                                                    root:
                                                        classes.selectMenuItem,
                                                    selected:
                                                        classes.selectMenuItemSelected,
                                                }}
                                                value="Manager"
                                            >
                                                Manager
                                            </MenuItem>
                                            <MenuItem
                                                classes={{
                                                    root:
                                                        classes.selectMenuItem,
                                                    selected:
                                                        classes.selectMenuItemSelected,
                                                }}
                                                value="Operator"
                                            >
                                                Operator
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={5}>
                                    <CustomInput
                                        success={nameState === "success"}
                                        error={nameState === "error"}
                                        labelText="Full Name *"
                                        id="fullname"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (
                                                    verifyName(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setNameState("success");
                                                } else {
                                                    setNameState("error");
                                                }
                                                setName(event.target.value);
                                            },
                                            type: "text",
                                            value: name,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={5}>
                                    <CustomInput
                                        success={userNameState === "success"}
                                        error={userNameState === "error"}
                                        labelText="User Name *"
                                        id="username"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (
                                                    verifyUserName(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setUserNameState("success");
                                                } else {
                                                    setUserNameState("error");
                                                }
                                                setUserName(event.target.value);
                                            },
                                            type: "text",
                                            value: userName,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={5}>
                                    <CustomInput
                                        success={mobileNoState === "success"}
                                        error={mobileNoState === "error"}
                                        labelText="Mobile Number *"
                                        id="mobilenumber"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (
                                                    verifyMobileNo(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setMobileNoState("success");
                                                } else {
                                                    setMobileNoState("error");
                                                }
                                                setMobileNo(event.target.value);
                                            },
                                            type: "text",
                                            value: mobileNo,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={5}>
                                    <CustomInput
                                        success={emailState === "success"}
                                        error={emailState === "error"}
                                        labelText="Email Address *"
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (
                                                    verifyEmail(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setEmailState("success");
                                                } else {
                                                    setEmailState("error");
                                                }
                                                setEmail(event.target.value);
                                            },
                                            type: "email",
                                            value: email,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={5}>
                                    {message}
                                </GridItem>
                            </GridContainer>
                        </form>
                    </DialogContent>
                    <DialogActions
                        className={
                            classes.modalFooter +
                            " " +
                            classes.modalFooterCenter
                        }
                    >
                        <Button onClick={handleSubmit} color="success">
                            Update
                        </Button>
                        <Button
                            onClick={() => handleDialogClose(false)}
                            color="warning"
                        >
                            CLOSE
                        </Button>
                    </DialogActions>
                </Dialog>
            </GridItem>
        </GridContainer>
    );
}
