import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFilePicker } from "use-file-picker";
import { random } from "underscore";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import { addTransactionImageAction } from "../../redux/actions/transaction-action";
import { PhotoCamera } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  // imageList: {
  //     width: 500,
  //     height: 450,
  // },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddTransactionImage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.transactions.message);
  const status = useSelector((state) => state.transactions.status);

  const handleDialogClose = (state) => {
    props.parentCallback(state);
  };

  if(status) {
    setTimeout(() => {
      dispatch({
        type: 'SET_ACTION_MESSAGE',
        message: '',
        status: false,
      })
      props.parentCallback(false);
    },3000)
  }

  const handleSubmit = () => {
    const images = [];
    imageData.map((image, index) => {
      images.push({ base64: image.base64, name: image.name });
    });
    const licence = [];
    licenceData.map((image, index) => {
      licence.push({ base64: image.base64, name: image.name });
    });
    const photo = [];
    photoData.map((image, index) => {
      photo.push({ base64: image.base64, name: image.name });
    });
    const rc = [];
    RCData.map((image, index) => {
      rc.push({ base64: image.base64, name: image.name });
    });
    const challan = [];
    challanData.map((image, index) => {
      challan.push({ base64: image.base64, name: image.name });
    });
    const data = {
      images,
      licence,
      photo,
      rc,
      challan,
    };
    dispatch(addTransactionImageAction(data, props.transaction._id));
  };

  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: true,
    limitFilesConfig: { max: 10 },
  });

  const [openLicenceSelector, lacencePickerData] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });
  const [openPhotoSelector, photoPickerData] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });
  const [openRCSelector, RCPickerData] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });
  const [openChallanSelector, challanPickerData] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });

  const imageData = [];
  {
    filesContent.map((file, index) => {
      let data = file.content.split(";");
      let base64 = data[1].replace(",", ":").split(":");
      let objbase64 = {
        base64: base64[1],
        name: file.name,
        file: file.content,
      };
      imageData.push(objbase64);
    });
  }
  const licenceData = [];
  {
    lacencePickerData.filesContent.map((file, index) => {
      let data = file.content.split(";");
      let base64 = data[1].replace(",", ":").split(":");
      let objbase64 = {
        base64: base64[1],
        name: file.name,
        file: file.content,
        key: random(10, 100),
      };
      licenceData.push(objbase64);
    });
  }
  const photoData = [];
  {
    photoPickerData.filesContent.map((file, index) => {
      let data = file.content.split(";");
      let base64 = data[1].replace(",", ":").split(":");
      let objbase64 = {
        base64: base64[1],
        name: file.name,
        file: file.content,
        key: random(10, 100),
      };
      photoData.push(objbase64);
    });
  }
  const RCData = [];
  {
    RCPickerData.filesContent.map((file, index) => {
      let data = file.content.split(";");
      let base64 = data[1].replace(",", ":").split(":");
      let objbase64 = {
        base64: base64[1],
        name: file.name,
        file: file.content,
        key: random(10, 100),
      };
      RCData.push(objbase64);
    });
  }
  const challanData = [];
  {
    challanPickerData.filesContent.map((file, index) => {
      let data = file.content.split(";");
      let base64 = data[1].replace(",", ":").split(":");
      let objbase64 = {
        base64: base64[1],
        name: file.name,
        file: file.content,
        key: random(10, 100),
      };
      challanData.push(objbase64);
    });
  }

  const imageList = (
    <>
      {" "}
      {imageData.map((data, index) => (
        <img
          height={50}
          width="auto"
          alt={data.name}
          src={data.file}
          key={index}
        ></img>
      ))}
    </>
  );
  const licenceImage = (
    <>
      {" "}
      {licenceData.map((data) => (
        <img
          height={40}
          width="auto"
          alt={data.name}
          src={data.file}
          key={data.key}
        ></img>
      ))}
    </>
  );
  const photoImage = (
    <>
      {" "}
      {photoData.map((data) => (
        <img
          height={40}
          width="auto"
          alt={data.name}
          src={data.file}
          key={data.key}
        ></img>
      ))}
    </>
  );
  const RCImage = (
    <>
      {" "}
      {RCData.map((data) => (
        <img
          height={40}
          width="auto"
          alt={data.name}
          src={data.file}
          key={data.key}
        ></img>
      ))}
    </>
  );
  const challanImage = (
    <>
      {" "}
      {challanData.map((data) => (
        <img
          height={40}
          width="auto"
          alt={data.name}
          src={data.file}
          key={data.key}
        ></img>
      ))}
    </>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          open={props.show}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleDialogClose(false)}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>Add Transaction Images</h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            {/* Image Adding Content */}
            <GridContainer>
              <GridItem xs={12} sm={3}>
                {licenceImage}
              </GridItem>
              <GridItem xs={12} sm={3}>
                {photoImage}
              </GridItem>
              <GridItem xs={12} sm={3}>
                {RCImage}
              </GridItem>
              <GridItem xs={12} sm={3}>
                {challanImage}
              </GridItem>

              <GridItem xs={12} sm={3}>
                <Button
                  block
                  color="success"
                  onClick={openLicenceSelector}
                  className={classes.Button}
                >
                  {/* <CardMembership /> */}
                  DL
                </Button>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <Button
                  block
                  color="success"
                  onClick={openPhotoSelector}
                  className={classes.Button}
                >
                  {/* <CameraFrontIcon /> */}
                  Photo
                </Button>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <Button
                  block
                  color="success"
                  onClick={openRCSelector}
                  className={classes.Button}
                >
                  {/* <CreditCard /> */}
                  RC
                </Button>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <Button
                  block
                  color="success"
                  onClick={openChallanSelector}
                  className={classes.Button}
                >
                  {/* <Receipt /> */}
                  Chln
                </Button>
              </GridItem>

              <GridItem xs={12} sm={10}>
                {imageList}
              </GridItem>
              <GridItem xs={12} sm={10}>
              </GridItem>
              <GridItem xs={12} sm={10}>
                {message}
              </GridItem>
              <GridItem xs={12} sm={10}>
                <Button
                  color="rose"
                  onClick={handleSubmit}
                  className={classes.Button}
                >
                  Submit
                </Button>
                <Button
                  color="success"
                  onClick={openFileSelector}
                  className={classes.Button}
                >
                  <PhotoCamera />
                </Button>
                <Button onClick={() => handleDialogClose(false)} color="warning">
                  CLOSE
                </Button>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
