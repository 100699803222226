/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// @material-ui/core components
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

// material ui icons
import DevicesOutlined from '@material-ui/icons/DevicesOutlined';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import { fetchDivisionAction } from '../../redux/actions/division-action';
import { fetchTypeAction } from '../../redux/actions/type-action';
import { fetchTypeByDivisionAction } from '../../redux/actions/type-action';
import { createSubTypeAction } from '../../redux/actions/subtype-action';
import { windowAction } from '../../redux/actions/window-action';

// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';

const useStyles = makeStyles(styles);

export default function SubType() {
	const dispatch = useDispatch();
	const [division, setDivision] = useState('');
	const [divisionState, setDivisionState] = useState('');
	const [type, setType] = useState('');
	const [typeState, setTypeState] = useState('');
	const [subTypeName, setSubTypeName] = useState('');
	const [subTypeNameState, setSubTypeNameState] = useState('');

	const divisionData = useSelector((state) => state.divisions.divisions);
	const typeData = useSelector((state) => state.types.typesDivision);

	useEffect(() => {
		dispatch(fetchDivisionAction());
	}, []);
	useEffect(() => {
		if (division) {
			dispatch(fetchTypeByDivisionAction({ division: division }));
		}
	}, [division]);

	const divisionList = divisionData.map((data) => (
		<MenuItem value={data._id} key={data._id}>
			{data.division + '(' + data.unit + ')'}
		</MenuItem>
	));
	const typeList = typeData.map((data) => (
		<MenuItem value={data._id} key={data._id}>
			{data.typeName}
		</MenuItem>
	));
    
	const verifyDivision = (value) => {
		return value.length !== 0;
	};
	const verifyType = (value) => {
		return value.length !== 0;
	};
	const verifySubTypeName = (value) => {
		return value.length !== 0;
	};
	const handleSubmit = () => {
		if (divisionState === '') {
			setDivisionState('error');
			return;
		}
		if (typeState === '') {
			setTypeState('error');
			return;
		}
		if (subTypeNameState === '') {
			setSubTypeNameState('error');
			return;
		}
		const data = {
			division: division,
			type: type,
			subTypeName: subTypeName,
		};
		handleReset();
		dispatch(createSubTypeAction(data));
	};

	const handleCloseWindow = () => {
		dispatch(windowAction({ open: false }));
	};

	const handleReset = (event) => {
		setDivision('');
		setType('');
		setSubTypeName('');
		setDivisionState('');
		setTypeState('');
		setSubTypeNameState('');
	};

	const classes = useStyles();
	return (
		<GridContainer justify="center">
			<GridItem xs={12} sm={10} md={8}>
				<Card>
					<CardHeader color="rose" icon>
						<CardIcon color="rose">
							<DevicesOutlined />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Add New Sub Type</h4>
					</CardHeader>
					<CardBody>
						<form>
							<GridContainer justify="center">
								<GridItem xs={12} sm={10}>
									<FormControl fullWidth className={classes.selectFormControl}>
										<InputLabel
											htmlFor="simple-select"
											className={classes.selectLabel}
										>
											Select Division
										</InputLabel>
										<Select
											MenuProps={{
												className: classes.selectMenu,
											}}
											classes={{
												select: classes.select,
											}}
											onChange={(event) => {
												if (verifyDivision(event.target.value)) {
													setDivisionState('success');
												} else {
													setDivisionState('error');
												}
												setDivision(event.target.value);
											}}
											inputProps={{
												name: 'division',
												id: 'division',
												value: division,
											}}
										>
											<MenuItem
												disabled
												classes={{
													root: classes.selectMenuItem,
												}}
												value="division"
											>
												Division
											</MenuItem>
											{divisionList}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={10}>
									<FormControl fullWidth className={classes.selectFormControl}>
										<InputLabel
											htmlFor="simple-select"
											className={classes.selectLabel}
										>
											Select Type
										</InputLabel>
										<Select
											MenuProps={{
												className: classes.selectMenu,
											}}
											classes={{
												select: classes.select,
											}}
											onChange={(event) => {
												if (verifyType(event.target.value)) {
													setTypeState('success');
												} else {
													setTypeState('error');
												}
												setType(event.target.value);
											}}
											inputProps={{
												name: 'type',
												id: 'type',
												value: type,
											}}
										>
											<MenuItem
												disabled
												classes={{
													root: classes.selectMenuItem,
												}}
												value="type"
											>
												Type
											</MenuItem>
											{typeList}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem xs={12} sm={10}>
									<CustomInput
										success={subTypeNameState === 'success'}
										error={subTypeNameState === 'error'}
										labelText="Sub Type Name *"
										id="subtypename"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											onChange: (event) => {
												if (verifySubTypeName(event.target.value)) {
													setSubTypeNameState('success');
												} else {
													setSubTypeNameState('error');
												}
												setSubTypeName(event.target.value);
											},
											type: 'text',
											value: subTypeName,
										}}
									/>
								</GridItem>
								<GridItem xs={12} sm={5}>
									<Button
										color="rose"
										onClick={handleSubmit}
										className={classes.Button}
									>
										Add
									</Button>
								</GridItem>
								<GridItem xs={12} sm={5}>
									<Button
										color="warning"
										onClick={handleCloseWindow}
										className={classes.Button}
									>
										Close
									</Button>
								</GridItem>
							</GridContainer>
						</form>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
