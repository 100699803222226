import axios from 'axios';
import { ApiUrl } from '../../constants/apiurls';
import { StorageKey } from '../../constants/text';
import {
	SET_TOTAL_GROSS_WEIGHT,
	SET_TOTAL_TARE_WEIGHT,
	SET_ACTION_MESSAGE,
	CLEAR_ACTION_MESSAGE,
} from '../types/sync-type';

export const fetchGrossWeightAction = () => {
	const url = ApiUrl.getTotalGrossWeigth;
	return async (dispatch) => {
		const userData = await localStorage.getItem(StorageKey.UserData);
		const userDataJson = JSON.parse(userData);

		try {
			const config = {
				headers: { Authorization: `Bearer ${userDataJson.token}` },
			};
			await axios
				.get(url, config)
				.then((response) => {
					dispatch({
						type: SET_TOTAL_GROSS_WEIGHT,
						totalGrossWeight: response.data.data,
					});
				})
				.catch((error) => {
					dispatch({ type: SET_ACTION_MESSAGE, message: error.message });
				});
		} catch (err) {
			throw err;
		}
	};
};
export const fetchTareWeightAction = () => {
	const url = ApiUrl.getTotalTareWeigth;
	return async (dispatch) => {
		const userData = await localStorage.getItem(StorageKey.UserData);
		const userDataJson = JSON.parse(userData);

		try {
			const config = {
				headers: { Authorization: `Bearer ${userDataJson.token}` },
			};
			await axios
				.get(url, config)
				.then((response) => {
					dispatch({
						type: SET_TOTAL_TARE_WEIGHT,
						totalTareWeight: response.data.data,
					});
				})
				.catch((error) => {
					dispatch({ type: SET_ACTION_MESSAGE, message: error.message });
				});
		} catch (err) {
			throw err;
		}
	};
};
