import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/icons/List";
import Assignment from "@material-ui/icons/Assignment";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactTable from "components/ReactTable/ReactTable.js";

import { fetchDivisionAction } from "../../redux/actions/division-action";
import {
    fetchTypeAction,
    updateTypeAction,
    deleteTypeAction,
} from "../../redux/actions/type-action";
import { windowAction } from "../../redux/actions/window-action";
import Type from "./Type";
import UpdateType from "./UpdateType";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import myStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useMyStyles = makeStyles(myStyles);

export default function TypeList() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [updateType, setUpdateType] = useState(null);

    const allDivisions = useSelector((state) => state.divisions.divisions);
    const allTypes = useSelector((state) => state.types.types);
    const open = useSelector((state) => state.window.open);

    useEffect(() => {
        batch(() => {
            dispatch(fetchDivisionAction());
            dispatch(fetchTypeAction());
        });
    }, []);

    const handleCreateType = () => {
        dispatch(windowAction({ open: true }));
    };

    const handleUpdateTypeCallback = (state) => {
        if (!state) {
            setUpdateType(null);
        }
    };

    const handleCloseWindow = () => {
        dispatch(windowAction({ open: false }));
    };

    // Prepare table rows
    const data = allTypes.map((prop, key) => {
        const typeDivision = (function () {
            if (allDivisions && allDivisions.length > 0) {
                let division = allDivisions.find(
                    (item) => item._id === prop.division
                );
                if (division) {
                    return division.division;
                }
            }
            return "N/A";
        })();
        const typeDivisionName = (function () {
            if (allDivisions && allDivisions.length > 0) {
                let division = allDivisions.find(
                    (item) => item._id === prop.division
                );
                if (division) {
                    return division.divisionName;
                }
            }
            return "";
        })();
        return {
            id: prop._id,
            typeName: prop.typeName,
            division: `${typeDivisionName}( ${typeDivision} )`,
            actions: (
                <div className="actions-right">
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() => {
                            setUpdateType(
                                <UpdateType
                                    show={true}
                                    parentCallback={handleUpdateTypeCallback}
                                    type={prop}
                                    divisionName={typeDivisionName}
                                    division={typeDivision}
                                />
                            );
                        }}
                        color="info"
                        className="like"
                    >
                        <Edit />
                    </Button>
                    {/* <Button
						justIcon
						round
						simple
						onClick={() => {
							setShowDetailsDialog(
								<DetailsDialog
									show={true}
									parentCallback={handleDetailsCallback}
									transaction={prop}
									division={transactionDivision}
									type={transactionType}
									subType={transactionSubType}
								/>
							);
						}}
						color="warning"
						className="edit"
					>
						<Dvr />
					</Button> */}
                </div>
            ),
        };
    });

    return (
        <GridContainer>
            {updateType}
            {/* {showThumbnailsDialog} */}
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Assignment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Type List</h4>
                        <h2 className={classes.cardButtonTitle} color="primary">
                            <Button
                                round
                                onClick={() => {
                                    handleCreateType();
                                }}
                                color="primary"
                            >
                                <PersonAdd />
                                Type
                            </Button>
                        </h2>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            columns={[
                                {
                                    Header: "Type Name",
                                    accessor: "typeName",
                                },
                                {
                                    Header: "Division Name",
                                    accessor: "division",
                                },
                                {
                                    Header: "Actions",
                                    accessor: "actions",
                                },
                            ]}
                            data={data}
                        />
                        <Dialog open={open} onClose={handleCloseWindow}>
                            <Type />
                        </Dialog>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

// export default function TypeList() {
// 	const classes = useStyles();
// 	const myClasses = useMyStyles();
// 	const dispatch = useDispatch();
// 	const [alertMessage, setAlertMessage] = useState(null);
// 	const typeData = useSelector((state) => state.types.types);
// 	const open = useSelector((state) => state.window.open);
// 	useEffect(() => {
// 		dispatch(fetchTypeAction());
// 	}, []);
// 	// if (typeData.length === 0) {
// 	// 	return <div>Loding...</div>;
// 	// }

// 	const handleOpenWindow = (event) => {
// 		dispatch(windowAction({ open: true }));
// 	};
// 	const handleCloseWindow = (event) => {
// 		dispatch(windowAction({ open: false }));
// 	};

// 	const handleEdit = (data) => {
// 		console.log(`Type ID : ` + data);
// 	};
// 	const handleRemove = (data) => {
// 		dispatch(deleteTypeAction(data));
// 		hideAlert();
// 	};

// 	const warningWithConfirmAndCancelMessage = (data) => {
// 		setAlertMessage(
// 			<SweetAlert
// 				warning
// 				style={{ display: 'block', marginTop: '-100px' }}
// 				title="Are you sure?"
// 				onConfirm={() => successDelete(data)}
// 				onCancel={() => cancelDetele()}
// 				confirmBtnCssClass={myClasses.button + ' ' + myClasses.success}
// 				cancelBtnCssClass={myClasses.button + ' ' + myClasses.danger}
// 				confirmBtnText="Yes, delete it!"
// 				cancelBtnText="Cancel"
// 				showCancel
// 			>
// 				You want to delete the type.
// 			</SweetAlert>
// 		);
// 	};

// 	const successDelete = (data) => {
// 		setAlertMessage(
// 			<SweetAlert
// 				success
// 				style={{ display: 'block', marginTop: '-100px' }}
// 				title="Deleted!"
// 				onConfirm={() => handleRemove(data)}
// 				onCancel={() => hideAlert()}
// 				confirmBtnCssClass={myClasses.button + ' ' + myClasses.success}
// 			>
// 				Type has been deleted.
// 			</SweetAlert>
// 		);
// 	};
// 	const cancelDetele = () => {
// 		setAlertMessage(
// 			<SweetAlert
// 				danger
// 				style={{ display: 'block', marginTop: '-100px' }}
// 				title="Cancelled"
// 				onConfirm={() => hideAlert()}
// 				onCancel={() => hideAlert()}
// 				confirmBtnCssClass={myClasses.button + ' ' + myClasses.success}
// 			>
// 				Type is safe.
// 			</SweetAlert>
// 		);
// 	};
// 	const hideAlert = () => {
// 		setAlertMessage(null);
// 	};

// 	const data = [];
// 	typeData.map((type, index) => {
// 		let d = [];
// 		d.push(type.division);
// 		d.push(type.typeName);
// 		const edit = (
// 			<Button
// 				round
// 				color="success"
// 				className={classes.actionButton + ' ' + classes.actionButtonRound}
// 				key={index}
// 				value={type._id}
// 				onClick={(e) => {
// 					handleEdit(e.target.value);
// 				}}
// 			>
// 				<Edit className={classes.icon} />
// 			</Button>
// 		);
// 		const remove = (
// 			<Button
// 				round
// 				color="danger"
// 				className={classes.actionButton + ' ' + classes.actionButtonRound}
// 				key={type._id}
// 				value={type._id}
// 				onClick={(e) => {
// 					warningWithConfirmAndCancelMessage(e.target.value);
// 				}}
// 			>
// 				<Close className={classes.icon} />
// 			</Button>
// 		);
// 		const btn = [];
// 		btn.push(edit);
// 		btn.push(remove);
// 		d.push(btn);
// 		data.push(d);
// 	});
// 	const thName = ['Division', 'Type Name', 'Action'];

// 	return (
// 		<div>
// 			{alertMessage}
// 			<GridContainer>
// 				<GridItem xs={12}>
// 					<Card>
// 						<CardHeader>
// 							<CardIcon color="success">
// 								<List />
// 							</CardIcon>
// 							<h4 className={classes.cardIconTitle}>All Devices</h4>
// 							<Button
// 								round
// 								color="danger"
// 								className={
// 									classes.actionButton + ' ' + classes.actionButtonRound
// 								}
// 								onClick={(e) => {
// 									handleOpenWindow(e);
// 								}}
// 							>
// 								<PersonAdd className={classes.icon} />
// 							</Button>
// 							<Dialog open={open} onClose={handleCloseWindow}>
// 								<Type />
// 							</Dialog>
// 						</CardHeader>
// 						<CardBody>
// 							<GridContainer justify="space-between">
// 								<GridItem xs={12} sm={12} md={12}>
// 									<Table tableHead={thName} tableData={data} />
// 								</GridItem>
// 							</GridContainer>
// 						</CardBody>
// 					</Card>
// 				</GridItem>
// 			</GridContainer>
// 		</div>
// 	);
// }
