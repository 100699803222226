import axios from "axios";
import { ApiUrl } from "../../constants/apiurls";
import { StorageKey } from "../../constants/text";
import {
    ADD_DEVICE,
    UPDATE_DEVICE,
    DELETE_DEVICE,
    SET_ALL_DEVICE,
    SET_ACTION_DEVICE_MESSAGE,
    CLEAR_ACTION_DEVICE_MESSAGE,
} from "../types/device";

export const fetchDeviceAction = () => {
    const url = ApiUrl.getAllDevices;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .get(url, config)
            .then((response) => {
                dispatch({
                    type: SET_ALL_DEVICE,
                    devices: response.data.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SET_ACTION_DEVICE_MESSAGE,
                    message: error.message,
                });
            });
    };
};

export const addDeviceAction = (data) => {
    const url = ApiUrl.getAllDevices;
    return async (dispatch) => {
        const userData = await localStorage.getItem("UserData");
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .post(url, data, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ADD_DEVICE,
                        deviceData: response.data.data,
                        message: response.data.message,
                    });
                } else {
                    dispatch({
                        type: SET_ACTION_DEVICE_MESSAGE,
                        message: response.data.message,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_ACTION_DEVICE_MESSAGE,
                    message: error.response.data.message,
                });
            });
    };
};
