import React from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContentText, DialogTitle, DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { deleteTransactionAction } from 'redux/actions/transaction-action';

export default function DeleteTransaction(props) {
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(deleteTransactionAction(props.transaction._id));
        props.parentCallback(false);
    }

    const handleClose = (state) => {
        props.parentCallback(state);
    }

    return(
        <>
        <div>
            <Dialog onClick={() => {handleClose(false)}} open={props.show}>
                <DialogTitle>Delete Transaction</DialogTitle>
                <DialogContentText>
                    Are you sure you want to delete {props.transaction.partyName}'s transaction
                </DialogContentText>
                <DialogActions>
                    <Button onClick={() => {handleClose(false)}}>
                        Close
                    </Button>
                    <Button onClick={handleDelete}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        </>
    )
}