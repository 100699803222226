import axios from "axios";
import { ApiUrl } from "../../constants/apiurls";
import { StorageKey } from "../../constants/text";
import {
  SET_TRANSACTIONS_COUNT,
  CREATE_TRANSACTION,
  ADD_TRANSACTION_IMAGE,
  SET_ACTION_MESSAGE,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION,
  SET_TRANSACTIONS,
  SET_TO_BE_PROCCESSED_TRANSACTION,
  SET_UNREAD_TRANSACTIONS,
  SET_READ_UNREAD_TRANSACTIONS,
  SET_TRANSACTION_DOCUMENT,
  SET_ALL_TO_BE_PROCCESSED_TRANSACTION,
} from "../types/transaction-type";

export const fetchTranactionAction = (prop) => {
  const url = ApiUrl.getAllTransaction;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: {
        Authorization: `Bearer ${userDataJson.token}`,
        pageLimiter: false,
      },
    };
    await axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: SET_TRANSACTIONS,
          transactions: response.data.data,
          count: response.data.count,
          nextpage: response.data.pagination?.next?.page,
          limit: response.data.pagination?.next?.limit,
        });
      })
      .catch((error) => {
        dispatch({ type: SET_ACTION_MESSAGE, message: error.message });
      });
  };
};

export const fetchTranactionDocumentAction = (prop) => {
  const url = `${ApiUrl.getTransactionDocument}/${prop}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: {
        Authorization: `Bearer ${userDataJson.token}`,
        pageLimiter: false,
      },
    };
    await axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: SET_TRANSACTION_DOCUMENT,
          document: response.data.documents,
        });
      })
      .catch((error) => {
        dispatch({ type: SET_ACTION_MESSAGE, message: error.message });
      });
  };
};

export const fetchUnreadTranactionAction = (prop) => {
  const url = ApiUrl.getUnreadTransaction;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: {
        Authorization: `Bearer ${userDataJson.token}`,
      },
    };
    await axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: SET_UNREAD_TRANSACTIONS,
          transactions: response.data.data,
          count: response.data.count,
        });
      })
      .catch((error) => {
        dispatch({ type: SET_ACTION_MESSAGE, message: error.message });
      });
  };
};
export const readUnreadTranactionAction = (id) => {
  const url = `${ApiUrl.readUnreadTransaction}/${id}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: {
        Authorization: `Bearer ${userDataJson.token}`,
      },
    };
    await axios
      .put(url, { isRead: true }, config)
      .then((response) => {
        dispatch({
          type: SET_READ_UNREAD_TRANSACTIONS,
          transactions: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: SET_ACTION_MESSAGE, message: error.message });
      });
  };
};

export const fetchVendorTranactionAction = () => {
  const url = ApiUrl.getAllVendorTransaction;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: { Authorization: `Bearer ${userDataJson.token}` },
    };
    await axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: SET_TRANSACTIONS,
          transactions: response.data.data,
          count: response.data.count,
          nextpage: response.data.pagination?.next?.page,
          limit: response.data.pagination?.next?.limit,
        });
      })
      .catch((error) => {
        dispatch({ type: SET_ACTION_MESSAGE, message: error.message });
      });
  };
};

export const fetchTranactionCount = () => {
  const url = ApiUrl.lastTransaction;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: { Authorization: `Bearer ${userDataJson.token}` },
    };
    await axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: SET_TRANSACTIONS_COUNT,
          lastTransactionData: response.data.lastTransaction,
          allTransactionData: response.data.allTransaction,
        });
      })
      .catch((error) => {
        dispatch({ type: SET_ACTION_MESSAGE, message: error.message });
      });
  };
};

export const fetchAllTranactionToBeProccessed = () => {
  const url = `${ApiUrl.getAllRecordsToBeProcesed}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: { Authorization: `Bearer ${userDataJson.token}` },
    };

    await axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: SET_ALL_TO_BE_PROCCESSED_TRANSACTION,
          allToBeProccessed: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: SET_ACTION_MESSAGE, message: error.message });
      });
  };
};

export const fetchTranactionToBeProccessed = (division, gatePassDate) => {
  const url = `${ApiUrl.getRecordsToBeProcesed}/${division}/${gatePassDate}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: { Authorization: `Bearer ${userDataJson.token}` },
    };

    await axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: SET_TO_BE_PROCCESSED_TRANSACTION,
          toBeProccessed: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: SET_ACTION_MESSAGE, message: error.message });
      });
  };
};

export const createTransactionAction = (data) => {
  const url = ApiUrl.submitTransaction;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: { Authorization: `Bearer ${userDataJson.token}` },
    };

    await axios
      .post(url, data, config)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: CREATE_TRANSACTION,
            transaction: response.data.data,
            message: response.data.message,
            status: response.data.success,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_ACTION_MESSAGE,
          message: error.response.data.message,
          status: error.response.dada.status,
        });
      });
  };
};

export const addTransactionImageAction = (data, txnId) => {
  const url = `${ApiUrl.addTransactionImage}/${txnId}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);
    const config = {
      headers: { Authorization: `Bearer ${userDataJson.token}` },
    };

    await axios
      .put(url, data, config)
      .then((response) => {
        if(response.data.success) {
          dispatch({
            type: ADD_TRANSACTION_IMAGE,
            message: response.data.message,
            status: response.data.success,
          })
        }
        if(!response.data.success) {
          dispatch({
            type: ADD_TRANSACTION_IMAGE,
            message: response.data.message,
            status: response.data.success,
          })
        }
      })
      .catch((error) => {
        // NOTE: Getting success responce and error at the same time error is undefined id 
        // dispatch({
        //   type: SET_ACTION_MESSAGE,
        //   message: error.message,
        // })
      })
  };
};

export const updateTransactionAction = (data) => {
  const url = `${ApiUrl.updateTransaction}/${data._id}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);
    const config = {
      headers: { Authorization: `Bearer ${userDataJson.token}` },
    };

    await axios
      .put(url, data, config)
      .then((response) => {
        dispatch({
          type: UPDATE_TRANSACTION,
          updatedData: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ACTION_MESSAGE,
          message: error.response.data.message,
        });
      });
  };
};

export const deleteTransactionAction = (transactionId) => {
  const url = `${ApiUrl.deleteTransaction}/${transactionId}/transaction`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);
    const config = {
      headers: { Authorization: `Bearer ${userDataJson.token}` },
    };

    await axios
      .delete(url, config)
      .then((response) => {
        dispatch({
          type: DELETE_TRANSACTION,
          transactionId: transactionId,
          message: response.data.message,
          status: response.data.success,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ACTION_MESSAGE,
          message: error.response.data.message,
        });
      });
  };
};
