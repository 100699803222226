import { SET_WINDOWS_ACTION } from '../types/window-action-type';

const initialState = {
	open: false,
	openP: false,
};

const windowReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_WINDOWS_ACTION:
		 if (action.open) {
		  return {
		   open: true,
		  };
		 } else if (action.openP) {
		  return { openP: true };
		 } else {
		  return {
		   open: false,
		   openP: false,
		  };
		 }
	   }
	   return state;
};

export default windowReducer;
