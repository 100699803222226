export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const SET_USER = "SET_USER";
export const SET_ALL_USER = "SET_ALL_USER";
export const SET_ALL_VENDOR = "SET_ALL_VENDOR";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const SET_USER_ERROR_MESSAGE = "SET_USER_ERROR_MESSAGE";
export const CHANGE_VENDOR_PASSWORD = "UPDATE_VENDOR_PASSWORD";
export const SET_ACTION_USER_MESSAGE = "SET_ACTION_USER_MESSAGE";
export const CLEAR_ACTION_USER_MESSAGE = "CLEAR_ACTION_USER_MESSAGE";