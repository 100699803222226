/*eslint-disable*/
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// @material-ui/core components
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// material ui icons
import PersonAddOutlined from '@material-ui/icons/PersonAddOutlined';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import { createUserAction } from '../../redux/actions/user-action';
import { windowAction } from '../../redux/actions/window-action';

// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';

const useStyles = makeStyles(styles);

export default function AddUser() {
	const dispatch = useDispatch();
	const [role, setRole] = useState('');
	const [roleState, setRoleState] = useState('');
	const [name, setName] = useState('');
	const [nameState, setNameState] = useState('');
	const [userName, setUserName] = useState('');
	const [userNameState, setUserNameState] = useState('');
	const [email, setEmail] = useState('');
	const [emailState, setEmailState] = useState('');
	const [mobileNo, setMobileNo] = useState('');
	const [mobileNoState, setMobileNoState] = useState('');
	const [password, setPassword] = useState('');
	const [passwordState, setPasswordState] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [confirmPasswordState, setConfirmPasswordState] = useState('');

	const verifyEmail = (value) => {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	};
	const verifyLength = (value, length) => {
		return value.length >= length;
	};
	const verifyRole = (value) => {
		return value.length !== 0;
	};
	const verifyName = (value) => {
		return value.length !== 0;
	};
	const verifyUserName = (value) => {
		return value.length !== 0;
	};
	const verifyMobileNo = (value) => {
		return value.length !== 0;
	};
	const handleSubmit = () => {
		if (roleState === '') {
			setRoleState('error');
			return;
		}
		if (nameState === '') {
			setNameState('error');
			return;
		}
		if (userNameState === '') {
			setUserNameState('error');
			return;
		}
		if (emailState === '') {
			setEmailState('error');
			return;
		}
		if (passwordState === '') {
			setPasswordState('error');
			return;
		}
		if (confirmPasswordState === '') {
			setConfirmPasswordState('error');
		}
		const data = {
			name,
			userName,
			mobileNo,
			email,
			password,
			role,
		};
		handleReset();
		dispatch(createUserAction(data));
	};

	const handleCloseWindow = () => {
		dispatch(windowAction({ open: false }));
	};

	const handleReset = (event) => {
		setRole('');
		setName('');
		setUserName('');
		setMobileNo('');
		setEmail('');
		setPassword('');
		setConfirmPassword('');
	};

	const classes = useStyles();
	return (
		<DialogContent>
			<GridContainer justify="center">
				<GridItem xs={12} sm={10} md={8}>
					<Card>
						<CardHeader color="rose" icon>
							<CardIcon color="rose">
								<PersonAddOutlined />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Add New User</h4>
						</CardHeader>
						<CardBody>
							<form>
								<GridContainer justify="center">
									<GridItem xs={12} sm={10}>
										<FormControl
											fullWidth
											className={classes.selectFormControl}
										>
											<InputLabel
												htmlFor="simple-select"
												className={classes.selectLabel}
											>
												Select Role
											</InputLabel>
											<Select
												MenuProps={{
													className: classes.selectMenu,
												}}
												classes={{
													select: classes.select,
												}}
												onChange={(event) => {
													if (verifyRole(event.target.value)) {
														setRoleState('success');
													} else {
														setRoleState('error');
													}
													setRole(event.target.value);
												}}
												inputProps={{
													name: 'role',
													id: 'role',
													value: role,
												}}
											>
												<MenuItem
													disabled
													classes={{
														root: classes.selectMenuItem,
													}}
													value=""
												>
													Role
												</MenuItem>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected,
													}}
													value="SuperAdmin"
												>
													Super Admin
												</MenuItem>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected,
													}}
													value="Admin"
												>
													Admin
												</MenuItem>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected,
													}}
													value="Manager"
												>
													Manager
												</MenuItem>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected,
													}}
													value="Operator"
												>
													Operator
												</MenuItem>
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={12} sm={5}>
										<CustomInput
											success={nameState === 'success'}
											error={nameState === 'error'}
											labelText="Full Name *"
											id="fullname"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyName(event.target.value)) {
														setNameState('success');
													} else {
														setNameState('error');
													}
													setName(event.target.value);
												},
												type: 'text',
												value: name,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={5}>
										<CustomInput
											success={userNameState === 'success'}
											error={userNameState === 'error'}
											labelText="User Name *"
											id="username"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyUserName(event.target.value)) {
														setUserNameState('success');
													} else {
														setUserNameState('error');
													}
													setUserName(event.target.value);
												},
												type: 'text',
												value: userName,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={5}>
										<CustomInput
											success={mobileNoState === 'success'}
											error={mobileNoState === 'error'}
											labelText="Mobile Number *"
											id="mobilenumber"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyMobileNo(event.target.value)) {
														setMobileNoState('success');
													} else {
														setMobileNoState('error');
													}
													setMobileNo(event.target.value);
												},
												type: 'text',
												value: mobileNo,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={5}>
										<CustomInput
											success={emailState === 'success'}
											error={emailState === 'error'}
											labelText="Email Address *"
											id="email"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyEmail(event.target.value)) {
														setEmailState('success');
													} else {
														setEmailState('error');
													}
													setEmail(event.target.value);
												},
												type: 'email',
												value: email,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={5}>
										<CustomInput
											success={passwordState === 'success'}
											error={passwordState === 'error'}
											labelText="Password *"
											id="password"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyLength(event.target.value, 1)) {
														setPasswordState('success');
													} else {
														setPasswordState('error');
													}
													setPassword(event.target.value);
												},
												type: 'password',
												value: password,
												autoComplete: 'off',
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={5}>
										<CustomInput
											success={confirmPasswordState === 'success'}
											error={confirmPasswordState === 'error'}
											labelText="Confirm Password *"
											id="confirmpassword"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (password === event.target.value) {
														setConfirmPasswordState('success');
													} else {
														setConfirmPasswordState('error');
													}
													setConfirmPassword(event.target.value);
												},
												type: 'password',
												value: confirmPassword,
												autoComplete: 'off',
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={5}>
										<Button
											color="rose"
											onClick={handleSubmit}
											className={classes.Button}
										>
											Add
										</Button>
									</GridItem>
									<GridItem xs={12} sm={5}>
										<Button
											color="warning"
											onClick={handleCloseWindow}
											className={classes.Button}
										>
											Close
										</Button>
									</GridItem>
								</GridContainer>
							</form>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</DialogContent>
	);
}
