export const SET_TRANSACTIONS_COUNT = "SET_TRANSACTIONS_COUNT";
export const CREATE_TRANSACTION = "ADD_TRANSACTION";
export const ADD_TRANSACTION_IMAGE = "ADD_TRANSACTION_IMAGE";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const SET_TO_BE_PROCCESSED_TRANSACTION =
    "SET_TO_BE_PROCCESSED_TRANSACTION";
export const SET_ACTION_MESSAGE = "SET_ACTION_MESSAGE";
export const SET_UNREAD_TRANSACTIONS = "SET_UNREAD_TRANSACTIONS";
export const SET_READ_UNREAD_TRANSACTIONS = "SET_READ_UNREAD_TRANSACTIONS";
export const SET_TRANSACTION_DOCUMENT = "SET_TRANSACTION_DOCUMENT";
export const SET_ALL_TO_BE_PROCCESSED_TRANSACTION = "SET_ALL_TO_BE_PROCCESSED_TRANSACTION";
