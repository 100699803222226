import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/icons/List';
import Assignment from '@material-ui/icons/Assignment';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Table from 'components/Table/Table.js';
import Button from 'components/CustomButtons/Button.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import ReactTable from 'components/ReactTable/ReactTable.js';

import { fetchDivisionAction } from '../../redux/actions/division-action';
import { fetchTypeAction } from '../../redux/actions/type-action';
import {
	fetchSubTypeAction,
	updateSubTypeAction,
	deleteSubTypeAction,
} from '../../redux/actions/subtype-action';
import { windowAction } from '../../redux/actions/window-action';
import SubType from './SubType';
import UpdateSubType from './UpdateSubType';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';
import myStyles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

const useStyles = makeStyles(styles);
const useMyStyles = makeStyles(myStyles);

export default function SubTypeList() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [updateSubType, setUpdateSubType] = useState(null);
	const allDivisions = useSelector((state) => state.divisions.divisions);
	const allTypes = useSelector((state) => state.types.types);
	const allSubTypes = useSelector((state) => state.subtypes.subtypes);
	const open = useSelector((state) => state.window.open);

	useEffect(() => {
		batch(() => {
			dispatch(fetchDivisionAction());
			dispatch(fetchTypeAction());
			dispatch(fetchSubTypeAction());
		});
	}, []);
	const handleCreateSubType = () => {
        dispatch(windowAction({ open: true }));
    };

	const handleDetailsCallback = (state) => {
		if (!state) {
			setUpdateSubType(null);
		}
	};
	const handleCloseWindow = () => {
        dispatch(windowAction({ open: false }));
    };

	// Prepare table rows
	const data = allSubTypes.map((prop, key) => {
		const typeDivision = (function () {
			if (allDivisions && allDivisions.length > 0) {
				let division = allDivisions.find((item) => item._id === prop.division);
				if (division) {
					return division.division;
				}
			}
			return 'N/A';
		})();
		const typeDivisionName = (function () {
			if (allDivisions && allDivisions.length > 0) {
				let division = allDivisions.find((item) => item._id === prop.division);
				if (division) {
					return division.divisionName;
				}
			}
			return '';
		})();
		const typeName = (function () {
			if (allTypes && allTypes.length > 0) {
				let type = allTypes.find((item) => item._id === prop.type);
				if (type) {
					return type.typeName;
				}
			}
			return 'N/A';
		})();
		return {
			id: prop._id,
			subTypeName: prop.subTypeName,
			typeName: typeName,
			divisionName: `${typeDivisionName} ( ${typeDivision} )`,
			actions: (
				<div className="actions-right">
					{/* <Button
						justIcon
						round
						simple
						onClick={() => {
							setShowThumbnailsDialog(
								<ThumbnailDialog
									show={true}
									parentCallback={handleThumbnailCallback}
									transaction={prop}
								/>
							);
						}}
						color="info"
						className="like"
					>
						<CollectionsIcon />
					</Button> */}
					<Button
						justIcon
						round
						simple
						onClick={() => {
							setUpdateSubType(
								<UpdateSubType
									show={true}
									parentCallback={handleDetailsCallback}
									subType={prop}
									division={typeDivision}
									divisionName={typeDivisionName}
									type={typeName}
								/>
							);
						}}
						color="warning"
						className="edit"
					>
						<Edit />
					</Button>
				</div>
			),
		};
	});

	return (
		<GridContainer>
			{updateSubType}
			{/* {showThumbnailsDialog} */}
			<GridItem xs={12}>
				<Card>
					<CardHeader color="primary" icon>
						<CardIcon color="primary">
							<Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Sub Type List</h4>
						<h2 className={classes.cardButtonTitle} color="primary">
                            <Button
                                round
                                onClick={() => {
                                    handleCreateSubType();
                                }}
                                color="primary"
                            >
                                <PersonAdd />
                                Sub Type
                            </Button>
                        </h2>
					</CardHeader>
					<CardBody>
						<ReactTable
							columns={[
								{
									Header: 'Sub Type',
									accessor: 'subTypeName',
								},
								{
									Header: 'Type',
									accessor: 'typeName',
								},
								{
									Header: 'Division',
									accessor: 'divisionName',
								},
								{
									Header: 'Actions',
									accessor: 'actions',
								},
							]}
							data={data}
						/>
						 <Dialog open={open} onClose={handleCloseWindow}>
                            <SubType />
                        </Dialog>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}

// export default function SubTypeList() {
// 	const classes = useStyles();
// 	const myClasses = useMyStyles();
// 	const dispatch = useDispatch();
// 	const [alertMessage, setAlertMessage] = useState(null);
// 	const subTypeData = useSelector((state) => state.subtypes.subtypes);
// 	const open = useSelector((state) => state.window.open);
// 	useEffect(() => {
// 		dispatch(fetchSubTypeAction());
// 	}, []);
// 	// if (subTypeData.length === 0) {
// 	// 	return <div>Loding...</div>;
// 	// }

// 	const handleOpenWindow = (event) => {
// 		dispatch(windowAction({ open: true }));
// 	};
// 	const handleCloseWindow = (event) => {
// 		dispatch(windowAction({ open: false }));
// 	};

// 	const handleEdit = (data) => {
// 	};
// 	const handleRemove = (data) => {
// 		dispatch(deleteSubTypeAction(data));
// 		hideAlert();
// 	};

// 	const warningWithConfirmAndCancelMessage = (data) => {
// 		setAlertMessage(
// 			<SweetAlert
// 				warning
// 				style={{ display: 'block', marginTop: '-100px' }}
// 				title="Are you sure?"
// 				onConfirm={() => successDelete(data)}
// 				onCancel={() => cancelDetele()}
// 				confirmBtnCssClass={myClasses.button + ' ' + myClasses.success}
// 				cancelBtnCssClass={myClasses.button + ' ' + myClasses.danger}
// 				confirmBtnText="Yes, delete it!"
// 				cancelBtnText="Cancel"
// 				showCancel
// 			>
// 				You want to delete the sub type.
// 			</SweetAlert>
// 		);
// 	};

// 	const successDelete = (data) => {
// 		setAlertMessage(
// 			<SweetAlert
// 				success
// 				style={{ display: 'block', marginTop: '-100px' }}
// 				title="Deleted!"
// 				onConfirm={() => handleRemove(data)}
// 				onCancel={() => hideAlert()}
// 				confirmBtnCssClass={myClasses.button + ' ' + myClasses.success}
// 			>
// 				Sub type has been deleted.
// 			</SweetAlert>
// 		);
// 	};
// 	const cancelDetele = () => {
// 		setAlertMessage(
// 			<SweetAlert
// 				danger
// 				style={{ display: 'block', marginTop: '-100px' }}
// 				title="Cancelled"
// 				onConfirm={() => hideAlert()}
// 				onCancel={() => hideAlert()}
// 				confirmBtnCssClass={myClasses.button + ' ' + myClasses.success}
// 			>
// 				Sub type data is safe.
// 			</SweetAlert>
// 		);
// 	};
// 	const hideAlert = () => {
// 		setAlertMessage(null);
// 	};

// 	const data = [];
// 	subTypeData.map((dubType, index) => {
// 		let d = [];
// 		d.push(dubType.division);
// 		d.push(dubType.type);
// 		d.push(dubType.subTypeName);
// 		const edit = (
// 			<Button
// 				round
// 				color="success"
// 				className={classes.actionButton + ' ' + classes.actionButtonRound}
// 				key={index}
// 				value={dubType._id}
// 				onClick={(e) => {
// 					handleEdit(e.target.value);
// 				}}
// 			>
// 				<Edit className={classes.icon} />
// 			</Button>
// 		);
// 		const remove = (
// 			<Button
// 				round
// 				color="danger"
// 				className={classes.actionButton + ' ' + classes.actionButtonRound}
// 				key={dubType._id}
// 				value={dubType._id}
// 				onClick={(e) => {
// 					warningWithConfirmAndCancelMessage(e.target.value);
// 				}}
// 			>
// 				<Close className={classes.icon} />
// 			</Button>
// 		);
// 		const btn = [];
// 		btn.push(edit);
// 		btn.push(remove);
// 		d.push(btn);
// 		data.push(d);
// 	});
// 	const thName = ['Division', 'Type', 'Sub Type Name', 'Action'];

// 	return (
// 		<div>
// 			{alertMessage}
// 			<GridContainer>
// 				<GridItem xs={12}>
// 					<Card>
// 						<CardHeader>
// 							<CardIcon color="success">
// 								<List />
// 							</CardIcon>
// 							<h4 className={classes.cardIconTitle}>All Devices</h4>
// 							<Button
// 								round
// 								color="danger"
// 								className={
// 									classes.actionButton + ' ' + classes.actionButtonRound
// 								}
// 								onClick={(e) => {
// 									handleOpenWindow(e);
// 								}}
// 							>
// 								<PersonAdd className={classes.icon} />
// 							</Button>
// 							<Dialog open={open} onClose={handleCloseWindow}>
// 								<SubType />
// 							</Dialog>
// 						</CardHeader>
// 						<CardBody>
// 							<GridContainer justify="space-between">
// 								<GridItem xs={12} sm={12} md={12}>
// 									<Table tableHead={thName} tableData={data} />
// 								</GridItem>
// 							</GridContainer>
// 						</CardBody>
// 					</Card>
// 				</GridItem>
// 			</GridContainer>
// 		</div>
// 	);
// }
