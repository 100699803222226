import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import {
    createDivisionAction,
    updateDivisionAction,
} from "../../redux/actions/division-action";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: "rgba(255, 255, 255, 0.54)",
    },
}));

const Division = React.forwardRef(function Division(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function UpdateDivision(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [division, setDivision] = useState(props.division.division);
    const [divisionState, setDivisionState] = useState("");
    const [divisionName, setDivisionName] = useState(
        props.division.divisionName
    );
    const [divisionNameState, setDivisionNameState] = useState("");
    const [city, setCity] = useState(props.division.city);
    const [cityState, setCityState] = useState("");
    const [unit, setUnit] = useState(props.division.unit);
    const [unitState, setUnitState] = useState("");
    const actionMessage = useSelector((state) => state.divisions.actionMessage);
    const verifyDivision = (value) => {
        return value.length !== 0;
    };
    const verifyDivisionName = (value) => {
        return value.length !== 0;
    };
    const verifyCity = (value) => {
        return value.length !== 0;
    };
    const verifyUnit = (value) => {
        return value.length !== 0;
    };
    const handleSubmit = () => {
        if (division.length === 0) {
            setDivisionState("error");
            return;
        }
        if (divisionName.length === 0) {
            setDivisionNameState("error");
            return;
        }
        if (city.length === 0) {
            setCityState("error");
            return;
        }
        if (unit.length === 0) {
            setUnitState("error");
            return;
        }

        handleReset();
        const data = {
            division: division,
            divisionName: divisionName,
            city: city,
            unit: unit,
            id: props.division._id,
        };
        dispatch(updateDivisionAction(data));
    };

    const handleReset = () => {
        setDivision("");
        setDivisionName("");
        setCity("");
        setUnit("");
        setDivisionState("");
        setDivisionNameState("");
        setCityState("");
        setUnitState("");
    };

    const handleDialogClose = (state) => {
        props.parentCallback(state);
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Dialog
                    classes={{
                        root: classes.center + " " + classes.modalRoot,
                        paper: classes.modal,
                    }}
                    open={props.show}
                    DivisionComponent={Division}
                    keepMounted
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="notice-modal-slide-title"
                    aria-describedby="notice-modal-slide-description"
                >
                    <DialogTitle
                        id="notice-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle}>Update Division</h4>
                    </DialogTitle>
                    <DialogContent
                        id="notice-modal-slide-description"
                        className={classes.modalBody}
                    >
                        {/* Custom input */}

                        <form>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={5}>
                                    <CustomInput
                                        success={divisionState === "success"}
                                        error={divisionState === "error"}
                                        labelText="Division *"
                                        id="division"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (
                                                    verifyDivision(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setDivisionState("success");
                                                } else {
                                                    setDivisionState("error");
                                                }
                                                setDivision(event.target.value);
                                            },
                                            type: "text",
                                            value: division,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={5}>
                                    <CustomInput
                                        success={
                                            divisionNameState === "success"
                                        }
                                        error={divisionNameState === "error"}
                                        labelText="Division Name *"
                                        id="divisionName"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (
                                                    verifyDivisionName(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setDivisionNameState(
                                                        "success"
                                                    );
                                                } else {
                                                    setDivisionNameState(
                                                        "error"
                                                    );
                                                }
                                                setDivisionName(
                                                    event.target.value
                                                );
                                            },
                                            type: "text",
                                            value: divisionName,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={5}>
                                    <CustomInput
                                        success={cityState === "success"}
                                        error={cityState === "error"}
                                        labelText="City *"
                                        id="city"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (
                                                    verifyCity(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setCityState("success");
                                                } else {
                                                    setCityState("error");
                                                }
                                                setCity(event.target.value);
                                            },
                                            type: "text",
                                            value: city,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={5}>
                                    <CustomInput
                                        success={unitState === "success"}
                                        error={unitState === "error"}
                                        labelText="Unit"
                                        id="unit"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (
                                                    verifyUnit(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setUnitState("success");
                                                } else {
                                                    setUnitState("error");
                                                }
                                                setUnit(event.target.value);
                                            },
                                            type: "text",
                                            value: unit,
                                        }}
                                    />
                                </GridItem>
                                {actionMessage}
                            </GridContainer>
                        </form>
                    </DialogContent>
                    <DialogActions
                        className={
                            classes.modalFooter +
                            " " +
                            classes.modalFooterCenter
                        }
                    >
                        <Button onClick={handleSubmit} color="success">
                            Update
                        </Button>
                        <Button
                            onClick={() => handleDialogClose(false)}
                            color="warning"
                        >
                            CLOSE
                        </Button>
                    </DialogActions>
                </Dialog>
            </GridItem>
        </GridContainer>
    );
}
