import {
	AUTHENTICATE,
	LOGOUT,
	AUTH_ERROR,
	SET_USER_NAME,
	PASSWORD_ACTION,
	SET_ERROR_MESSAGE,
	SET_VENDOR_PASSWORD_STATUS,
} from '../types/auth-type';
import { ApiUrl } from '../../constants/apiurls';
import { StorageKey } from '../../constants/text';
import axios from 'axios';
let timer;

export const authenticate = (token, role, expiryTime) => {
	return (dispatch) => {
		dispatch(setLogoutTimer(expiryTime));
		dispatch({ type: AUTHENTICATE, role: role, token: token });
	};
};

export const loginAction = (userName, password) => {
	const url = ApiUrl.login;
	return async (dispatch) => {
		const data = { userName: userName, password: password };
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		await axios
			.post(url, data, config)
			.then((response) => {
				if (response.data.success) {
					const expirationDate = new Date(
						new Date().getTime() + 11400000
					).toISOString();

					authenticate(response.data.token, response.data.role, expirationDate);
					saveDataToStorage(
						response.data.token,
						response.data.role,
						expirationDate,
						response.data.user
					);

					dispatch({
						type: AUTHENTICATE,
						token: response.data.token,
						role: response.data.role,
						user: response.data.user,
					});
				} else if (response.data.passwordStatus) {
					dispatch({
						type: SET_VENDOR_PASSWORD_STATUS,
						vendorPasswordStatus: response.data.passwordStatus,
						message: response.data.message,
					});
				} else {
					dispatch({ type: AUTH_ERROR, message: response.data.message });
				}
			})
			.catch((error) => {
				dispatch({ type: AUTH_ERROR, message: error.message });
			});
	};
};

export const logoutAction = () => {
	clearLogoutTimer();
	localStorage.removeItem(StorageKey.UserData);
	return { type: LOGOUT };
};

export const setUserNameAction = (userName) => {
	return async (dispatch) => {
		dispatch({ type: SET_USER_NAME, userName: userName });
	};
};

export const setChangePasswordStatusAction = (status) => {
	return async (dispatch) => {
		dispatch({
			type: SET_VENDOR_PASSWORD_STATUS,
			vendorPasswordStatus: status,
		});
	};
};

const clearLogoutTimer = () => {
	if (timer) {
		clearTimeout(timer);
	}
};

const setLogoutTimer = (expirationTime) => {
	return (dispatch) => {
		timer = setTimeout(() => {
			//   dispatch(logout());
		}, expirationTime);
	};
};

const saveDataToStorage = (token, role, expirationDate, user) => {
	localStorage.setItem(
		StorageKey.UserData,
		JSON.stringify({
			token: token,
			role: role,
			expiryDate: expirationDate,
		})
	);
	localStorage.setItem('User', JSON.stringify(user));
	localStorage.setItem(StorageKey.user_token, token);
};

export const updatePasswordAction = (props) => {
	const url = ApiUrl.authChangePassword;
	return async (dispatch) => {
	 const data = props;
	 const userData = await localStorage.getItem(StorageKey.UserData);
	 const userDataJson = JSON.parse(userData);
	 const config = {
	  headers: { Authorization: `Bearer ${userDataJson.token}` },
	 };
	 await axios
	  .put(url, data, config)
	  .then((response) => {
	   if (response.data.success) {
		dispatch({
		 type: PASSWORD_ACTION,
		 message: response.data.message,
		 status: true,
		});
	   } else {
		dispatch({
		 type: PASSWORD_ACTION,
		 message: response.data.message,
		 status: false,
		});
	   }
	  })
	  .catch((error) => {
	   dispatch({
		type: PASSWORD_ACTION,
		message: error.message,
		status: false,
	   });
	  });
	};
   };