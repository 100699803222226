/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  Redirect,
  HashRouter,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store/index";
import App from "./App";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    {/* <Router history={his}>
      <App />
    </Router> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  </Provider>,
  document.getElementById("root")
);
