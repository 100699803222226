import { Type } from "../../models/type-model";
import {
    CREATE_TYPE,
    UPDATE_TYPE,
    DELETE_TYPE,
    SET_TYPES,
    SET_TYPES_BY_DIVISION,
    SET_TYPE_ACTION_MESSAGE,
} from "../types/type-type";
const initialState = {
    types: [],
    typesDivision: [],
    actionMessage: "",
    message: "",
    status: false,
};

const typeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TYPES:
            return {
                ...state,
                types: action.types,
            };
        case SET_TYPES_BY_DIVISION:
            return {
                ...state,
                typesDivision: action.typesByDivision,
            };
        case CREATE_TYPE:
            const newType = new Type(
                action.typeData._id,
                action.typeData.typeName,
                action.typeData.division,
                action.typeData.active,
                action.typeData.createdAt
            );
            return {
                ...state,
                types: state.types.concat(newType),
            };
        case UPDATE_TYPE:
            const typeIndex = state.types.findIndex(
                (type) => type._id === action.typeData._id
            );
            const updatedType = new Type(
                action.typeData._id,
                action.typeData.typeName,
                action.typeData.division,
                action.typeData.active,
                action.typeData.createdAt
            );

            const updatedTypes = [...state.types];
            updatedTypes[typeIndex] = updatedType;
            return {
                ...state,
                types: updatedTypes,
                message: action.message,
                status: true,
            };
        case DELETE_TYPE:
            return {
                ...state,
                types: state.types.filter(
                    (type) => type._id !== action.typeData._id
                ),
            };
        case SET_TYPE_ACTION_MESSAGE:
            return {
                ...state,
                message: action.actionMessage,
                // message: action.message,
                status: false,
            };
    }
    return state;
};

export default typeReducer;
