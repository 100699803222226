import axios from "axios";
import { ApiUrl } from "../../constants/apiurls";
import { StorageKey } from "../../constants/text";
import {
    CREATE_SUBTYPE,
    UPDATE_SUBTYPE,
    DELETE_SUBTYPE,
    SET_SUBTYPES,
    SET_SUBTYPES_BY_TYPE,
    SET_SUBTYPES_ACTION_MESSAGE,
} from "../types/subtype-type";

export const fetchSubTypeAction = () => {
    const url = ApiUrl.getAllSubTypes;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);

        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .get(url, config)
            .then((response) => {
                dispatch({ type: SET_SUBTYPES, subtypes: response.data.data });
            })
            .catch((error) => {
                dispatch({
                    type: SET_SUBTYPES_ACTION_MESSAGE,
                    actionMessage: error.message,
                });
            });
    };
};
export const fetchSubTypeByTypeAction = (prop) => {
    const url = `${ApiUrl.getAllTypesByType}/${prop.typeId}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);

        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .get(url, config)
            .then((response) => {
                dispatch({
                    type: SET_SUBTYPES_BY_TYPE,
                    subtypes: response.data.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SET_SUBTYPES_ACTION_MESSAGE,
                    actionMessage: error.message,
                });
            });
    };
};

export const createSubTypeAction = (data) => {
    const url = ApiUrl.createSubType;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .post(url, data, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CREATE_SUBTYPE,
                        subtypeData: response.data.data,
                    });
                } else {
                    dispatch({
                        type: SET_SUBTYPES_ACTION_MESSAGE,
                        actionMessage: response.data.message,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_SUBTYPES_ACTION_MESSAGE,
                    actionMessage: error.message,
                });
            });
    };
};

export const updateSubTypeAction = (data) => {
    const url = `${ApiUrl.updateSubType}/${data._id}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);

        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };
        await axios
            .put(url, data, config)
            .then((response) => {
                dispatch({
                    type: UPDATE_SUBTYPE,
                    id: response.data.data._id,
                    subtypeData: response.data.data,
					message: response.data.message,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SET_SUBTYPES_ACTION_MESSAGE,
                    actionMessage: error.message,
                    message: error.response.data.message,
                });
            });
    };
};

export const deleteSubTypeAction = (data) => {
    const url = `${ApiUrl.deleteSubType}/${data._id}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);

        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };
        await axios
            .delete(url, config)
            .then((response) => {
                dispatch({ type: DELETE_SUBTYPE, id: data._id });
            })
            .catch((error) => {
                dispatch({
                    type: SET_SUBTYPES_ACTION_MESSAGE,
                    actionMessage: error.message,
                });
            });
    };
};
