/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// material ui icons
import DevicesOutlined from "@material-ui/icons/DevicesOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {
    createDivisionAction,
    updateDivisionAction,
} from "../../redux/actions/division-action";
import { windowAction } from "../../redux/actions/window-action";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

export default function Division(id) {
    const dispatch = useDispatch();
    const [uId, setUId] = useState("");
    const [division, setDivision] = useState("");
    const [divisionState, setDivisionState] = useState("");
    const [divisionName, setDivisionName] = useState("");
    const [divisionNameState, setDivisionNameState] = useState("");
    const [city, setCity] = useState("");
    const [cityState, setCityState] = useState("");
    const [unit, setUnit] = useState("");
    const [unitState, setUnitState] = useState("");
    const divisions = useSelector((state) => state.divisions.divisions);

    useEffect(() => {
        if (id.id) {
            divisions.map((division) => {
                if (division._id === id.id) {
                    setDivision(division.division);
                    setDivisionName(division.divisionName);
                    setCity(division.city);
                    setUnit(division.unit);
                    setUId(division._id);
                }
            });
        }
    }, []);

    const verifyDivision = (value) => {
        return value.length !== 0;
    };
    const verifyDivisionName = (value) => {
        return value.length !== 0;
    };
    const verifyCity = (value) => {
        return value.length !== 0;
    };
    const verifyUnit = (value) => {
        return value.length !== 0;
    };
    const handleSubmit = () => {
        if (division.length === 0) {
            setDivisionState("error");
            return;
        }
        if (divisionName.length === 0) {
            setDivisionNameState("error");
            return;
        }
        if (city.length === 0) {
            setCityState("error");
            return;
        }
        if (unit.length === 0) {
            setUnitState("error");
            return;
        }

        handleReset();
        const data = {
            division: division,
            divisionName: divisionName,
            city: city,
            unit: unit,
        };
        dispatch(createDivisionAction(data));
        // if (uId) {
        // 	const data = {
        // 		division: division,
        // 		divisionName: divisionName,
        // 		city: city,
        // 		unit: unit,
        // 		id: uId,
        // 	};
        // 	dispatch(updateDivisionAction(data));
        // } else {
        // 	const data = {
        // 		division: division,
        // 		divisionName: divisionName,
        // 		city: city,
        // 		unit: unit,
        // 	};
        // 	dispatch(createDivisionAction(data));
        // }
    };

    const handleReset = () => {
        setDivision("");
        setDivisionName("");
        setCity("");
        setUnit("");
        setDivisionState("");
        setDivisionNameState("");
        setCityState("");
        setUnitState("");
    };
    const handleCloseWindow = () => {
        dispatch(windowAction({ open: false }));
    };

    const classes = useStyles();
    return (
        <div>
            {/* <DialogTitle> */}
            {/* <Card>
					<CardHeader color="rose" icon>
						<CardIcon color="rose">
							<DevicesOutlined />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Add New Division</h4>
					</CardHeader>
				</Card> */}
            {/* </DialogTitle> */}

            <DialogContent>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={10} md={8}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <DevicesOutlined />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Add New Division
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <form>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={5}>
                                            <CustomInput
                                                success={
                                                    divisionState === "success"
                                                }
                                                error={
                                                    divisionState === "error"
                                                }
                                                labelText="Division *"
                                                id="division"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        if (
                                                            verifyDivision(
                                                                event.target
                                                                    .value
                                                            )
                                                        ) {
                                                            setDivisionState(
                                                                "success"
                                                            );
                                                        } else {
                                                            setDivisionState(
                                                                "error"
                                                            );
                                                        }
                                                        setDivision(
                                                            event.target.value
                                                        );
                                                    },
                                                    type: "text",
                                                    value: division,
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={5}>
                                            <CustomInput
                                                success={
                                                    divisionNameState ===
                                                    "success"
                                                }
                                                error={
                                                    divisionNameState ===
                                                    "error"
                                                }
                                                labelText="Division Name *"
                                                id="divisionName"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        if (
                                                            verifyDivisionName(
                                                                event.target
                                                                    .value
                                                            )
                                                        ) {
                                                            setDivisionNameState(
                                                                "success"
                                                            );
                                                        } else {
                                                            setDivisionNameState(
                                                                "error"
                                                            );
                                                        }
                                                        setDivisionName(
                                                            event.target.value
                                                        );
                                                    },
                                                    type: "text",
                                                    value: divisionName,
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={5}>
                                            <CustomInput
                                                success={
                                                    cityState === "success"
                                                }
                                                error={cityState === "error"}
                                                labelText="City *"
                                                id="city"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        if (
                                                            verifyCity(
                                                                event.target
                                                                    .value
                                                            )
                                                        ) {
                                                            setCityState(
                                                                "success"
                                                            );
                                                        } else {
                                                            setCityState(
                                                                "error"
                                                            );
                                                        }
                                                        setCity(
                                                            event.target.value
                                                        );
                                                    },
                                                    type: "text",
                                                    value: city,
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={5}>
                                            <CustomInput
                                                success={
                                                    unitState === "success"
                                                }
                                                error={unitState === "error"}
                                                labelText="Unit"
                                                id="unit"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        if (
                                                            verifyUnit(
                                                                event.target
                                                                    .value
                                                            )
                                                        ) {
                                                            setUnitState(
                                                                "success"
                                                            );
                                                        } else {
                                                            setUnitState(
                                                                "error"
                                                            );
                                                        }
                                                        setUnit(
                                                            event.target.value
                                                        );
                                                    },
                                                    type: "text",
                                                    value: unit,
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={5}>
                                            <Button
                                                color="rose"
                                                onClick={handleSubmit}
                                                className={classes.Button}
                                            >
                                                Add
                                            </Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={5}>
                                            <Button
                                                color="warning"
                                                onClick={handleCloseWindow}
                                                className={classes.Button}
                                            >
                                                Close
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </DialogContent>
        </div>
    );
}
