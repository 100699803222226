export class Device {
	constructor(_id, deviceId, osType, validity, userId, active, allowedAt) {
		this._id = _id;
		this.deviceId = deviceId;
		this.osType = osType;
		this.validity = validity;
		this.userId = userId;
		this.active = active;
		this.allowedAt = allowedAt;
	}
}
