import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';

import ChangeVendorPassword from './ChangeVendorPassword';

import { loginAction } from '../../redux/actions/auth-action.js';
import { setUserNameAction } from '../../redux/actions/auth-action.js';
import { windowAction } from '../../redux/actions/window-action';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';
import Login_Footer from 'components/Footer/Login_Footer';

const useStyles = makeStyles(styles);

export default function LoginPage() {
	const [cardAnimaton, setCardAnimation] = useState('cardHidden');
	const [userName, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const [userNameError, setUserNameError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [disabledLoginButton, setDisabledLoginButton] = useState(true);
	const [error, setError] = useState('');
	const errorMessage = useSelector((state) => state.auth.errorMessage);
	const open = useSelector((state) => state.window.open);
	const passwordChanged = useSelector(
		(state) => state.user.vendorPasswordChanged
	);
	const vendorPasswordStatus = useSelector(
		(state) => state.auth.vendorPasswordStatus
	);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		let id = setTimeout(function () {
			setCardAnimation('');
		}, 700);
		return function cleanup() {
			window.clearTimeout(id);
		};
	});

	useEffect(() => {
		setDisabledLoginButton(userName.length === 0 || password.length === 0);
	}, [userName, password]);

	useEffect(() => {
		if (vendorPasswordStatus) {
			dispatch(windowAction({ open: true }));
		}
	}, [vendorPasswordStatus]);

	const hamdleSubmit = async (event) => {
		event.preventDefault();
		if (userName.length === 0) {
			setUserNameError(true);
			setDisabledLoginButton(false);
			return false;
		}
		if (password.length === 0) {
			setPasswordError(true);
			setDisabledLoginButton(false);
			return false;
		}
		await dispatch(setUserNameAction(userName));
		await dispatch(loginAction(userName, password))
			.then((result) => {
				setUserName('');
				setPassword('');
				history.push('/admin/dashbord');
			})
			.catch((error) => {
				setError(error);
			});
	};

	const handleUserName = (event) => {
		event.preventDefault();
		setUserName(event.target.value);
		setUserNameError(userName.length === 0);
	};

	const handlePassword = (event) => {
		event.preventDefault();
		setPassword(event.target.value);
		setPasswordError(password.length === 0);
	};

	const handleCloseWindow = () => {
		dispatch(windowAction({ open: false }));
	};

	const classes = useStyles();
	return (
		<div className={classes.container}>
			<GridContainer justifyContent="center">
				<GridItem xs={12} sm={6} md={4}>
					<form onSubmit={hamdleSubmit}>
						<Card login className={classes[cardAnimaton]}>
							<CardHeader
								className={`${classes.cardHeader} ${classes.textCenter}`}
								color="rose"
							>
								<h4 className={classes.cardTitle}>Login</h4>
								<div className={classes.socialLine}></div>
								<Dialog open={open} onClose={handleCloseWindow}>
									<ChangeVendorPassword />
								</Dialog>
							</CardHeader>
							<CardBody>
								<CustomInput
									labelText="Username"
									formControlProps={{
										fullWidth: true,
									}}
									error={userNameError}
									inputProps={{
										value: userName,
										name: 'userName',
										onChange: handleUserName,
										endAdornment: (
											<InputAdornment position="end">
												<PersonOutlineOutlinedIcon
													className={classes.inputAdornmentIcon}
												/>
											</InputAdornment>
										),
									}}
								/>
								<CustomInput
									labelText="Password"
									id="password"
									formControlProps={{
										fullWidth: true,
									}}
									error={passwordError}
									inputProps={{
										value: password,
										name: 'password',
										onChange: handlePassword,
										endAdornment: (
											<InputAdornment position="end">
												<Icon className={classes.inputAdornmentIcon}>
													lock_outline
												</Icon>
											</InputAdornment>
										),
										type: 'password',
										autoComplete: 'off',
									}}
								/>
								{errorMessage || error}
							</CardBody>
							<CardFooter className={classes.justifyContentCenter}>
								<Button
									type="submit"
									disabled={disabledLoginButton}
									color="rose"
									simple
									size="lg"
									block
								>
									Login
								</Button>
							</CardFooter>
						</Card>
					</form>
				</GridItem>
			</GridContainer>
			{/* <Footer /> */}
			{/* <p className={footerClass.right}>
				<a href='javascript:' onClick={() => history.push('/privacy-policy')} style={{alignItems: 'self-end', textAlign: 'right', color: 'white'}} > Privacy policy</a>
				</p> */}
				<Login_Footer/>
		</div>
	);
}
