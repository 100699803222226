import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import moment from 'moment';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import CollectionsIcon from '@material-ui/icons/Collections';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import EqualizerIcon from '@material-ui/icons/Equalizer';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import ReactTable from 'components/ReactTable/ReactTable.js';
import Dialog from '@material-ui/core/Dialog';

// local components
import DetailsDialog from './DetailsDialog';
import ReportDialog from './ReportDialog';
import ThumbnailDialog from './ThumbnailDialog';
import ChangePassword from '../ChangePassword/ChangePassword';

import { fetchVendorAction } from '../../redux/actions/user-action';
import { setUserNameAction } from '../../redux/actions/auth-action.js';
import { windowAction } from '../../redux/actions/window-action';
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

export default function VendorList() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const open = useSelector((state) => state.window.open);
	const [showChangePasswordDailog, setShowChangePasswordDailog] = useState(
		null
	);
	const vendors = useSelector((state) => state.user.allVendors);

	useEffect(() => {
		dispatch(fetchVendorAction());
	}, []);

	const handleCloseWindow = () => {
		dispatch(windowAction({ open: false }));
	};

	const handleChangePasswordCallback = async (state) => {
		await dispatch(setUserNameAction(state._id));
		await dispatch(windowAction({ open: true }));
		if (!state) {
			setShowChangePasswordDailog(null);
		}
	};

	// Prepare table rows
	const data = vendors.map((prop, key) => {
		return {
			id: prop._id,
			name: prop.name,
			userName: prop.userName,
			email: prop.email,
			mobileNo: prop.mobileNo,
			role: prop.role,
			actions: (
				<div className="actions-right">
					<Button
						justIcon
						round
						simple
						onClick={() => {
							handleChangePasswordCallback(prop);
						}}
						color="info"
						className="like"
					>
						<EnhancedEncryptionIcon />
					</Button>
				</div>
			),
		};
	});

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color="primary" icon>
						<CardIcon color="primary">
							<Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}> Vendors</h4>
						<Dialog open={open} onClose={handleCloseWindow}>
							<ChangePassword />
						</Dialog>
					</CardHeader>
					<CardBody>
						<ReactTable
							columns={[
								{
									Header: 'Name',
									accessor: 'name',
								},
								{
									Header: 'Email',
									accessor: 'email',
								},
								{
									Header: 'Mobile No',
									accessor: 'mobileNo',
								},
								{
									Header: 'Action',
									accessor: 'actions',
								},
							]}
							data={data}
						/>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
