import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/icons/List";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

import {
    fetchUserAction,
    createUserAction,
    updateUserAction,
    deleteUserAction,
} from "../../redux/actions/user-action";
import { windowAction } from "../../redux/actions/window-action";
import AddUser from "./AddUser";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import myStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import UpdateUser from "./UpdateUser";
import { AddCircle } from "@material-ui/icons";
import PasswordUpdate from './PasswordUpdate';

const useStyles = makeStyles(styles);
const useMyStyles = makeStyles(myStyles);

export default function UserList() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [updateUser, setUpdateUser] = useState(null);
    const [passwordUpdate, setPasswordUpdate] = useState(null);
    const user = useSelector((state) => state.user.allUsers);
    const open = useSelector((state) => state.window.open);

    useEffect(() => {
        dispatch(fetchUserAction());
    }, []);

    const handleUpdatePasswordCallback = (state) => {
        if (!state) {
            setPasswordUpdate(null);
        }
    };

    const handleUpdateUserCallback = (state) => {
        if (!state) {
            setUpdateUser(null);
        }
    };

    const handleRegisterUser = () => {
        dispatch(windowAction({open: true}))
    };

    const handleCloseWindow = () => {
		dispatch(windowAction({ open: false }));
	};

    // Prepare table rows
    const data = user.map((prop, key) => {
        return {
            id: prop._id,
            name: prop.name,
            userName: prop.userName,
            mobileNo: prop.mobileNo,
            email: prop.email,
            actions: (
                <div className="actions-right">
                    <Button
						justIcon
						round
						simple
						onClick={() => {
							setPasswordUpdate(
								<PasswordUpdate
									show={true}
									parentCallback={handleUpdatePasswordCallback}
									id={prop._id}
								/>
							);
						}}
						color="info"
						className="like"
					>
						<EnhancedEncryptionIcon />
					</Button>
                    <Button
						justIcon
						round
						simple
						onClick={() => {
							setUpdateUser(
								<UpdateUser
									show={true}
									parentCallback={handleUpdateUserCallback}
									user={prop}
								/>
							);
						}}
						color="warning"
						className="edit"
					>
						<Edit />
					</Button>
                </div>
            ),
        };
    });

    return (
        <GridContainer>
            {passwordUpdate}
            {updateUser}
			{/* {deleteUser} */}
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Assignment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>User List</h4>
                        <h4 className={classes.cardButtonTitle} color="primary">
                            <Button
                                round
                                onClick={() => {
                                    handleRegisterUser();
                                }}
                                color="primary"
                            >
                                <AddCircle />
                                User
                            </Button>
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            columns={[
                                {
                                    Header: "Name",
                                    accessor: "name",
                                },
                                {
                                    Header: "User Name",
                                    accessor: "userName",
                                },
                                {
                                    Header: "Mobile No",
                                    accessor: "mobileNo",
                                },
                                {
                                    Header: "Email",
                                    accessor: "email",
                                },
                                {
                                    Header: "Actions",
                                    accessor: "actions",
                                },
                            ]}
                            data={data}
                        />
                    </CardBody>
                    <Dialog open={open} onClose={handleCloseWindow}>
                        <AddUser />
                    </Dialog>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

// export default function UserList() {
// 	const classes = useStyles();
// 	const myClasses = useMyStyles();
// 	const dispatch = useDispatch();
// 	const [alertMessage, setAlertMessage] = useState(null);
// 	const userData = useSelector((state) => state.user.allUsers);
// 	const open = useSelector((state) => state.window.open);
// 	useEffect(() => {
// 		dispatch(fetchUserAction());
// 	}, []);
// 	if (userData.length === 0) {
// 		return <div>Loding...</div>;
// 	}

// 	const handleOpenWindow = (event) => {
// 		dispatch(windowAction({ open: true }));
// 	};
// 	const handleCloseWindow = () => {
// 		dispatch(windowAction({ open: false }));
// 	};

// 	const handleEdit = (data) => {
// 		if (data) {
// 			alert(`ID : ${data}`);
// 		}
// 	};
// 	const handleRemove = (data) => {
// 		if (data) {
// 			dispatch(deleteUserAction(data));
// 			hideAlert();
// 		}
// 	};

// 	const warningWithConfirmAndCancelMessage = (data) => {
// 		setAlertMessage(
// 			<SweetAlert
// 				warning
// 				style={{ display: 'block', marginTop: '-100px' }}
// 				title="Are you sure?"
// 				onConfirm={() => successDelete(data)}
// 				onCancel={() => cancelDetele()}
// 				confirmBtnCssClass={myClasses.button + ' ' + myClasses.success}
// 				cancelBtnCssClass={myClasses.button + ' ' + myClasses.danger}
// 				confirmBtnText="Yes, delete it!"
// 				cancelBtnText="Cancel"
// 				showCancel
// 			>
// 				You want to delete the user.
// 			</SweetAlert>
// 		);
// 	};

// 	const successDelete = (data) => {
// 		setAlertMessage(
// 			<SweetAlert
// 				success
// 				style={{ display: 'block', marginTop: '-100px' }}
// 				title="Deleted!"
// 				onConfirm={() => handleRemove(data)}
// 				onCancel={() => hideAlert()}
// 				confirmBtnCssClass={myClasses.button + ' ' + myClasses.success}
// 			>
// 				User has been deleted.
// 			</SweetAlert>
// 		);
// 	};
// 	const cancelDetele = () => {
// 		setAlertMessage(
// 			<SweetAlert
// 				danger
// 				style={{ display: 'block', marginTop: '-100px' }}
// 				title="Cancelled"
// 				onConfirm={() => hideAlert()}
// 				onCancel={() => hideAlert()}
// 				confirmBtnCssClass={myClasses.button + ' ' + myClasses.success}
// 			>
// 				User data is safe.
// 			</SweetAlert>
// 		);
// 	};
// 	const hideAlert = () => {
// 		setAlertMessage(null);
// 	};

// 	const data = [];
// 	userData.map((user, index) => {
// 		let d = [];
// 		d.push(user.name);
// 		d.push(user.userName);
// 		d.push(user.email);
// 		d.push(user.role);
// 		const edit = (
// 			<Button
// 				round
// 				color="success"
// 				className={classes.actionButton + ' ' + classes.actionButtonRound}
// 				key={index}
// 				value={user._id}
// 				onClick={(e) => {
// 					handleEdit(e.target.value);
// 				}}
// 			>
// 				<Edit className={classes.icon} />
// 			</Button>
// 		);
// 		const remove = (
// 			<Button
// 				round
// 				color="danger"
// 				className={classes.actionButton + ' ' + classes.actionButtonRound}
// 				key={user._id}
// 				value={user._id}
// 				onClick={(e) => {
// 					warningWithConfirmAndCancelMessage(e.target.value);
// 				}}
// 			>
// 				<Close className={classes.icon} />
// 			</Button>
// 		);
// 		const btn = [];
// 		btn.push(edit);
// 		btn.push(remove);
// 		d.push(btn);
// 		data.push(d);
// 	});
// 	const thName = ['Name', 'User Name', 'Email', 'Role', 'Action'];

// 	return (
// 		<div>
// 			{alertMessage}
// 			<GridContainer>
// 				<GridItem xs={12}>
// 					<Card>
// 						<CardHeader>
// 							<CardIcon color="success">
// 								<List />
// 							</CardIcon>
// 							<h4 className={classes.cardIconTitle}>Users List</h4>
// 							<Button
// 								round
// 								color="danger"
// 								className={
// 									classes.actionButton + ' ' + classes.actionButtonRound
// 								}
// 								onClick={(e) => {
// 									handleOpenWindow(e);
// 								}}
// 							>
// 								<PersonAdd className={classes.icon} />
// 							</Button>
// 							<Dialog open={open} onClose={handleCloseWindow}>
// 								<AddUser />
// 							</Dialog>
// 						</CardHeader>
// 						<CardBody>
// 							<GridContainer justify="space-between">
// 								<GridItem xs={12} sm={12} md={12}>
// 									<Table tableHead={thName} tableData={data} />
// 								</GridItem>
// 							</GridContainer>
// 						</CardBody>
// 					</Card>
// 				</GridItem>
// 			</GridContainer>
// 		</div>
// 	);
// }
