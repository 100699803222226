import React from 'react';
import moment from 'moment';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// @material-ui/icons
import Close from '@material-ui/icons/Close';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function DetailsDialog(props) {
	const classes = useStyles();

	const handleDialogClose = (state) => {
		props.parentCallback(state);
	};
	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<Dialog
					classes={{
						root: classes.center + ' ' + classes.modalRoot,
						paper: classes.modal,
					}}
					open={props.show}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => handleDialogClose(false)}
					aria-labelledby="notice-modal-slide-title"
					aria-describedby="notice-modal-slide-description"
				>
					<DialogTitle
						id="notice-modal-slide-title"
						disableTypography
						className={classes.modalHeader}
					>
						<Button
							justIcon
							className={classes.modalCloseButton}
							key="close"
							aria-label="Close"
							color="transparent"
							onClick={() => handleDialogClose(false)}
						>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Transaction Details</h4>
					</DialogTitle>
					<DialogContent
						id="notice-modal-slide-description"
						className={classes.modalBody}
					>
						<SnackbarContent
							message="Party Name"
							action={props.transaction.partyName}
						/>
						<SnackbarContent
							message="Party Code"
							action={props.transaction.partyCode}
						/>
						<SnackbarContent message="Division" action={props.division} />
						<SnackbarContent message="Type" action={props.type} />
						<SnackbarContent message="Sub Type" action={props.subType} />
						<SnackbarContent
							message="Gate Pass #"
							action={props.transaction.gatePassNumber}
						/>
						<SnackbarContent
							message="Gate Pass Date"
							action={moment(props.transaction.gatePassDate).format(
								'DD-MM-YYYY'
							)}
						/>
						<SnackbarContent
							message="Entry Date"
							action={moment(props.transaction.entryDate).format('DD-MM-YYYY')}
						/>
						<SnackbarContent
							message="Entry/Exit"
							action={props.transaction.gateInOut[0]}
						/>

						<SnackbarContent
							message="Remark"
							action={props.transaction.remark}
						/>
					</DialogContent>
					<DialogActions
						className={classes.modalFooter + ' ' + classes.modalFooterCenter}
					>
						<Button onClick={() => handleDialogClose(false)} color="info">
							CLOSE
						</Button>
					</DialogActions>
				</Dialog>
			</GridItem>
		</GridContainer>
	);
}
