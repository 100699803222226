export class User {
	constructor(
		_id,
		name,
		userName,
		email,
		mobileNo,
		role,
		active,
		createdAt
	) {
		this._id = _id;
		this.name = name;
		this.userName = userName;
		this.email = email;
		this.mobileNo = mobileNo;
		this.role = role;
		this.active = active;
		this.createdAt = createdAt;
	}
}
