import {
	SET_TOTAL_GROSS_WEIGHT,
	SET_TOTAL_TARE_WEIGHT,
	SET_ACTION_MESSAGE,
	CLEAR_ACTION_MESSAGE,
} from '../types/sync-type';

const initialState = {
	totalGrossWeight: 0,
	totalTareWeight: 0,
	message: '',
};

const syncReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TOTAL_GROSS_WEIGHT:
			return {
				...state,
				totalGrossWeight: action.totalGrossWeight,
			};
		case SET_TOTAL_TARE_WEIGHT:
			return {
				...state,
				totalTareWeight: action.totalTareWeight,
			};
		case SET_ACTION_MESSAGE:
			return {
				...state,
				message: action.message,
			};
	}
	return state;
};

export default syncReducer;
