import { User } from "../../models/user-model";
import {
    ADD_USER,
    UPDATE_USER,
    DELETE_USER,
    SET_USER,
    SET_ALL_USER,
    SET_ALL_VENDOR,
    UPDATE_PASSWORD,
    SET_USER_ERROR_MESSAGE,
    CHANGE_VENDOR_PASSWORD,
    SET_ACTION_USER_MESSAGE,
    CLEAR_ACTION_USER_MESSAGE,
} from "../types/user";

const initialState = {
    allUsers: [],
    allVendors: [],
    message: "",
    status: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_USER:
            return {
                ...state,
                allUsers: action.users,
            };
        case SET_ALL_VENDOR:
            return {
                ...state,
                allVendors: action.vendors,
            };
        case ADD_USER:
            const newUser = new User(
                action.userData._id,
                action.userData.name,
                action.userData.userName,
                action.userData.mobileNo,
                action.userData.email,
                action.userData.role,
                action.userData.active,
                action.userData.createdAt
            );
            return {
                ...state,
                allUsers: state.allUsers.concat(newUser),
            };
        case UPDATE_PASSWORD:
            return {
                ...state,
                message: action.message,
                status: action.status,
            }
        case UPDATE_USER:
            const userIndex = state.allUsers.findIndex(
                (user) => user._id === action.userData._id
            );
            const updatedUser = new User(
                action.userData._id,
                action.userData.name,
                action.userData.userName,
                action.userData.email,
                action.userData.mobileNo,
                action.userData.role,
                action.userData.active
            );

            const allUsers = [...state.allUsers];
            allUsers[userIndex] = updatedUser;
            return {
                ...state,
                allUsers: allUsers,
                message: action.message,
                status: true,
            };
        case CHANGE_VENDOR_PASSWORD:
            return {
                ...state,
                message: action.message,
                status: true,
            };
        case DELETE_USER:
            return {
                ...state,
                allUsers: state.allUsers.filter(
                    (user) => user.id !== action.userId
                ),
            };
        case SET_ACTION_USER_MESSAGE:
            return {
                ...state,
                message: action.message,
                status: action.status,
            };
        case SET_USER_ERROR_MESSAGE:
            return {
                ...state,
                message: action.message,
                status: action.status,
            };
    }
    return state;
};

export default userReducer;
