import axios from "axios";
import { ApiUrl } from "../../constants/apiurls";
import { StorageKey } from "../../constants/text";
import {
  SET_TRANSACTION_STATUS_REPORT,
  SET_TRANSACTION_REPORT,
  SET_TRANSACTION_STATUS,
  SET_TRANSACTION_SEARCHBY,
  SET_ACTION_MESSAGE,
} from "../types/report-type";

export const fetchStatusReportAction = (searchtext, division) => {
  const url = `${ApiUrl.getTransactionReport}/${searchtext}/${division}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    // any async code you want!
    try {
      const config = {
        headers: { Authorization: `Bearer ${userDataJson.token}` },
      };

      await axios
        .get(url, config)
        .then((response) => {
          const data = {
            ewaybill: response.data.ewaybill,
            gatedetails: response.data.gatedetails,
            debitnote: response.data.debitnote,
            pcr: response.data.pcr,
            grosstare: response.data.grosstare,
            purchaseorder: response.data.purchaseorder,
            scrapquality: response.data.scrapquality,
          }
          dispatch({
            type: SET_TRANSACTION_STATUS_REPORT,
            filteredRecord: data,
            status: response.data.success,
          });
        })
        .catch((err) => {
          dispatch({
            type: SET_ACTION_MESSAGE,
            message: err.response.data.message,
            status: err.response.data.success,
          });
        });
    } catch (error) {
      dispatch({
        type: SET_ACTION_MESSAGE,
        message: error.message,
        status: false,
      });
    }
  };
};

export const fetchTransactionStatusReportAction = (division, searchtext) => {
  const url = `${ApiUrl.getRecordsToBeProcesed}/${division}/${searchtext}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);
    try {
      const config = {
        headers: { Authorization: `Bearer ${userDataJson.token}` },
      };

      await axios
        .get(url, config)
        .then((response) => {
          dispatch({
            type: SET_TRANSACTION_STATUS_REPORT,
            filteredRecord: response.data.data,
          });
        })
        .catch((err) => {
          throw new Error(`Can not fetch reports from api - ${err.message}`);
        });
    } catch (err) {
      throw err;
    }
  };
};

export const fetchTransactionBetweenDate = (from, to) => {
  const url = `${ApiUrl.getRecordsBetweenTimeRange}/${from}/${to}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: { Authorization: `Bearer ${userDataJson.token}` },
    };

    await axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: SET_TRANSACTION_REPORT,
          transactionReport: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ACTION_MESSAGE,
          message: error.response.data.message,
        });
      });
  };
};

export const fetchTransactionBetweenDateCode = (from, to, partyCode) => {
  const url = `${ApiUrl.getTransactionByDateCode}/${from}/${to}/${partyCode}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: { Authorization: `Bearer ${userDataJson.token}` },
    };

    await axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: SET_TRANSACTION_REPORT,
          transactionReport: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ACTION_MESSAGE,
          message: error.response.data.message,
        });
      });
  };
};

export const fetchTransactionSearchBy = (searchText) => {
  const url = `${ApiUrl.getRecordFromSearchBy}/${searchText}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    const config = {
      headers: { Authorization: `Bearer ${userDataJson.token}` },
    };

    await axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: SET_TRANSACTION_SEARCHBY,
          transactionsSearchBy: response.data.data,
          message: response.data.message,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ACTION_MESSAGE,
          message: error.response.data.message,
        });
      });
  };
};

export const fetchTransactionReportAction = (searchtext, division) => {
  const url = `${ApiUrl.getTransactionReportStatus}/${searchtext}/${division}`;
  return async (dispatch) => {
    const userData = await localStorage.getItem(StorageKey.UserData);
    const userDataJson = JSON.parse(userData);

    // any async code you want!
    try {
      const config = {
        headers: { Authorization: `Bearer ${userDataJson.token}` },
      };

      await axios
        .get(url, config)
        .then((response) => {
          dispatch({
            type: SET_TRANSACTION_STATUS,
            ewaybill: response.data.ewaybill,
            gatedetails: response.data.gatedetails,
            debitnote: response.data.debitnote,
            pcr: response.data.pcr,
            grosstare: response.data.grosstare,
            purchaseorder: response.data.purchaseorder,
            scrapquality: response.data.scrapquality,
            message: response.data.message,
          });
        })
        .catch((error) => {
          dispatch({
            type: SET_ACTION_MESSAGE,
            message: error.response.data.message,
          });
        });
    } catch (error) {
      // send to custom analytics server
      dispatch({
        type: SET_ACTION_MESSAGE,
        message: error.message,
      });
    }
  };
};
