import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import { fetchDivisionAction } from "../../redux/actions/division-action";
import { updateTypeAction } from "../../redux/actions/type-action";
import { windowAction } from "../../redux/actions/window-action";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: "rgba(255, 255, 255, 0.54)",
    },
}));

const Type = React.forwardRef(function Type(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function UpdateType(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [division, setDivision] = useState(props.type.division);
    const [divisionState, setDivisionState] = useState("");
    const [typeName, setTypeName] = useState(props.type.typeName);
    const [typeNameState, setTypeNameState] = useState("");

    const divisionData = useSelector((state) => state.divisions.divisions);
    const message = useSelector((state) => state.types.message);
    const status = useSelector((state) => state.types.status);

    useEffect(() => {
        dispatch(fetchDivisionAction());
    }, []);

    const divisionList = divisionData.map((data) => (
        <MenuItem value={data._id} key={data._id}>
            {data.division + "(" + data.unit + ")"}
        </MenuItem>
    ));

    if (status) {
        setTimeout(() => {
            handleDialogClose(false);
            dispatch({
                type: "SET_TYPE_ACTION_MESSAGE",
                message: "",
                status: false,
            });
        }, 3000);
    }

    const verifyDivision = (value) => {
        return value.length !== 0;
    };
    const verifyTypeName = (value) => {
        return value.length !== 0;
    };
    const handleSubmit = () => {
        if (divisionState === "") {
            setDivisionState("error");
            return;
        }
        if (typeNameState === "") {
            setTypeNameState("error");
            return;
        }
        const data = {
            _id: props.type._id,
            division: division,
            typeName: typeName,
        };
        handleReset();
        dispatch(updateTypeAction(data));
    };

    const handleCloseWindow = () => {
        dispatch(windowAction({ open: false }));
    };

    const handleReset = (event) => {
        setDivision("");
        setTypeName("");
        setDivisionState("");
        setTypeNameState("");
    };
    const handleDialogClose = (state) => {
        props.parentCallback(state);
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Dialog
                    classes={{
                        root: classes.center + " " + classes.modalRoot,
                        paper: classes.modal,
                    }}
                    open={props.show}
                    TypeComponent={Type}
                    keepMounted
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="notice-modal-slide-title"
                    aria-describedby="notice-modal-slide-description"
                >
                    <DialogTitle
                        id="notice-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle}>Update Type</h4>
                    </DialogTitle>
                    <DialogContent
                        id="notice-modal-slide-description"
                        className={classes.modalBody}
                    >
                        {/* Custom input */}

                        <form>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={10}>
                                    <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                    >
                                        <InputLabel
                                            htmlFor="simple-select"
                                            className={classes.selectLabel}
                                        >
                                            Select Division
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            onChange={(event) => {
                                                if (
                                                    verifyDivision(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setDivisionState("success");
                                                } else {
                                                    setDivisionState("error");
                                                }
                                                setDivision(event.target.value);
                                            }}
                                            inputProps={{
                                                name: "division",
                                                id: "division",
                                                value: division,
                                            }}
                                        >
                                            <MenuItem
                                                disabled
                                                classes={{
                                                    root:
                                                        classes.selectMenuItem,
                                                }}
                                                value="division"
                                            >
                                                Division
                                            </MenuItem>
                                            {divisionList}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={10}>
                                    <CustomInput
                                        success={typeNameState === "success"}
                                        error={typeNameState === "error"}
                                        labelText="Type Name *"
                                        id="typeName"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (
                                                    verifyTypeName(
                                                        event.target.value
                                                    )
                                                ) {
                                                    setTypeNameState("success");
                                                } else {
                                                    setTypeNameState("error");
                                                }
                                                setTypeName(event.target.value);
                                            },
                                            type: "text",
                                            value: typeName,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={10}>
                                    {message}
                                </GridItem>
                            </GridContainer>
                        </form>
                    </DialogContent>
                    <DialogActions
                        className={
                            classes.modalFooter +
                            " " +
                            classes.modalFooterCenter
                        }
                    >
                        <Button onClick={handleSubmit} color="success">
                            Update
                        </Button>
                        <Button
                            onClick={() => handleDialogClose(false)}
                            color="warning"
                        >
                            CLOSE
                        </Button>
                    </DialogActions>
                </Dialog>
            </GridItem>
        </GridContainer>
    );
}
