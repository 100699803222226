import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import PersonAddOutlined from "@material-ui/icons/PersonAddOutlined";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import { changePasswordAction } from "../../redux/actions/user-action";
import { setChangePasswordStatusAction } from "../../redux/actions/auth-action";
import { windowAction } from "../../redux/actions/window-action";

import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

export default function ChangePassword(prop) {
    const dispatch = useDispatch();
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordState, setNewPasswordState] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordState, setConfirmPasswordState] = useState("");
    const id = useSelector((state) => state.auth.userName);
    const message = useSelector((state) => state.user.message);
    const status = useSelector((state) => state.user.status);

    const verifyLength = (value, length) => {
        return value.length >= length;
    };

    if (status) {
        setTimeout(() => {
            dispatch(windowAction({ open: false }));
            dispatch({
                type: "SET_USER_ERROR_MESSAGE",
                message: "",
                status: false,
            });
        }, 2000);
    }

    const handleSubmit = () => {
        if (newPasswordState === "") {
            setNewPasswordState("error");
            return;
        }
        if (confirmPasswordState === "") {
            setConfirmPasswordState("error");
        }

        const data = {
            newPassword,
            confirmPassword,
        };
        handleReset();
        dispatch(changePasswordAction(id, data));
    };

    const handleCloseWindow = () => {
        dispatch(windowAction({ open: false }));
    };

    const handleReset = (event) => {
        setNewPassword("");
        setConfirmPassword("");
    };

    const classes = useStyles();

    return (
        <DialogContent>
            <GridContainer justify="center">
                <GridItem xs={12} sm={10} md={8}>
                    <Card>
                        <CardHeader color="rose" icon>
                            {/* <CardIcon color="rose">
								<PersonAddOutlined />
							</CardIcon> */}
                            <h4 className={classes.cardIconTitle}>
                                Change Vendor Password
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={10}>
                                        <CustomInput
                                            success={
                                                newPasswordState === "success"
                                            }
                                            error={newPasswordState === "error"}
                                            labelText="New Password *"
                                            id="newpassword"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    if (
                                                        verifyLength(
                                                            event.target.value,
                                                            1
                                                        )
                                                    ) {
                                                        setNewPasswordState(
                                                            "success"
                                                        );
                                                    } else {
                                                        setNewPasswordState(
                                                            "error"
                                                        );
                                                    }
                                                    setNewPassword(
                                                        event.target.value
                                                    );
                                                },
                                                type: "password",
                                                value: newPassword,
                                                autoComplete: "off",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={10}>
                                        <CustomInput
                                            success={
                                                confirmPasswordState ===
                                                "success"
                                            }
                                            error={
                                                confirmPasswordState === "error"
                                            }
                                            labelText="Confirm Password *"
                                            id="confirmpassword"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    if (
                                                        newPassword ===
                                                        event.target.value
                                                    ) {
                                                        setConfirmPasswordState(
                                                            "success"
                                                        );
                                                    } else {
                                                        setConfirmPasswordState(
                                                            "error"
                                                        );
                                                    }
                                                    setConfirmPassword(
                                                        event.target.value
                                                    );
                                                },
                                                type: "password",
                                                value: confirmPassword,
                                                autoComplete: "off",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={10}>
                                        {message}
                                    </GridItem>
                                    <GridItem xs={12} sm={5}>
                                        <Button
                                            color="rose"
                                            onClick={handleSubmit}
                                            className={classes.Button}
                                        >
                                            Submit
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={5}>
                                        <Button
                                            color="warning"
                                            onClick={handleCloseWindow}
                                            className={classes.Button}
                                        >
                                            Close
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </DialogContent>
    );
}
