// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// // @material-ui/core components
// import { makeStyles } from '@material-ui/core/styles';

// // @material-ui/icons
// import Store from '@material-ui/icons/Store';
// import TagFaces from '@material-ui/icons/TagFaces';
// import DateRange from '@material-ui/icons/DateRange';
// import LocalOffer from '@material-ui/icons/LocalOffer';
// import Update from '@material-ui/icons/Update';
// import Language from '@material-ui/icons/Language';

// // core components
// import GridContainer from 'components/Grid/GridContainer.js';
// import GridItem from 'components/Grid/GridItem.js';
// import Table from 'components/Table/Table.js';
// import Danger from 'components/Typography/Danger.js';
// import Card from 'components/Card/Card.js';
// import CardHeader from 'components/Card/CardHeader.js';
// import CardIcon from 'components/Card/CardIcon.js';
// import CardBody from 'components/Card/CardBody.js';
// import CardFooter from 'components/Card/CardFooter.js';

// import {
// 	fetchTranactionAction,
// 	fetchTranactionToBeProccessed,
// 	fetchTranactionCount,
// } from '../../redux/actions/transaction-action';
// import {
// 	fetchGrossWeightAction,
// 	fetchTareWeightAction,
// } from '../../redux/actions/sync-action';

// import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';

// const useStyles = makeStyles(styles);
// export default function AdminDashboard() {
//     const classes = useStyles();
// 	const dispatch = useDispatch();
// 	const txnData = useSelector((state) => state.transactions.transactions);
// 	const totalTransactionCount = useSelector(
// 		(state) => state.transactions.allTransaction
// 	);
// 	const lastDayTransactionCount = useSelector(
// 		(state) => state.transactions.lastTransaction
// 	);
// 	const unprossedTransactions = useSelector(
// 		(state) => state.transactions.toBeProccessed
// 	);
// 	const totalGrossWeight = useSelector((state) => state.sync.totalGrossWeight);
// 	const totalTareWeight = useSelector((state) => state.sync.totalTareWeight);

// 	useEffect(() => {
// 		dispatch(fetchTranactionAction());
// 		dispatch(fetchTranactionToBeProccessed());
// 		dispatch(fetchTranactionCount());
// 		dispatch(fetchGrossWeightAction());
// 		dispatch(fetchTareWeightAction());
// 	}, []);
// 	const data = [];
// 	txnData.map((txn) => {
// 		let d = [];
// 		d.push(txn.vehicleNo);
// 		d.push(txn.partyName);
// 		d.push(txn.gatePassNumber);
// 		data.push(d);
// 	});
// 	const thName = ['Vehicle No', 'Party Name', 'Gate Pass Number'];

// 	return (
// 		<div>
// 			<GridContainer>
// 				<GridItem xs={12} sm={6} md={6} lg={3}>
// 					<Card>
// 						<CardHeader color="warning" stats icon>
// 							<CardIcon color="warning">
// 								<TagFaces />
// 							</CardIcon>
// 							<p className={classes.cardCategory}>Transaction{"'"}s</p>
// 							<h3 className={classes.cardTitle}>
// 								{lastDayTransactionCount}/{totalTransactionCount}
// 								<small> TXN</small>
// 							</h3>
// 						</CardHeader>
// 						<CardFooter stats>
// 							<div className={classes.stats}>
// 								<Danger>
// 									<DateRange />
// 								</Danger>
// 								<a href="#pablo" onClick={(e) => e.preventDefault()}>
// 									Last 24 Hours
// 								</a>
// 							</div>
// 						</CardFooter>
// 					</Card>
// 				</GridItem>
// 				<GridItem xs={12} sm={6} md={6} lg={3}>
// 					<Card>
// 						<CardHeader color="success" stats icon>
// 							<CardIcon color="success">
// 								<Store />
// 							</CardIcon>
// 							<p className={classes.cardCategory}>Un-TXN</p>
// 							<h3 className={classes.cardTitle}>
// 								{unprossedTransactions ? unprossedTransactions.length : 0}
// 							</h3>
// 						</CardHeader>
// 						<CardFooter stats>
// 							<div className={classes.stats}>
// 								<DateRange />
// 								Last 24 Hours
// 							</div>
// 						</CardFooter>
// 					</Card>
// 				</GridItem>
// 				<GridItem xs={12} sm={6} md={6} lg={3}>
// 					<Card>
// 						<CardHeader color="danger" stats icon>
// 							<CardIcon color="danger">
// 								<Store />
// 							</CardIcon>
// 							<p className={classes.cardCategory}>Tare Weight</p>
// 							<h3 className={classes.cardTitle}>
// 								<small>{totalTareWeight} KG</small>
// 							</h3>
// 						</CardHeader>
// 						<CardFooter stats>
// 							<div className={classes.stats}>
// 								<LocalOffer />
// 								Total Tare of Current Session
// 							</div>
// 						</CardFooter>
// 					</Card>
// 				</GridItem>
// 				<GridItem xs={12} sm={6} md={6} lg={3}>
// 					<Card>
// 						<CardHeader color="info" stats icon>
// 							<CardIcon color="info">
// 								<Store />
// 							</CardIcon>
// 							<p className={classes.cardCategory}>Gross Weight</p>
// 							<h3 className={classes.cardTitle}>
// 								<small>{totalGrossWeight} KG</small>
// 							</h3>
// 						</CardHeader>
// 						<CardFooter stats>
// 							<div className={classes.stats}>
// 								<Update />
// 								Total Gross of Current Session
// 							</div>
// 						</CardFooter>
// 					</Card>
// 				</GridItem>
// 			</GridContainer>
// 			<GridContainer>
// 				<GridItem xs={12}>
// 					<Card>
// 						<CardHeader color="success" icon>
// 							<CardIcon color="success">
// 								<Language />
// 							</CardIcon>
// 							<h4 className={classes.cardIconTitle}>
// 								Last 5 Transaction{"'"}s
// 							</h4>
// 						</CardHeader>
// 						<CardBody>
// 							<GridContainer justify="space-between">
// 								<GridItem xs={12} sm={12} md={12}>
// 									<Table tableHead={thName} tableData={data} />
// 								</GridItem>
// 							</GridContainer>
// 						</CardBody>
// 					</Card>
// 				</GridItem>
// 			</GridContainer>
// 		</div>
// 	);
// }

import React from 'react';
import Transactions from '../Transaction/TransactionList';

export default function AdminDashboard() {
	return <Transactions />;
}
