import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFilePicker } from "use-file-picker";
// @material-ui/core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// material ui icons
import NoteAdd from "@material-ui/icons/NoteAdd";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import CameraFrontIcon from "@material-ui/icons/CameraFront";
import { CardMembership, CreditCard, Receipt } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { fetchDivisionAction } from "../../redux/actions/division-action";
import {
  fetchTypeAction,
  fetchTypeByDivisionAction,
} from "../../redux/actions/type-action";
import { fetchTransactionStatusReportAction } from "../../redux/actions/report-action";
import { fetchSubTypeByTypeAction } from "../../redux/actions/subtype-action";
import { windowAction } from "../../redux/actions/window-action";
import { createTransactionAction } from "../../redux/actions/transaction-action";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { random } from "underscore";

const useStyles = makeStyles(styles);

export default function TransactionCreate() {
  const dispatch = useDispatch();
  const [division, setDivision] = useState("");
  const [divisionState, setDivisionState] = useState("");
  const [type, setType] = useState("");
  const [typeState, setTypeState] = useState("");
  const [subType, setSubType] = useState("");
  const [subTypeState, setSubTypeState] = useState("");
  const [date, setDate] = useState("");
  const [dateState, setDateState] = useState("");
  const [gatePassNumber, setGatePassNumber] = useState("");
  const [gatePassNumberState, setGatePassNumberState] = useState("");
  const [partyName, setPartyName] = useState("");
  const [partyCode, setPartyCode] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [orderNumberState, setOrderNumberState] = useState("");
  const [partyNameState, setPartyNameState] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleNumberState, setVehicleNumberState] = useState("");
  const [remark, setRemark] = useState("");
  const [remarkState, setRemarkState] = useState("");

  const divisionData = useSelector((state) => state.divisions.divisions);
  const typeData = useSelector((state) => state.types.typesDivision);
  const subTypeData = useSelector((state) => state.subtypes.subTypesByType);
  const transactionStatus = useSelector(
    (state) => state.reports.filteredRecord
  );
  const message = useSelector((state) => state.transactions.message);
  const status = useSelector((state) => state.transactions.status);

  useEffect(() => {
    dispatch(fetchDivisionAction());
  }, []);
  useEffect(() => {
    if (division) {
      dispatch(fetchTypeByDivisionAction({ division: division }));
    }
  }, [division]);
  useEffect(() => {
    if (type) {
      dispatch(fetchSubTypeByTypeAction({ typeId: type }));
    }
  }, [type]);
  useEffect(() => {
    if (gatePassNumber) {
      const party = transactionStatus.find(
        (data) => gatePassNumber === data.gate_pass_no
      );
      setPartyName(party.party_name);
      setPartyCode(party.party_code);
      setVehicleNumber(party.truck_no);
      setOrderNumber(party.purchase_order_no);
    }
  }, [gatePassNumber]);
  useEffect(() => {
    if (date) {
      let divisionName = "";
      divisionData.map((data) => {
        if (data._id === division) {
          divisionName = data.unit;
        }
      });
      dispatch(fetchTransactionStatusReportAction(divisionName, date));
    }
  }, [date]);

  if(status) {
    setTimeout(() => {
      dispatch({
        type: 'SET_ACTION_MESSAGE',
        message: '',
        status: false,
      })
      dispatch(windowAction({ open: false }))
    },3000)
  }

  const divisionList = divisionData.map((data) => (
    <MenuItem value={data._id} key={data._id}>
      {data.division + "(" + data.unit + ")"}
    </MenuItem>
  ));
  const typeList = typeData.map((data) => (
    <MenuItem value={data._id} key={data._id}>
      {data.typeName}
    </MenuItem>
  ));
  const subTypeList = subTypeData.map((data) => (
    <MenuItem value={data._id} key={data._id}>
      {data.subTypeName}
    </MenuItem>
  ));
  const gatePassList = transactionStatus.map((data) => (
    <MenuItem value={data.gate_pass_no} key={data._id}>
      {data.gate_pass_no}({data.truck_no} -{data.party_name})`
    </MenuItem>
  ));

  const verifyDivision = (value) => {
    return value.length !== 0;
  };
  const verifyType = (value) => {
    return value.length !== 0;
  };
  const verifySubType = (value) => {
    return value.length !== 0;
  };
  const verifyDate = (value) => {
    return value.length !== 0;
  };
  const verifyGatePassNumber = (value) => {
    return value.length !== 0;
  };
  const verifyParyName = (value) => {
    return value.length !== 0;
  };
  const verifyOrderNumber = (value) => {
    return value.length !== 0;
  };
  const verifyVehicleNumber = (value) => {
    return value.length !== 0;
  };

  const handleSubmit = () => {
    if (divisionState === "") {
      setDivisionState("error");
      return;
    }
    if (typeState === "") {
      setTypeState("error");
      return;
    }
    if (subTypeState === "") {
      setSubTypeState("error");

      return;
    }
    if (dateState === "") {
      setDateState("error");
      return;
    }
    if (gatePassNumberState === "") {
      setGatePassNumberState("error");
      return;
    }
    const images = [];
    imageData.map((image, index) => {
      images.push({ base64: image.base64, name: image.name });
    });
    const licence = [];
    licenceData.map((image, index) => {
      licence.push({ base64: image.base64, name: image.name });
    });
    const photo = [];
    photoData.map((image, index) => {
      photo.push({ base64: image.base64, name: image.name });
    });
    const rc = [];
    RCData.map((image, index) => {
      rc.push({ base64: image.base64, name: image.name });
    });
    const challan = [];
    challanData.map((image, index) => {
      challan.push({ base64: image.base64, name: image.name });
    });
    const data = {
      division,
      type,
      subType,
      gatePassDate: date,
      gatePassNumber,
      partyName,
      partyCode,
      orderNumber,
      vehicleNo: vehicleNumber,
      gateInOut: "Entry",
      remark,
      images,
      licence,
      photo,
      rc,
      challan,
    };
    dispatch(createTransactionAction(data));
    handleReset();
  };

  const handleCloseWindow = () => {
    dispatch(windowAction({ open: false }));
  };

  const handleReset = (event) => {
    setDivision("");
    setType("");
    setSubType("");
    setDate("");
    setGatePassNumber("");
    setPartyName("");
    setVehicleNumber("");
    setOrderNumber("");
    setRemark("");
  };

  const [openImageSelector, imagePickerData] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: true,
    limitFilesConfig: { max: 15 },
  });
  const [openLicenceSelector, lacencePickerData] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });
  const [openPhotoSelector, photoPickerData] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });
  const [openRCSelector, RCPickerData] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });
  const [openChallanSelector, challanPickerData] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });

  const imageData = [];
  {
    imagePickerData.filesContent.map((file, index) => {
      let data = file.content.split(";");
      let base64 = data[1].replace(",", ":").split(":");
      let objbase64 = {
        base64: base64[1],
        name: file.name,
        file: file.content,
      };
      imageData.push(objbase64);
    });
  }
  const licenceData = [];
  {
    lacencePickerData.filesContent.map((file, index) => {
      let data = file.content.split(";");
      let base64 = data[1].replace(",", ":").split(":");
      let objbase64 = {
        base64: base64[1],
        name: file.name,
        file: file.content,
        key: random(10, 100),
      };
      licenceData.push(objbase64);
    });
  }
  const photoData = [];
  {
    photoPickerData.filesContent.map((file, index) => {
      let data = file.content.split(";");
      let base64 = data[1].replace(",", ":").split(":");
      let objbase64 = {
        base64: base64[1],
        name: file.name,
        file: file.content,
        key: random(10, 100),
      };
      photoData.push(objbase64);
    });
  }
  const RCData = [];
  {
    RCPickerData.filesContent.map((file, index) => {
      let data = file.content.split(";");
      let base64 = data[1].replace(",", ":").split(":");
      let objbase64 = {
        base64: base64[1],
        name: file.name,
        file: file.content,
        key: random(10, 100),
      };
      RCData.push(objbase64);
    });
  }
  const challanData = [];
  {
    challanPickerData.filesContent.map((file, index) => {
      let data = file.content.split(";");
      let base64 = data[1].replace(",", ":").split(":");
      let objbase64 = {
        base64: base64[1],
        name: file.name,
        file: file.content,
        key: random(10, 100),
      };
      challanData.push(objbase64);
    });
  }
  const imageList = (
    <>
      {" "}
      {imageData.map((data) => (
        <img height={50} width="auto" alt={data.name} src={data.file}></img>
      ))}
    </>
  );
  const licenceImage = (
    <>
      {" "}
      {licenceData.map((data) => (
        <img
          height={40}
          width="auto"
          alt={data.name}
          src={data.file}
          key={data.key}
        ></img>
      ))}
    </>
  );
  const photoImage = (
    <>
      {" "}
      {photoData.map((data) => (
        <img
          height={40}
          width="auto"
          alt={data.name}
          src={data.file}
          key={data.key}
        ></img>
      ))}
    </>
  );
  const RCImage = (
    <>
      {" "}
      {RCData.map((data) => (
        <img
          height={40}
          width="auto"
          alt={data.name}
          src={data.file}
          key={data.key}
        ></img>
      ))}
    </>
  );
  const challanImage = (
    <>
      {" "}
      {challanData.map((data) => (
        <img
          height={40}
          width="auto"
          alt={data.name}
          src={data.file}
          key={data.key}
        ></img>
      ))}
    </>
  );

  const classes = useStyles();
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={10} md={8}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <NoteAdd />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Create Transaction</h4>
          </CardHeader>
          <CardBody>
            <form>
              <GridContainer justify="center">
                <GridItem xs={12} sm={10}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Select Division
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      onChange={(event) => {
                        if (verifyDivision(event.target.value)) {
                          setDivisionState("success");
                        } else {
                          setDivisionState("error");
                        }
                        setDivision(event.target.value);
                      }}
                      inputProps={{
                        name: "division",
                        id: "division",
                        value: division,
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        value="division"
                      >
                        Division
                      </MenuItem>
                      {divisionList}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Select Type
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      onChange={(event) => {
                        if (verifyType(event.target.value)) {
                          setTypeState("success");
                        } else {
                          setTypeState("error");
                        }
                        setType(event.target.value);
                      }}
                      inputProps={{
                        name: "type",
                        id: "type",
                        value: type,
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        value="type"
                      >
                        Type
                      </MenuItem>
                      {typeList}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Select Sub Type
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      onChange={(event) => {
                        if (verifySubType(event.target.value)) {
                          setSubTypeState("success");
                        } else {
                          setSubTypeState("error");
                        }
                        setSubType(event.target.value);
                      }}
                      inputProps={{
                        name: "subtype",
                        id: "subtype",
                        value: subType,
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        value="subType"
                      >
                        Sub Type
                      </MenuItem>
                      {subTypeList}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    success={dateState === "success"}
                    error={dateState === "error"}
                    labelText="Date"
                    id="date"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => {
                        if (verifyDate(event.target.value)) {
                          setDateState("success");
                        } else {
                          setDateState("error");
                        }
                        setDate(event.target.value);
                      },
                      type: "date",
                      value: date,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Gate Pass Number
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      onChange={(event) => {
                        if (verifyGatePassNumber(event.target.value)) {
                          setGatePassNumberState("success");
                        } else {
                          setGatePassNumberState("error");
                        }
                        setGatePassNumber(event.target.value);
                      }}
                      inputProps={{
                        name: "gatepassnumber",
                        id: "gatepassnumber",
                        value: gatePassNumber,
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        value="gatePassNumber"
                      >
                        Select
                      </MenuItem>
                      {gatePassList}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    success={setPartyNameState === "success"}
                    error={setPartyNameState === "error"}
                    labelText="Party Name"
                    id="partyName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => {
                        if (verifyParyName(event.target.value)) {
                          setPartyNameState("success");
                        } else {
                          setPartyNameState("error");
                        }
                      },
                      type: "text",
                      value: partyName,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    success={setPartyNameState === "success"}
                    error={setPartyNameState === "error"}
                    labelText="Order Number"
                    id="orderNumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => {
                        if (verifyOrderNumber(event.target.value)) {
                          setOrderNumberState("success");
                        } else {
                          setOrderNumberState("error");
                        }
                      },
                      type: "text",
                      value: orderNumber,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    success={setVehicleNumberState === "success"}
                    error={setVehicleNumberState === "error"}
                    labelText="Vehicle Number"
                    id="vehicleNumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => {
                        if (verifyVehicleNumber(event.target.value)) {
                          setVehicleNumberState("success");
                        } else {
                          setVehicleNumberState("error");
                        }
                      },
                      type: "text",
                      value: vehicleNumber,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    labelText="Remark"
                    id="remark"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => {
                        setRemark(event.target.value);
                      },
                      type: "text",
                      value: remark,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={3}>
                  {licenceImage}
                </GridItem>
                <GridItem xs={12} sm={3}>
                  {photoImage}
                </GridItem>
                <GridItem xs={12} sm={3}>
                  {RCImage}
                </GridItem>
                <GridItem xs={12} sm={3}>
                  {challanImage}
                </GridItem>

                <GridItem xs={12} sm={3}>
                  <Button
                    block
                    color="success"
                    onClick={openLicenceSelector}
                    className={classes.Button}
                  >
                    {/* <CardMembership /> */}
                    DL
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <Button
                    block
                    color="success"
                    onClick={openPhotoSelector}
                    className={classes.Button}
                  >
                    {/* <CameraFrontIcon /> */}
                    Photo
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <Button
                    block
                    color="success"
                    onClick={openRCSelector}
                    className={classes.Button}
                  >
                    {/* <CreditCard /> */}
                    RC
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <Button
                    block
                    color="success"
                    onClick={openChallanSelector}
                    className={classes.Button}
                  >
                    {/* <Receipt /> */}
                    Chln
                  </Button>
                </GridItem>

                <GridItem xs={12} sm={10}>
                  {imageList}
                </GridItem>

                <GridItem xs={12} sm={10}>
                  {message}
                </GridItem>

                <GridItem xs={12} sm={4}>
                  <Button
                    block
                    color="rose"
                    onClick={handleSubmit}
                    className={classes.Button}
                  >
                    Submit
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Button
                    block
                    color="success"
                    onClick={openImageSelector}
                    className={classes.Button}
                  >
                    <PhotoCamera />
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Button
                    block
                    color="warning"
                    onClick={handleCloseWindow}
                    className={classes.Button}
                  >
                    Close
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
