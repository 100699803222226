import axios from "axios";
import { ApiUrl } from "../../constants/apiurls";
import { StorageKey } from "../../constants/text";
import {
    CREATE_TYPE,
    UPDATE_TYPE,
    DELETE_TYPE,
    SET_TYPES,
    SET_TYPES_BY_DIVISION,
    SET_TYPE_ACTION_MESSAGE,
} from "../types/type-type";

export const fetchTypeAction = () => {
    const url = ApiUrl.getAllTypes;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);

        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };
        await axios
            .get(url, config)
            .then((response) => {
                dispatch({ type: SET_TYPES, types: response.data.data });
            })
            .catch((error) => {
                dispatch({
                    type: SET_TYPE_ACTION_MESSAGE,
                    actionMessage: error.message,
                });
            });
    };
};

export const fetchTypeByDivisionAction = (data) => {
    const url = `${ApiUrl.getAllTypesByDivision}/${data.division}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);

        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };
        await axios
            .get(url, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: SET_TYPES_BY_DIVISION,
                        typesByDivision: response.data.data,
                    });
                } else {
                    dispatch({
                        type: SET_TYPE_ACTION_MESSAGE,
                        actionMessage: response.data.message,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_TYPE_ACTION_MESSAGE,
                    actionMessage: error.message,
                });
            });
    };
};

export const createTypeAction = (data) => {
    const url = ApiUrl.createType;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .post(url, data, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CREATE_TYPE,
                        typeData: response.data.data,
                    });
                } else {
                    dispatch({
                        type: SET_TYPE_ACTION_MESSAGE,
                        actionMessage: response.data.message,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_TYPE_ACTION_MESSAGE,
                    actionMessage: error.message,
                });
            });
    };
};

export const updateTypeAction = (data) => {
    const url = `${ApiUrl.updateType}/${data._id}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .put(url, data, config)
            .then((response) => {
                dispatch({
                    type: UPDATE_TYPE,
                    typeData: response.data.data,
                    message: response.data.message,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SET_TYPE_ACTION_MESSAGE,
                    actionMessage: error.message,
                    // message: error.response.data.message,
                });
            });
    };
};

export const deleteTypeAction = (typeId) => {
    const url = `${ApiUrl.deleteType}/${typeId}`;
    return async (dispatch) => {
        const userData = await localStorage.getItem(StorageKey.UserData);
        const userDataJson = JSON.parse(userData);
        const config = {
            headers: { Authorization: `Bearer ${userDataJson.token}` },
        };

        await axios
            .delete(url, config)
            .then((response) => {
                dispatch({
                    type: DELETE_TYPE,
                    typeData: typeId,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SET_TYPE_ACTION_MESSAGE,
                    actionMessage: error.message,
                });
            });
    };
};
