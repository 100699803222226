import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PrivateRoute({ component: C, appProps, ...rest }) {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? <C {...props} {...appProps} /> : <Redirect to="/" />
			}
		/>
	);
}
