export const EnvironmentName = {
	Staging: 'staging',
	Development: 'development',
	Production: 'production',
};

export const UserType = {
	Admin: 'Admin',
	User: 'User',
	Vendor: 'Vendor',
};

export const StorageKey = {
	UserData: 'UserData',
	user_token: 'user_token',
};

export const SyncDataItems = {
	GateDetails: 'GateDetails',
	GrossTare: 'GrossTare',
	ScrapQuality: 'ScrapQuality',
	PurchaseOrder: 'PurchaseOrder',
	Pcr: 'Pcr',
	DebitNote: 'DebitNote',
};
