import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import CollectionsIcon from "@material-ui/icons/Collections";
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import EqualizerIcon from "@material-ui/icons/Equalizer";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";

// local components
import DetailsDialog from "./DetailsDialog";
import ReportDialog from "../Report/ReportDialog";
import ThumbnailDialog from "./ThumbnailDialog";

import { fetchVendorTranactionAction, fetchUnreadTranactionAction } from "../../redux/actions/transaction-action";
import { fetchDivisionAction } from "../../redux/actions/division-action";
import { fetchTypeAction } from "../../redux/actions/type-action";
import { fetchSubTypeAction } from "../../redux/actions/subtype-action";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function VendorTransactions() {
  const [showDetailsDialog, setShowDetailsDialog] = useState(null);
  const [showReportDialog, setShowReportDialog] = useState(null);
  const [showThumbnailsDialog, setShowThumbnailsDialog] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const classes = useStyles();
  const dispatch = useDispatch();
  const allVendorTransactions = useSelector(
    (state) => state.transactions.transactions
  );
  const allDivisions = useSelector((state) => state.divisions.divisions);
  const allTypes = useSelector((state) => state.types.types);
  const allSubTypes = useSelector((state) => state.subtypes.subtypes);

  useEffect(() => {
    batch(() => {
      dispatch(fetchDivisionAction());
      dispatch(fetchTypeAction());
      dispatch(fetchSubTypeAction());
      dispatch(fetchVendorTranactionAction());
      dispatch(fetchUnreadTranactionAction());
    });
  }, []);

  const handleThumbnailCallback = (state) => {
    if (!state) {
      setShowThumbnailsDialog(null);
    }
  };

  const handleReportCallback = (state) => {
    if (!state) {
      setShowReportDialog(null);
    }
  };

  const handleDetailsCallback = (state) => {
    if (!state) {
      setShowDetailsDialog(null);
    }
  };

  // Prepare table rows
  const data = allVendorTransactions.map((prop, key) => {
    const transactionDivision = (function () {
      if (allDivisions && allDivisions.length > 0) {
        let division = allDivisions.find((item) => item._id === prop.division);
        if (division) {
          return division.division;
        }
      }
      return "N/A";
    })();
    const transactionDivisionUnit = (function () {
      if (allDivisions && allDivisions.length > 0) {
        let division = allDivisions.find((item) => item._id === prop.division);
        if (division) {
          return division.unit;
        }
      }
      return "";
    })();
    const transactionType = (function () {
      if (allTypes && allTypes.length > 0) {
        let type = allTypes.find((item) => item._id === prop.type);
        if (type) {
          return type.typeName;
        }
      }
      return "N/A";
    })();
    const transactionSubType = (function () {
      if (allSubTypes && allSubTypes.length > 0) {
        let subType = allSubTypes.find((item) => item._id === prop.subType);
        if (subType) {
          return subType.subTypeName;
        }
      }
      return "N/A";
    })();
    return {
      id: prop._id,
      gatePassDate: moment(prop.gatePassDate).format("DD-MM-YYYY"),
      gatePassNumber: prop.gatePassNumber,
      vehicleNo: prop.vehicleNo,
      division: transactionDivision,
      type: transactionType,
      subType: transactionSubType,
      actions: (
        <div className="actions-right">
          <Button
            justIcon
            round
            simple
            onClick={() => {
              setShowThumbnailsDialog(
                <ThumbnailDialog
                  show={true}
                  parentCallback={handleThumbnailCallback}
                  transaction={prop}
                />
              );
            }}
            color="info"
            className="like"
          >
            <CollectionsIcon />
          </Button>
          <Button
            justIcon
            round
            simple
            onClick={() => {
              setShowDetailsDialog(
                <DetailsDialog
                  show={true}
                  parentCallback={handleDetailsCallback}
                  transaction={prop}
                  division={transactionDivision}
                  type={transactionType}
                  subType={transactionSubType}
                />
              );
            }}
            color="warning"
            className="edit"
          >
            <Dvr />
          </Button>
          <Button
            justIcon
            round
            simple
            onClick={() => {
              setShowReportDialog(
                <ReportDialog
                  show={true}
                  parentCallback={handleReportCallback}
                  transaction={prop}
                  unit={transactionDivisionUnit}
                />
              );
            }}
            color="danger"
            className="remove"
          >
            <EqualizerIcon />
          </Button>
        </div>
      ),
    };
  });

  return (
    <GridContainer>
      {showDetailsDialog}
      {showReportDialog}
      {showThumbnailsDialog}
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{user?.name} Transaction</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: "Date",
                  accessor: "gatePassDate",
                },
                {
                  Header: "Gate Pass",
                  accessor: "gatePassNumber",
                },
                {
                  Header: "Vechile No",
                  accessor: "vehicleNo",
                },
                {
                  Header: "Division",
                  accessor: "division",
                },
                {
                  Header: "Type",
                  accessor: "type",
                },
                {
                  Header: "Sub Type",
                  accessor: "subType",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                },
              ]}
              data={data}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
