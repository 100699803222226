import { Transaction } from "../../models/transaction-model";
import {
  SET_TRANSACTIONS_COUNT,
  CREATE_TRANSACTION,
  ADD_TRANSACTION_IMAGE,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION,
  SET_TRANSACTIONS,
  SET_TO_BE_PROCCESSED_TRANSACTION,
  SET_ACTION_MESSAGE,
  SET_UNREAD_TRANSACTIONS,
  SET_READ_UNREAD_TRANSACTIONS,
  SET_TRANSACTION_DOCUMENT,
  SET_ALL_TO_BE_PROCCESSED_TRANSACTION
} from "../types/transaction-type";

const initialState = {
  transactions: [],
  toBeProccessed: [],
  allToBeProccessed: [],
  document: null,
  lastTransaction: 0,
  allTransaction: 0,
  count: 5,
  unreadTransaction: [],
  unreadCount: 0,
  nextpage: 2,
  limit: 5,
  message: "",
  status: false,
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSACTIONS_COUNT:
      return {
        ...state,
        lastTransaction: action.lastTransactionData,
        allTransaction: action.allTransactionData,
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
        count: action.count,
        nextpage: action.nextpage,
        limit: action.limit,
      };
    case SET_TRANSACTION_DOCUMENT:
      return {
        ...state,
        document: action.document,
      };
    case SET_UNREAD_TRANSACTIONS:
      return {
        ...state,
        unreadTransaction: action.transactions,
        unreadCount: action.count,
      };
    case SET_READ_UNREAD_TRANSACTIONS:
      return {
        ...state,
        unreadCount: state.unreadCount - 1,
        unreadTransaction: state.unreadTransaction.filter(
          (transaction) => transaction._id !== action.transactions._id
        ),
      };
    case SET_TO_BE_PROCCESSED_TRANSACTION:
      return {
        ...state,
        toBeProccessed: action.toBeProccessed,
      };
    case SET_ALL_TO_BE_PROCCESSED_TRANSACTION:
      return {
        ...state,
        allToBeProccessed: action.allToBeProccessed,
      };
    case SET_ACTION_MESSAGE:
      return {
        ...state,
        message: action.message,
        status: action.status,
      };
    case CREATE_TRANSACTION:
      return {
        ...state,
        transactions: state.transactions.concat(action.transaction),
        message: action.message,
        status: action.status,
      };
    case ADD_TRANSACTION_IMAGE:
      return {
        ...state,
        message: action.message,
        status: action.status,
      };
    case UPDATE_TRANSACTION:
      const transactionIndex = state.transactions.findIndex(
        (trans) => trans._id === action.updatedData._id
      );
      const updatedTransaction = new Transaction(
        action.updatedData._id,
        action.updatedData.division,
        action.updatedData.divisionName,
        action.updatedData.date,
        action.updatedData.typeName,
        action.updatedData.subTypeName,
        action.updatedData.vechileNo,
        action.updatedData.gateInOut,
        action.updatedData.partyName,
        action.updatedData.ERPRefNo,
        action.updatedData.ERPRefDate,
        action.updatedData.remark,
        action.updatedData.active,
        action.updatedData.createdAt
      );

      const updatedTransactions = [...state.transactions];
      updatedTransactions[transactionIndex] = updatedTransaction;
      return {
        ...state,
        transactions: updatedTransactions,
      };
    case DELETE_TRANSACTION:
      return {
        ...state,
        transactions: state.transactions.filter(
          (transaction) => transaction._id !== action.transactionId
        ),
        message: action.message,
        status: action.status,
      };
  }
  return state;
};

export default transactionReducer;
