import React from 'react'

const Policy = () => {
  return (
    <div style={{margin: 20}}>
      <h4>Escrap Privacy Policy Program</h4>
      <p>
      The Escrap Policy below apply to Escrap. The policy play an important role in maintaining a positive experience for everyone using Escrap.
      </p>
      <p>Be sure to check back from time to time, as these policy may change.</p>
      <h4>Copyright</h4>
      <p>
      Respect copyright laws. Don’t infringe on the intellectual property rights of others, including patent, trademark, trade secret, or other proprietary rights. You are also not allowed to encourage or induce others to violate intellectual property rights.
      </p>
    </div>
  )
}

export default Policy