// const baseUrl = "http://localhost:5000";
// const baseUrl = "https://escrap-api.codemetrics.in";
const baseUrl = "https://escrap-api.azurewebsites.net";

export const ApiUrl = {
  login: baseUrl + "/api/v1/auth/login",
  logout: baseUrl + "/api/v1/auth/logout",
  // auth user endpoints
  authRegister: baseUrl + "/api/v1/auth/register",
  getAuthUser: baseUrl + "/api/v1/auth/user",
  authUpdateUser: baseUrl + "/api/v1/auth/update",
  authChangePassword: baseUrl + "/api/v1/auth/updatepassword",
  authDeleteUser: baseUrl + "/api/v1/auth/delete",
  // user endpoints
  getAllUser: baseUrl + "/api/v1/user/getall",
  getAllVendor: baseUrl + "/api/v1/user/getallvendors",
  register: baseUrl + "/api/v1/user/register",
  getUser: baseUrl + "/api/v1/user/details/",
  updateUser: baseUrl + "/api/v1/user/update",
  changePassword: baseUrl + "/api/v1/user/updatepassword",
  changeUserRole: baseUrl + "/api/v1/user/updateuserrole",
  userActivation: baseUrl + "/api/v1/user/useractivation",
  deleteUser: baseUrl + "/api/v1/user/delete",
  changeVendorPassword: baseUrl + "/api/v1/user/changepassword",
  // transaction endpoints
  lastTransaction: baseUrl + "/api/v1/transaction/getstatus",
  submitTransaction: baseUrl + "/api/v1/transaction/create",
  addTransactionImage: baseUrl + "/api/v1/transaction/addimage",
  getAllTransaction: baseUrl + "/api/v1/transaction/getall",
  getAllVendorTransaction: baseUrl + "/api/v1/transaction/vendor/getall",
  getTransactionThumbnail: baseUrl + "/api/v1/transaction",
  deleteTransaction: baseUrl + "/api/v1/transaction/delete",
  updateTransaction: baseUrl + "/api/v1/transaction/update",
  getRecordsToBeProcesed: baseUrl + "/api/v1/sync/tobeproccessed",
  getAllRecordsToBeProcesed: baseUrl + "/api/v1/sync/alltobeproccessed",
  getRecordsBetweenTimeRange: baseUrl + "/api/v1/transaction/searchbydate",
  getTransactionByDateCode: baseUrl + "/api/v1/transaction/searchbydatecode",
  getTransactionDocument: baseUrl + "/api/v1/transaction/documents",
  getRecordFromSearchBy: baseUrl + "/api/v1/transaction/searchby",
  getThumbnails: baseUrl + "/api/v1/transaction/thumbnail",
  getUnreadTransaction: baseUrl + "/api/v1/transaction/getallunreadtransaction",
  readUnreadTransaction: baseUrl + "/api/v1/transaction/readunreadtransaction",
  // division endpoints
  getAllDivisions: baseUrl + "/api/v1/division/getall",
  createDivision: baseUrl + "/api/v1/division/create",
  updateDivision: baseUrl + "/api/v1/division/update",
  deleteDivision: baseUrl + "/api/v1/division/delete",
  // type endpoints
  getAllTypes: baseUrl + "/api/v1/type/getall",
  getAllTypesByDivision: baseUrl + "/api/v1/type/division",
  createType: baseUrl + "/api/v1/type/create",
  updateType: baseUrl + "/api/v1/type/update",
  deleteType: baseUrl + "/api/v1/type/delete",
  detailsType: baseUrl + "/api/v1/type/details",
  //subtype endpoints
  getAllSubTypes: baseUrl + "/api/v1/subtype/getall",
  getAllTypesByType: baseUrl + "/api/v1/subtype/type",
  createSubType: baseUrl + "/api/v1/subtype/create",
  updateSubType: baseUrl + "/api/v1/subtype/update",
  deleteSubType: baseUrl + "/api/v1/subtype/delete",
  // reports endpoint
  getTransactionReportStatus: baseUrl + "/api/v1/sync/datastatus",
  getTransactionReport: baseUrl + "/api/v2/sync/datastatus",
  // logs
  getAllLogs: baseUrl + "/api/v1/log/getalllog",
  // devices endpoint
  getAllDevices: baseUrl + "/api/v1/device",
  // data sync endpoint
  getTotalGateDetail: baseUrl + "/api/v1/sync/getall",
  getTotalGrossWeigth: baseUrl + "/api/v1/sync/grossweight",
  getTotalTareWeigth: baseUrl + "/api/v1/sync/tareweight",
};
