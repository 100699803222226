import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, batch } from "react-redux";

import PersonAddDisabled from "@material-ui/icons/PersonAddDisabled";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/icons/List";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Assignment from "@material-ui/icons/Assignment";
import Dialog from "@material-ui/core/Dialog";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactTable from "components/ReactTable/ReactTable.js";

import { fetchDeviceAction } from "../../redux/actions/device-action";
import { fetchUserAction } from "../../redux/actions/user-action";
import { windowAction } from "../../redux/actions/window-action";
import AddDevice from "./AddDevice";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import myStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Devices from "@material-ui/icons/Devices";

const useStyles = makeStyles(styles);
const useMyStyles = makeStyles(myStyles);

export default function DeviceList() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showRegisterDevice, setShowRegisterDevice] = useState(null);
    const [showReportDialog, setShowReportDialog] = useState(null);
    const users = useSelector((state) => state.user.allUsers);
    const devices = useSelector((state) => state.devices.allDevices);
    const open = useSelector((state) => state.window.open);

    useEffect(() => {
        batch(() => {
            dispatch(fetchDeviceAction());
            dispatch(fetchUserAction());
        });
    }, []);
    const handleRegisterDevice = () => {
        dispatch(windowAction({ open: true }));
    };

    const handleCloseWindow = () => {
        dispatch(windowAction({ open: false }));
    };

    const handleRegisterDeviceCallback = (state) => {
        if (!state) {
            setShowRegisterDevice(null);
        }
    };

    // Prepare table rows
    const data = devices.map((prop, key) => {
        const userName = (function () {
            if (users && users.length > 0) {
                let user = users.find((item) => item._id === prop.userId);
                if (user) {
                    return user.name;
                }
            }
            return "N/A";
        })();
        return {
            id: prop._id,
            userName: userName,
            deviceId: prop.deviceId,
            deviceType: prop.osType,
            validity: prop.validity,
            active: prop.active ? "Active" : "Deactive",
            actions: <div className="actions-right"></div>,
        };
    });

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Assignment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Device List</h4>
                        <h2 className={classes.cardButtonTitle} color="primary">
                            <Button
                                round
                                onClick={() => {
                                    handleRegisterDevice();
                                }}
                                color="primary"
                            >
                                <Devices />
                                Device
                            </Button>
                        </h2>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            columns={[
                                {
                                    Header: "User",
                                    accessor: "userName",
                                },
                                {
                                    Header: "Device Type",
                                    accessor: "deviceType",
                                },
                                {
                                    Header: "Device Id",
                                    accessor: "deviceId",
                                },
                                {
                                    Header: "Velidity",
                                    accessor: "velidity",
                                },
                                {
                                    Header: "Active",
                                    accessor: "active",
                                },
                                {
                                    Header: "Actions",
                                    accessor: "actions",
                                },
                            ]}
                            data={data}
                        />
                    </CardBody>
                    <Dialog open={open} onClose={handleCloseWindow}>
                        <AddDevice />
                    </Dialog>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
