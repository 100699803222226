import { combineReducers } from "redux";
import authReducer from "./auth-reducer";
import userReducer from "./user-reducer";
import transactionReducer from "./transaction-reducer";
import deviceReducer from "./device-reducer";
import divisionReducer from "./division-reducer";
import settingReducer from "./setting-reducer";
import typeReducer from "./type-reducer";
import subtypeReducer from "./subtype-reducer";
import systemconfigReducer from "./systemconfig-reducer";
import transactionimageReducer from "./transactionimage-reducer";
import transactionThumbnailReducer from "./transactionthumbnail-reducer";
import reportReducer from "./report-reducer";
import syncReducer from "./sync-reducer";
import windowReducer from "./window-reducer";

const allReducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  transactions: transactionReducer,
  divisions: divisionReducer,
  settings: settingReducer,
  devices: deviceReducer,
  types: typeReducer,
  subtypes: subtypeReducer,
  systemconfig: systemconfigReducer,
  images: transactionimageReducer,
  thumbnails: transactionThumbnailReducer,
  reports: reportReducer,
  sync: syncReducer,
  window: windowReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
	localStorage.clear();
    state = undefined;
  }

  return allReducers(state, action);
};


export default rootReducer;