import { SET_WINDOWS_ACTION } from '../types/window-action-type';

export const windowAction = (data) => {
	return async (dispatch) => {
		if (data.open) {
		 dispatch({ type: SET_WINDOWS_ACTION, open: data.open });
		} else if (data.openP) {
		 dispatch({ type: SET_WINDOWS_ACTION, openP: data.openP });
		} else {
		 dispatch({
		  type: SET_WINDOWS_ACTION,
		  open: data.open,
		  openP: data.openP,
		 });
		}
	   };
};
