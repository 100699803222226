/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// material ui icons
import DevicesOutlined from "@material-ui/icons/DevicesOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { fetchUserAction } from "../../redux/actions/user-action";
import { addDeviceAction } from "../../redux/actions/device-action";
import { windowAction } from "../../redux/actions/window-action";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

export default function AddDevice() {
    const dispatch = useDispatch();
    const [userId, setUserId] = useState("");
    const [userIdState, setUserIdState] = useState("");
    const [deviceId, setDeviceId] = useState("");
    const [deviceIdState, setDeviceIdState] = useState("");
    const [osType, setOsType] = useState("");
    const [osTypeState, setOsTypeState] = useState("");
    const [validity, setValidity] = useState("");
    const [validityState, setValidityState] = useState("");

    const userData = useSelector((state) => state.user.allUsers);
    const message = useSelector((state) => state.devices.message);

    useEffect(() => {
        dispatch(fetchUserAction());
    }, []);
    const userList = userData.map((data) => (
        <MenuItem value={data._id} key={data._id}>
            {data.name + "(" + data.role + ")"}
        </MenuItem>
    ));

    const verifyUser = (value) => {
        return value.length !== 0;
    };
    const verifyDevice = (value) => {
        return value.length !== 0;
    };
    const verifyOsType = (value) => {
        return value.length !== 0;
    };
    const handleSubmit = () => {
        if (userIdState === "") {
            setUserIdState("error");
            return;
        }
        if (deviceIdState === "") {
            setDeviceIdState("error");
            return;
        }
        if (osTypeState === "") {
            setOsTypeState("error");
            return;
        }
        const data = {
            userId: userId,
            deviceId: deviceId,
            osType: osType,
            validity: validity,
        };
        handleReset();
        dispatch(addDeviceAction(data));
    };
    const handleCloseWindow = () => {
        dispatch(windowAction({ open: false }));
    };

    const handleReset = (event) => {
        setUserId("");
        setDeviceId("");
        setOsType("");
        setValidity("");
    };

    const classes = useStyles();
    return (
        <DialogContent>
            <GridContainer justify="center">
                <GridItem xs={12} sm={10} md={8}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <DevicesOutlined />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Add New Device
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={5}>
                                        <FormControl
                                            fullWidth
                                            className={
                                                classes.selectFormControl
                                            }
                                        >
                                            <InputLabel
                                                htmlFor="simple-select"
                                                className={classes.selectLabel}
                                            >
                                                Select User
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className:
                                                        classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                onChange={(event) => {
                                                    if (
                                                        verifyUser(
                                                            event.target.value
                                                        )
                                                    ) {
                                                        setUserIdState(
                                                            "success"
                                                        );
                                                    } else {
                                                        setUserIdState("error");
                                                    }
                                                    setUserId(
                                                        event.target.value
                                                    );
                                                }}
                                                inputProps={{
                                                    name: "user",
                                                    id: "user",
                                                    value: userId,
                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root:
                                                            classes.selectMenuItem,
                                                    }}
                                                    value="user"
                                                >
                                                    User
                                                </MenuItem>
                                                {userList}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={5}>
                                        <CustomInput
                                            success={
                                                deviceIdState === "success"
                                            }
                                            error={deviceIdState === "error"}
                                            labelText="Device Id *"
                                            id="deviceId"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    if (
                                                        verifyDevice(
                                                            event.target.value
                                                        )
                                                    ) {
                                                        setDeviceIdState(
                                                            "success"
                                                        );
                                                    } else {
                                                        setDeviceIdState(
                                                            "error"
                                                        );
                                                    }
                                                    setDeviceId(
                                                        event.target.value
                                                    );
                                                },
                                                type: "text",
                                                value: deviceId,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={5}>
                                        <FormControl
                                            fullWidth
                                            className={
                                                classes.selectFormControl
                                            }
                                        >
                                            <InputLabel
                                                htmlFor="simple-select"
                                                className={classes.selectLabel}
                                            >
                                                Os Type
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className:
                                                        classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                onChange={(event) => {
                                                    if (
                                                        verifyOsType(
                                                            event.target.value
                                                        )
                                                    ) {
                                                        setOsTypeState(
                                                            "success"
                                                        );
                                                    } else {
                                                        setOsTypeState("error");
                                                    }
                                                    setOsType(
                                                        event.target.value
                                                    );
                                                }}
                                                inputProps={{
                                                    name: "osType",
                                                    id: "osType",
                                                    value: osType,
                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root:
                                                            classes.selectMenuItem,
                                                    }}
                                                    value="OS"
                                                >
                                                    OS
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root:
                                                            classes.selectMenuItem,
                                                        selected:
                                                            classes.selectMenuItemSelected,
                                                    }}
                                                    value="android"
                                                >
                                                    Android
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root:
                                                            classes.selectMenuItem,
                                                        selected:
                                                            classes.selectMenuItemSelected,
                                                    }}
                                                    value="windows"
                                                >
                                                    Windows
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={5}>
                                        <CustomInput
                                            success={
                                                validityState === "success"
                                            }
                                            error={validityState === "error"}
                                            labelText="Validity"
                                            id="validity"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    setValidity(
                                                        event.target.value
                                                    );
                                                },
                                                type: "text",
                                                value: validity,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={10}>
                                        {message}
                                    </GridItem>

                                    <GridItem xs={12} sm={5}>
                                        <Button
                                            color="rose"
                                            onClick={handleSubmit}
                                            className={classes.Button}
                                        >
                                            Add
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={5}>
                                        <Button
                                            color="warning"
                                            onClick={handleCloseWindow}
                                            className={classes.Button}
                                        >
                                            Close
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </DialogContent>
    );
}
