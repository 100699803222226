import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import GavelIcon from "@material-ui/icons/Gavel";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import BallotIcon from "@material-ui/icons/Ballot";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";

import { fetchStatusReportAction } from "../../redux/actions/report-action";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ReportDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusData = useSelector((state) => state.reports.filteredRecord);
  const status = useSelector((state) => state.reports.status);
  useEffect(() => {
    dispatch(
      fetchStatusReportAction(props.transaction.gatePassNumber, props.unit)
    );
  }, []);

  const handleDialogClose = (state) => {
    props.parentCallback(state);
  };
  let cardDetails = null;
  if (statusData && statusData.gatedetails) {
    cardDetails = (
      <Card>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              onClick={() => handleDialogClose(false)}
            >
              <CancelPresentationIcon />
            </IconButton>
          }
          title="Transaction Report"
          subheader={props.transaction.entryDate} // should be created date
        />
        <CardBody>
          <NavPills
            color="rose"
            horizontal={{
              tabsGrid: { xs: 12, sm: 12, md: 3 },
              contentGrid: { xs: 12, sm: 12, md: 9 },
            }}
            tabs={[
              {
                tabButton: "Eway Bill",
                tabIcon: AssignmentTurnedInIcon,
                tabContent:
                  statusData.ewaybill.length > 0 &&
                  statusData.ewaybill.map((data, index) => (
                    <table>
                      <thead>
                        <td>Eway Bill No</td>
                        <td>Eway Bill Date</td>
                        <td>Bill Ty No</td>
                      </thead>
                      <tr>
                        <td> {data.eway_bill_no}</td>
                        <td>{moment(data.eway_bill_date)
                          .format("DD/MM/YYYY")
                          .toString()}</td>
                        <td> {data.bill_ty_no}</td>
                      </tr>

                      <thead>
                        <td>Sup Place</td>
                        <td></td>
                        <td>Distance</td>
                      </thead>
                      <tr>
                        <td> {data.sup_place}</td>
                        <td></td>
                        <td>{data.distance}</td>
                      </tr>
                    </table>
                  )),
              },
              {
                tabButton: "Gate Details",
                tabIcon: Dashboard,
                tabContent: (
                  <table>
                    <thead>
                      <td>Date</td>
                      <td>Gate Pass No</td>
                      <td>Truck No</td>
                    </thead>
                    <tr>
                      <td>
                        {moment(statusData.gatedetails.gate_pass_date)
                          .format("DD/MM/YYYY")
                          .toString()}
                      </td>
                      <td>{statusData.gatedetails[0].gate_pass_no}</td>
                      <td>{statusData.gatedetails[0].truck_no}</td>
                    </tr>
                    <thead>
                      <td colspan="3">Party Name</td>
                    </thead>
                    <tr>
                      <td colspan="3">{statusData.gatedetails[0].party_name}</td>
                    </tr>
                    <thead>
                      <td colspan="3">Bill No & Bill Date</td>
                    </thead>
                    <tr>
                      <td colspan="3">{`${
                        statusData.gatedetails[0].bill_no
                      } ( ${moment(statusData.gatedetails[0].bill_date)
                        .format("DD/MM/YYYY")
                        .toString()} )`}</td>
                    </tr>
                    <thead>
                      <td colspan="2">Item name</td>
                      <td>Quantity</td>
                    </thead>
                    <tr>
                      <td colspan="2">{statusData.gatedetails[0].itnm}</td>
                      <td>{statusData.gatedetails[0].gate_pass_qty}</td>
                    </tr>
                  </table>
                ),
              },
              {
                tabButton: "Gross Tare",
                tabIcon: GavelIcon,
                tabContent: (
                  <table>
                    <thead>
                      <td>Gate Pass No.</td>
                      <td>Gross Weight</td>
                      <td>Tare Weight</td>
                    </thead>
                    <tr>
                      <td>
                        {" "}
                        {statusData.grosstare
                          ? statusData.grosstare.gate_pass_no
                          : ""}
                      </td>
                      <td>
                        {" "}
                        {statusData.grosstare
                          ? statusData.grosstare.gross_wt
                          : ""}
                      </td>
                      <td>
                        {" "}
                        {statusData.grosstare
                          ? statusData.grosstare.tare_wt
                          : ""}
                      </td>
                    </tr>
                  </table>
                ),
              },
              {
                tabButton: "PCR",
                tabIcon: DonutSmallIcon,
                tabContent:
                  statusData.pcr.length > 0 &&
                  statusData.pcr.map((pcr, index) => (
                    <table>
                      <thead>
                        <td>PCR No.</td>
                        <td></td>
                        <td>Po No</td>
                      </thead>
                      <tr>
                        <td> {pcr.id}</td>
                        <td></td>
                        <td> {pcr.purchase_order_no}</td>
                      </tr>

                      <thead>
                        <td>Qty</td>
                        <td>Rate</td>
                        <td>Value</td>
                      </thead>
                      <tr>
                        <td> {pcr.qty}</td>
                        <td> {pcr.rate}</td>
                        <td> {pcr.val}</td>
                      </tr>
                    </table>
                  )),
              },
              {
                tabButton: "Purchase Order",
                tabIcon: AssignmentIndIcon,
                tabContent:
                  statusData.purchaseorder.length > 0 &&
                  statusData.purchaseorder.map((purchaseOrder, index) => (
                    <table>
                      <thead>
                        <td>Order No.</td>
                        <td></td>
                        <td>Category</td>
                      </thead>
                      <tr>
                        <td>{purchaseOrder.order_no}</td>
                        <td></td>
                        <td> {purchaseOrder.category_name}</td>
                      </tr>

                      <thead>
                        <td>Rate</td>
                        <td>Qty</td>
                        <td>Recv.Qty</td>
                      </thead>
                      <tr>
                        <td> {purchaseOrder.rate}</td>
                        <td> {purchaseOrder.qty}</td>
                        <td> {purchaseOrder.rec_qty}</td>
                      </tr>
                    </table>
                  )),
              },
              {
                tabButton: "Scrap Quality",
                tabIcon: AssignmentTurnedInIcon,
                tabContent:
                  statusData.scrapquality.length > 0 &&
                  statusData.scrapquality.map((scrapQuality, index) => (
                    <table>
                      <thead>
                        <td>Scrap ID.</td>
                        <td>Weight</td>
                        <td>Percent</td>
                      </thead>
                      <tr>
                        <td> {scrapQuality.divisionId}</td>
                        <td>{scrapQuality.weight}</td>
                        <td> {scrapQuality.per}</td>
                      </tr>

                      <thead>
                        <td>Category Name</td>
                        <td></td>
                        <td></td>
                      </thead>
                      <tr>
                        <td> {scrapQuality.category_name}</td>
                        <td></td>
                        <td> </td>
                      </tr>
                    </table>
                  )),
              },
              {
                tabButton: "Debit Notes",
                tabIcon: BallotIcon,
                tabContent:
                  statusData.debitnote.length > 0 &&
                  statusData.debitnote.map((data, index) => (
                    <table>
                      <thead>
                        <td>Debit Note No</td>
                        <td></td>
                        <td>Category Name</td>
                      </thead>
                      <tr>
                        <td> {data.id}</td>
                        <td></td>
                        <td> {data.catg_name}</td>
                      </tr>

                      <thead>
                        <td>Qty</td>
                        <td>Rate</td>
                        <td>Value</td>
                      </thead>
                      <tr>
                        <td> {data.qty}</td>
                        <td>{data.rate}</td>
                        <td> {data.val}</td>
                      </tr>
                    </table>
                  )),
              },
            ]}
          />
        </CardBody>
        <CardActions justifyContent="center">
          <Box m="auto">
            <Button
              size="small"
              color="primary"
              onClick={() => handleDialogClose(false)}
            >
              CLOSE
            </Button>
          </Box>
        </CardActions>
      </Card>
    );
  } else {
    cardDetails = (
      <Card>
        <CardBody>No data available.</CardBody>
        <CardActions justifyContent="center">
          <Box m="auto">
            <Button
              size="small"
              color="primary"
              onClick={() => handleDialogClose(false)}
            >
              CLOSE
            </Button>
          </Box>
        </CardActions>
      </Card>
    );
  }

  return (
    <Dialog
      open={props.show}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleDialogClose(false)}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
      maxWidth="md"
    >
      <DialogContent
        id="notice-modal-slide-description"
        className={classes.modalBody}
      >
        {cardDetails}
      </DialogContent>
    </Dialog>
  );
}
