import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogActions, DialogContentText, DialogTitle } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from '@material-ui/core/Button';
import CustomInput from 'components/CustomInput/CustomInput.js';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { updatePasswordAction } from 'redux/actions/user-action';

function PasswordUpdate (props) {
    const dispatch = useDispatch();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPasswordState, setNewPasswordState] = useState('')
    const [confirmPasswordState, setConfirmPasswordState] = useState('')
    const status = useSelector((state) => state.user.status);
    const message = useSelector((state) => state.user.message);

    const verifyLength = (value, length) => {
        return value.length >= length;
    }

    if(status) {
        setTimeout(() => {
            // dispatch({
            //     type: 'UPDATE_PASSWORD',
            //     message: '',
            //     status: false,
            // });
            handleClose(false)
        }, 200)
    }

    const handleSubmit = () => {
        if(newPasswordState === 'error') {
            // setNewPasswordState('error')
            return;
        }
        if(confirmPasswordState === 'error') {
            // setConfirmPasswordState('error')
            return;
        }
        const data = {
            id: props.id,
            newPassword,
            confirmPassword,
        }
        dispatch(updatePasswordAction(data))
    }
    const handleClose = (state) => {
        props.parentCallback(state)
    }

    return (
        <>
        <Dialog onClose={() => handleClose(false)} open={props.show}>
            <GridContainer justify="center">
                    <Card>
                        <CardBody>
                            <GridItem>
                                <DialogTitle>Password Update</DialogTitle>
                            </GridItem>
                            <GridItem>
                                <CustomInput 
                                    success={newPasswordState === 'success'}
                                    error={newPasswordState === 'error'}
                                    labelText='New Password'
                                    id='newPassword'
                                    formControlProps={{fullWidth: true,}}
                                    inputProps={{
                                        onChange: (event) => {
                                            // alert(`Input Props`)
                                            if(verifyLength(event.target.value, 1)) {
                                                setNewPasswordState('success')
                                            } else {
                                                setNewPasswordState('error')
                                            }
                                            setNewPassword(event.target.value)
                                        },
                                        type: 'password',
                                        value: newPassword,
                                        autoComplete: 'off',
                                    }}
                                />
                                </GridItem>
                                <GridItem>
                                <CustomInput 
                                    success={confirmPasswordState === 'success'}
                                    error={confirmPasswordState === 'error'}
                                    labelText='Confirm Password'
                                    id='confirmPassword'
                                    formControlProps={{fullWidth: true,}}
                                    inputProps={{
                                        onChange: (event) => {
                                            // alert(`CP IP`)
                                            if(verifyLength(event.target.value, 1)) {
                                                setConfirmPasswordState('success')
                                            } else {
                                                setConfirmPasswordState('error')
                                            }
                                        setConfirmPassword(event.target.value)
                                        },
                                        type: 'password',
                                        value: confirmPassword,
                                        autoComplete: 'off',
                                    }}
                                />
                            </GridItem>
                            <GridItem>
                                {message}
                            </GridItem>
                            <GridItem>
                                <DialogActions>
                                    <Button
                                    color='rose'
                                    onClick={() =>{ handleClose(false)}}
                                    >
                                        Calcel
                                    </Button>
                                    <Button
                                    color='rose'
                                    onClick={handleSubmit}
                                    >
                                        Update
                                    </Button>
                                </DialogActions>
                            </GridItem>
                        </CardBody>
                    </Card>
            </GridContainer>
        </Dialog>
        </>
    );
}

export default PasswordUpdate;